
.static-page, .static-page-wor{
  width:100%;
  margin: 0 auto;
  max-width: 1000px;
  padding: 74px 40px 100px 40px;
  word-wrap: break-word;

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: @pages-heading-color;
    margin-bottom: 20px;
  }

  h1 {
    margin-bottom: 50px;
    text-transform: none;
    font-size: 32px;

    .gibson-light;
  }

  p {
    color: @pages-content;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 28px;
    .gibson-light;
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: 60px;
    font-size: 16px;
  }

  ol,
  ul {
    color: @pages-content;
    padding-left: 18px;

  }

  li {
    margin-bottom: 5px;
  }

  table {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.static-page-wor-new{
  padding: 15px 40px 100px 40px;
}


.static-page{
  background:#FFF;
  box-shadow: 0 0 0 100vw #FFF;
}

.reset-pass{
  background:var(--secondary-color);
  box-shadow: 0 0 0 100vw var(--secondary-color);
}

.terms-fi,
.terms-en,
.terms-no,
.terms-sv {
  p {
    font-size: 14px;
  }
}

.gbr-cat-list {
  display: none;
}