.pick-game {
  /*background: #f9d495 url("/assets/img/june-promo/backgroundGlobal@2x.jpg") no-repeat;
  &.fi, &.sv, &.no {
    background: #f9d495 url("/assets/img/june-promo/backgroundNordics@2x.jpg") no-repeat;
  }*/
  h1 {
    font-weight: 600;
    text-transform: uppercase;
  }
  img {
      width:100%;
      max-width:100%;
  }
  overflow: hidden;
  width:90%;
  max-width: 1280px;
  margin: 0 auto;
  h2 {
    font-weight:700;
    text-transform:uppercase;
    margin: 0 auto 2px;
  }
}

.pick-game-upper {
  color:white;
  transition: transform 0.25s ease;
  transform:translate3d(0,0,0) scale(1.0, 1.0);
  position:absolute;
  z-index:4;
  top:8px;
  width:100%;
  img {
    max-width: 40%;
  }
}

.pick-game-mid {
  position:absolute;
  z-index:5;
  top:54%;
  width:100%;
}

.pick-game-bottom{
  position: absolute;
  z-index: 5;
  width: 100%;
  bottom: 8px;
  color:@brand-white;
  transition: transform 0.25s ease;
  transform:translate3d(0,0,0) scale(1.0, 1.0);
  a {
    color:@brand-white;
    text-decoration:underline;
  }
}


.upper-tandc a {
  color:@brand-white;
  text-decoration:underline;
}

.pick-game-fg {
  position:absolute;
  bottom:0;
  z-index:10;

  /*background: #f9d495 url("/assets/img/june-promo/foregroundGlobal@2x.png") no-repeat;
  &.fi, &.sv, &.no {
    background: #f9d495 url("/assets/img/june-promo/foregroundNordics@2x.png") no-repeat;
  }*/
}

.pick-elem-cont {
  perspective: 1000px;
  transform-style: preserve-3d;
  filter: brightness(90%);

  transition: all 0.25s ease;
  &:hover {
    transform:scale(1.1, 1.1);
    filter: brightness(100%);
  }

  display: inline-block;
  margin: 2%;
  width: 16%;
  //height: 25%;
}

.pick-elem {
  //width:200px;
  //height:200px;
  //background: #f9d495 url("/assets/img/r-stamp.svg") no-repeat;
  transform:translate3d(0,0,0) scale(1.0, 1.0);
  backface-visibility:hidden;
  transform-style: preserve-3d;
  background-size: cover;
  clip-path: inset(2px 0 0 0);
  position:relative;
  >div {
    position:absolute;
    width:100%;
    height:100%;
    background-size: cover;
    background-repeat: no-repeat;
    //transform-style: preserve-3d;
    //backface-visibility:hidden;
  }
}

.pick-lid {
  transition: transform 0.5s ease;
  transform:translate3d(0,0,0) scale(1.0, 1.0);
  position: absolute;
  top: -54%;
  left: 0;
  background-size: cover;
}

.pick-result {
  position: absolute;
  top: -18px;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  img {
    width:100%;
    padding: 25%;
    margin-bottom:15px;
  }
}

.pick-result-rotator {
  width:150%;
  height:150%;
  position:absolute;
  top:-25%;
  left:-25%;
  z-index: -2;

  animation: spinning 70s linear 0s infinite;
  transform:rotate(0);
}

.pick-result-rays {
  // .rays;
  width:100%;
  height:100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.pick-result-texts {
  h3 {
    font-size: 24px;
    margin: 0 0 16px 0; 
  }
  p {
    margin: 16px;
    a {
      color:white;
      text-decoration: underline;
    }
  }
  a {
    color:white;
    text-decoration: underline;
  }
  color:white;
  position:absolute;
  width: 100%;
  bottom: 0;
  //height: 50%;
  z-index:15;
  transform: translate3d(0,100%,0);
  transition: all 0.25s ease;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.7+100 */
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.snowflake {
  border-radius:50px;
  width:4px;
  height:4px;
  line-height:4px;
  font-size: 1em;
  font-family: Arial;
  position:absolute;
  backface-visibility:hidden;
  transform-style: preserve-3d;
  top:-50px;
}

@-webkit-keyframes snowflakes-fall{
  0%{-webkit-transform:translateY(0%)}
  100%{-webkit-transform:translateY(100%)}
}
@-webkit-keyframes snowflakes-shake{
  0%{-webkit-transform:translateX(0px);transform:translateX(0px)}
  50%{-webkit-transform:translateX(40px);transform:translateX(40px)}
  100%{-webkit-transform:translateX(0px);transform:translateX(0px)}
}

@keyframes snowflakes-fall {
  0%{transform:translateY(0px)}
  100%{transform:translateY(1000px)}
}

@keyframes snowflakes-shake{
  0%{transform:translateX(0px)}
  50%{transform:translateX(40px)}
  100%{transform:translateX(0px)}
}

.snowflake{
  z-index:4;
  user-select:none;
  cursor:default;
  will-change: transform;

  animation: snowflakes-fall 10s linear 0s infinite;
  
  /*-webkit-animation-name:snowflakes-fall,snowflakes-shake;
  -webkit-animation-duration:10s,3s;
  -webkit-animation-timing-function:linear,ease-in-out;
  -webkit-animation-iteration-count:infinite,infinite;
  -webkit-animation-play-state:running,running;
  animation-name:snowflakes-fall,snowflakes-shake;
  animation-duration:10s,3s;
  animation-timing-function:linear,ease-in-out;
  animation-iteration-count:infinite,infinite;
  animation-play-state:running,running*/
}

.snowflake > div {
  animation: snowflakes-shake 3s ease-in-out 0s infinite;
  background: #fff;
  border-radius:50px;
  width:4px;
  height:4px;
  line-height:4px;
  font-size: 1em;
  font-family: Arial;
  position:absolute;
  backface-visibility:hidden;
  transform-style: preserve-3d;
  top:0%;
  will-change: transform;
}

.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}
.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}

@media (max-width: 610px) {
  .pick-game h2 {
    font-size:24px;
    margin:8px;
  }
  .pick-result-texts {
    h3 {
      font-size: 16px;
      margin: 0 0 8px 0; 
    }
    p {
      margin: 16px; 
    }
  }
}

@media (max-width: 350px) {
  .pick-result-texts {
    h2 {
      font-size: 16px;
      margin: 0;
    }
    h3 {
      font-size: 12px;
      margin: 0 0 4px 0; 
    }
  }

}
