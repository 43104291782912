.bonus-container {
    height: auto;
    text-align: unset;
    padding: 5%;
    max-width: inherit;

    * {
        &:not(#account-balance-header) {
            text-transform: lowercase;
        }
        &:first-child::first-letter {
            text-transform: uppercase !important;
        }
    }

    .currency-balance-amount {
        text-transform: none !important;
    }

    h1 {
        font-size: 14px;
        font-weight: 400;
        color: green;
        font-family: "Figtree", sans-serif;
    }

    h2 {
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 600;
        color: #3e3d3d;
        margin-bottom: 2%;
        font-family: "Figtree", sans-serif;
    }

    p{
        margin: 0;
        color: #747474;
        font-weight: 600;
        &::first-letter {
            text-transform: uppercase !important;
        }
    }

    .bar-heading {
        margin: 0;
        font-size: 12px !important;
        padding: 0; 
    }

    .global-amount {
        font-size: 23px;
        font-weight: 600;
    }
      
    .bubble {
        background: #efeeee;
        color: black;
        padding: 4%;
        border-radius: 8px;
        margin-top: 3%;

        .bubble-heading {
            display: flex; 
            justify-content: space-between; 
            align-content: center;
        }
    }
      
    .amount-slider {
        pointer-events: none;
    }
      
    .progress-bar {
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        background-color: var(--tertiary-color);
        transition: width .6s ease;
        border-radius: .5rem;
    }
      
    .progress {
        display: flex;
        height: 1.3rem;
        font-size: .75rem;
        background-color: grey;
        border-radius: .5rem;
        width: 200px;
        border: 0.2px solid black;
        align-self: center;
        overflow: hidden;
    }
      
    .flex-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .progress-container {
        width: fit-content; 
        display: inline-flex;
        align-items: center;
        overflow: unset !important;
        justify-content: flex-end;
    }

    .d-flex {
        display: flex;
    }

    .d-inline-block {
        display: inline-block;    
    }

    .d-block {
        display: block;
    }

    .pl-2 {
        padding-left: 2%;;
    }
    
    .pr-10 {
        padding-right: 10px;
    }

    .t-r {
        text-align: right;
    }

    .t-l {
        text-align: left;
    }

    .bonus-pending-btn {
        border-radius:var(--button-border-radius);
        font-size: 13px !important;
        padding: 3px !important;
        margin-top: 40px;
        &::first-letter {
            text-transform: uppercase !important;
        }
    }

    .pending-bonuses-heading {
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 600;
        color: #3e3d3d;
        margin: 8% 0 5%;
    }

    .bonus-divider {
        padding-top: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid #00000021;
    }

    #bonus-active-container-template {
        padding-top: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid #00000021;
    }

    .bonus-pending-parent {
        opacity: 0;
        position: absolute;
        z-index: -1;
        transition: all .4s ease-in-out;
    }

    .fade-in-down {
        animation: fadeInDown .4s ease-in both;
    }

    .fade-out-up {
        animation: fadeOutUp .4s ease-in both;
    }

    .no-active-bonuses-h {
        font-size: 14px;
        margin-top: 4%;
        text-align: center;
    }

    @media screen and (max-width: 400px) {
        .progress {
            height: 1rem !important;
            width: 95px !important;
        }
    }
}


@media screen and (max-width: 360px) {
    .mudal-dialog {
        max-width: 360px !important;
        > header * {
                font-size: 29px !important;
        }
    }
}

@media screen and (min-width: 361px) and (max-width: 400px) {
    .mudal-dialog {
        max-width: 380px !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 450px) {
    .mudal-dialog {
        max-width: 427px !important;
    }
}

@keyframes fadeInDown {
	from {
        position: absolute;
        z-index: -1;
		opacity: 0;
		transform: translate3d(0, -40%, 0);
	}
	to {
        position: relative;
        z-index: unset;
        display: block;
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeOutUp {
	from {
        position: relative;
        z-index: unset;
        opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	to {
        position: absolute;
        z-index: -1;
		opacity: 0;
		transform: translate3d(0, -40%, 0);
	}
}