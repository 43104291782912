.site-index-container {
    padding: 50px 100px;
    *{
        color: var(--primary-color);
    }
    .link-list {
        margin-left: 20px;
        .link-list-item, .link-list-item-child {
            display: block;
            font-size: 16px;
            transition: 0.2s all ease-in-out;
            text-transform: lowercase;
            &:hover {
                font-size: 18px;
                color: var(--tertiary-color);
            }
            &:first-letter {
                text-transform: uppercase;
            }
        }
        .link-list-item {
            margin-top: 15px;
        }
        .link-list-item-child {
            margin-left: 15px;
        }
    }
}