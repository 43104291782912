#about-wor {
	max-width: 980px;
  padding-left:0;
  padding-right:0;
  word-wrap: normal;

  h3{
    font-weight: bold;
  }

	.rizk-btn {
		padding:10px 60px;
		font-size:18px;
	}
	.gray-gradient {
	  height:88px;
	  border-top: var(--secondary-bg-button) 1px solid;
    background: linear-gradient(0deg, var(--secondary-color), var(--secondary-bg-button));
	}
	img.wheel{
		width:80%;
		max-width: 618px;
  }
  .wheel-banner{
    width: 100%;
    aspect-ratio: 980 / 606;
    border-radius: 10px;
  }
  .how-it-works-container{
    padding: 20px 15px 0;
  }
  .wor-title-new{
    font-family: 'Figtree', sans-serif;
    font-size: 26px;
    margin: 25px auto;
  }
  .steps-container, .wheels-list-container{
    display: flex;
    padding: 30px 0;
    margin: 25px auto 0;
    list-style: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  .steps-text-container{
    min-height: 150px;
  }
  .card, .wheel-card{
    display: flex;
    flex-direction: column;
    min-width: 238px;
    padding: 20px 10px;
    scroll-snap-align: start;
    transition: all 0.2s;
    text-align: center;
  }
  .single-step-container{
    background: var(--steps-gradient);
    background-color: var(--steps-bg);
    border-radius: 10px;
    border-top: var(--wor-steps-border) 3px solid;
    padding: 0 10px 25px;
    box-shadow: 0 5px 4px 0 var(--boxes-shadow);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 350px;
  }
  .step-img{
    max-width: 90px;
    transform: translateY(-50%);
  }
  .steps-logo-container{
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .step-img-logo{
    max-width: 90px;
  }
  .wor-steps-title, .terms-title{
    font-size: 16px;
    font-weight: 900;
    line-height: 20px;
    margin-top: -25px;
    min-height: 40px;
  }
  .wor-steps-text{
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin: 10px 0;
  }
  .btn-wor{
    padding: 10px 35px;
    font-family: 'Figtree', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 900;
  }
  .wheels-container, .rewards-container, .terms-container{
    padding: 20px 15px 0;
    margin: 50px 0 0 0;  
  }
  .wheels-content, .rewards-content{
    background-color: var(--wor-wheel-content-bg);
    background:  var(--wor-wheel-content-gradient);
    border-radius: 10px;
    border-top: 4px solid var(--wor-steps-border);
  }
  .wheels-main-title-container, .rewards-main-title-container{
    position: relative;
    display: inline-flex;
    justify-content: center;
    height: 55px;
    background: var(--main-title-bg-gradient);
    padding: 0px 30px;
    align-items: center;
    transform: translateY(-50%);
    filter: var(--wor-main-title-border);

  }
  .wheels-main-title-container::before, 
  .wheels-main-title-container::after,
  .rewards-main-title-container::before,
  .rewards-main-title-container::after{
    content: '';
    position: absolute;
    width: 30px;
    height: 100%;
    background: inherit;
    mask-image: var(--wor-wheel-main-title-corner-right-url);
  }
  .wheels-main-title-container::before,
  .rewards-main-title-container::before{
    left: -20px;
    mask-size: contain;
    mask-repeat: no-repeat;
    transform: scaleX(-1);
  }
  .wheels-main-title-container::after,
  .rewards-main-title-container::after{
    right: -20px;
    mask-size: contain;
    mask-repeat: no-repeat;
  }
  .wheels-main-title, .rewards-main-title{
    color: var(--wor-wheels-main-title-color);
    margin-top: -77px;
    position: relative;
  }
  .wheels-main-title,
  .rewards-main-title{
    margin-top: -3px;
    margin-bottom: 0;
  }
  .wheels-main-title-container .wheels-main-title::before, 
  .wheels-main-title-container .wheels-main-title::after,
  .rewards-main-title-container .rewards-main-title::before, 
  .rewards-main-title-container .rewards-main-title::after{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--wor-wheel-main-title-stars-bg);
    mask-image: var(--wor-wheel-main-title-stars-url);
  }
  .wheels-main-title-container .wheels-main-title::before,
  .rewards-main-title-container .rewards-main-title::before{
    mask-size: contain;
    mask-repeat: no-repeat;
    top: -10px;
    left: -15px;
    scale: -1;
  }
  .wheels-main-title-container .wheels-main-title::after,
  .rewards-main-title-container .rewards-main-title::after{
    mask-size: contain;
    mask-repeat: no-repeat;
    bottom: -10px;
    right: -15px;
  }
  .wheels-title-container, .rewards-title-container{
    margin-top: 0;
    padding: 0 15px;
  }
  .wheels-title{
    color: var(--wor-wheels-title-color);
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .wheels-list-div-container {
    overflow-x: scroll; 
    position: relative;
    width: 100%; 
    scroll-behavior: smooth;
}
  .wheels-list-container {
    margin-top: 10px;
    display: flex;
    transition: transform 0.5s ease;
    overflow: visible;
  }
  .single-wheel-container{
    margin-top: -150px;
  }
  .wheel-img-container{
    transform: translateY(50%);
    max-width: 200px;
    margin: 0 auto;
  }
  .wheel-img{
    width: 100%;
  }
  .wheel-text-container{
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 5px 4px 0 var(--boxes-shadow);
    padding-top: 110px;
    min-height: 340px;
  }
  #wheel-01-text-container{
    border-top: 3px solid var(--wor-wheel-01-border);
    background: var(--wor-wheel-01-gradient);
  }
  #wheel-02-text-container{
    border-top: 3px solid var(--wor-wheel-02-border);
    background: var(--wor-wheel-02-gradient);
  }
  #wheel-03-text-container{
    border-top: 3px solid var(--wor-wheel-03-border) ;
    background: var(--wor-wheel-03-gradient);
  }
  #wheel-04-text-container{
    border-top: 3px solid var(--wor-wheel-04-border);
    background: var(--wor-wheel-04-gradient);
  }
  .wheel-title{
    margin-top: 25px;
    margin-bottom: 0;
    color: var(--wor-wheel-title-color);
  }
  .wheel-text{
    color: var(--wor-wheel-text-color);
  }
  .controls{
    display: none;
  }
  .controls ul{
    position: relative; 
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  .controls ul li{
    width: 13px;
    height: 13px; 
    border-radius: 50px;
    margin: 5px;
    background: var(--wor-wheel-controls-bg);
    cursor: pointer;
  }
  .controls ul li.active{
    background: var(--wor-wheel-controls-bg-active);
  }

  .rewards-container{
    margin-top: 75px;
  }
  .rewards-content, .terms-content{
    background-color: var(--wor-rewards-bg-color);
    padding: 50px 10px 20px 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .rewards-content{
    padding-top: 0;
  }
  .rewards-title{
    margin-bottom: 0;
    margin-top: 10px;
  }
  .single-reward-title{
    color: var(--wor-single-reward-title-color);
  }
  .rewards-list-div-container{
    margin-top: 80px;
  }
  .rewards-list-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(295px, 1fr));
    gap: 100px 20px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .rewards-card{
    min-width: 295px; 
    max-width: 400px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--wor-rewards-card-bg);
    background: var(--wor-rewards-card-gradient);
    padding: 0 20px 20px;
    border-radius: 10px;
    box-shadow: 0 5px 4px 0 var(--boxes-shadow);
  }
  #reward-01-card{
    border-top: 3px solid var(--wor-rewards-card-01-border);
  }
  #reward-02-card{
    border-top: 3px solid var(--wor-rewards-card-02-border);
  }
  #reward-03-card{
    border-top: 3px solid var(--wor-rewards-card-03-border);
  }
  #reward-04-card{
    border-top: 3px solid var(--wor-rewards-card-04-border);
  }
  #reward-05-card{
    border-top: 3px solid var(--wor-rewards-card-05-border);
  }
  #reward-06-card{
    border-top: 3px solid var(--wor-rewards-card-06-border);
  }
  .reward-img-container{
    display: flex;
    align-items: center;
    max-width: 90px;
    min-height: 90px;
    margin: 0 auto;
    transform: translateY(-50%);
  }
  #reward-06-img-container{
    max-width: 150px;
  }
  img.reward-img{
    width: 100%;
  }
  .rewards-bottom-container{
    padding: 0;
  }
  .bottom-title-container{
    padding: 25px 0;
  }
  .rewards-title-bottom, .terms-title{
    text-transform: none;
  }
  .rewards-title-bottom{
    color: var(--wor-rewards-title-bottom);
  }
  .rewards-bottom-btn-container{
    display: flex;
    flex-direction: column;
    transform: translateY(50px);
    height: auto;
    margin: -100px 0 0 0;
    transform: translateY(10px);
    position: relative;
  }
  .btn-wrapper{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    transform: translateY(90px);
  }
  .rewards-bottom-btn-img, .rewards-bottom-btn-img-hover{
    cursor: pointer;
    max-width: 200px;
    margin: 0 auto;
    position: relative;
    opacity: 1;
  }
  .rewards-bottom-btn-img-hover{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  .btn-wrapper:hover .rewards-bottom-btn-img{
    opacity: 0;
  }
  .btn-wrapper:hover .rewards-bottom-btn-img-hover{
    opacity: 1;
  }
  .btn-bottom{
    background: none;
    font-size: 30px;
    padding: 0 25px;
    line-height: 30px;
    position: relative;
    z-index: 2;
    margin: auto;
    margin-top: -280px;
    width: 200px;
    height: 200px;
    transform: translateY(70px);
    color: var(--wor-btn-bottom);
  }
  .bottom-img-container{
    transform: translateY(-1px);
    position: relative;
    z-index: -2;
  }
  .rewards-bottom-bg-img {
    width: 100%;
    position: relative;
  }
  .terms-container{
    margin-top: 100px;
  }
  .terms-content{
    border-radius: 10px;
    padding: 20px 10px;
    background-color: var(--wor-rewards-bg-color);

  }
  .terms-title{
    margin: 0 auto;
  }
  .cta{
    color:var(--tertiary-color);
    font-size: 32px;
    margin-top:60px;
  }
  .points{
    margin: 0 auto;
    padding-bottom:60px;
    width: 50%;
    min-width: 200px;
    max-width: 570px;
    word-wrap: normal;
    h5 {
      margin: 0 0 10px 0;
    }
    img{
      height:200px;
      padding-bottom:20px;
    }
  }

  .rewards {
    .img-wrap{
      height: 100px;
    }
    img{
      height:80px;
    }

  }
}
@media (max-width: 900px) {
  #about-wor {
    .steps-container{
      width: 100%;
    }
    h3{
      margin-top: 15px;
    }
  }
}
@media (max-width: 768px) {
  #about-wor {
    .steps-text-container{
      min-height: 130px;
    }
    .rewards-card{
      min-width: 250px;
    }
  }
}
@media (max-width: 650px){
  #about-wor{
    .rewards-card{
      min-width: 400px;
    }
  }
}
@media (max-width: 500px) {
  #about-wor {
    padding-top: 0;
    .wheel-banner{
      aspect-ratio: 500 / 640;
      border-radius: 0;
    }
    .wor-title-new{
      margin: 10px auto;
    }
    .steps-container{
      width: 100%;
      margin: 0 0 0 15px;
      flex-direction: column;
      overflow-x: hidden;
      padding: 0 0 15px 0;
    }
    .steps-logo-container{
      display: flex;
      align-items: center;
    }
    .card{
      flex: 0 0 100%;
      text-align: left;
    }
    .single-step-container{
      border-left: var(--wor-steps-border) 3px solid;
      border-top: none;
      flex-direction: row;
      justify-content: center;
      padding: 15px;
      min-height: 150px;
      box-shadow: 0 3px 3px 0 var(--boxes-shadow);
    }
    .steps-image-container{
      position: absolute;
      left: 5px;
    }
    .step-img{
      max-width: 80px;
      transform: none;
    }
    .steps-text-container{
      padding: 0 10px 0 35px;
      min-height: auto;
    }
    .wor-steps-title{
      margin: 0 0 15px 0;
      min-height: auto;
    }
    .step-img-logo, .reward-img-container{
      max-width: 90px;
    }
    .wheels-main-title, .rewards-main-title{
      //margin-top: -65px;
      font-size: 20px;
    }
    .wheels-title, .rewards-title{
      font-size: 20px;
    }
    .wheel-title{
      margin-top: 15px;
    }
    .wheel-text-container{
      min-height: 310px;
    }
    .controls{
      display: flex;
    }
    .rewards-container{
      margin-top: 50px;
    }
    .rewards-list-container{
      gap: 70px 20px;
    }
    .rewards-card{
      min-width: 100%;
    }

    .reward-img-container{
      min-height: 80px;
    }
    .reward-title{
      margin-top: -20px;
    }
    img.rewards-bottom-bg{
      transform: translateY(50px);
    }
    .rewards-title-bottom{
      font-size: 26px;
    }
    .btn-wrapper{
      transform: translateY(40px);
      margin: 20px auto 0 auto;
    }
    .btn-bottom{
      font-size: 26px;
      line-height: 28px;
      margin-top: -245px;
    }
    .rewards-bottom-btn-img, .rewards-bottom-btn-img-hover, .btn-bottom{
      max-width: 170px;
      max-height: 170px;
    }
  }
}
