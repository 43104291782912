.osm-ul-list() {
    margin: 15px 0;

    li {
        list-style: disc;
        list-style-position: inside;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.mudal-inbox {
    display: flex;
    flex-direction: column;
    background-color: var(--osm-background-color);
    color: var(--primary-color);
    width: calc(100% - 48px);
    max-width: 551px;
    border-radius: 5px;
    overflow: inherit;

    &[bonus-box-shown="true"]{
        #inbox-dismiss{
            display: none;
        }

        #inbox-bonus-container{
            display: flex;
        }

        #inbox-bonus-container-button{
            display: flex;
        }
    }

    &[bonus-box-shown="false"]{
        #inbox-dismiss{
            display: flex;
        }

        #inbox-bonus-container{
            display: none;
        }

        #inbox-bonus-container-button{
            display: none;
        }
    }

    #inbox-container {
        position: absolute;
        left: 0; 
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        padding: 0px;

        header {
            padding: 0 25px;
            min-height: 57px;
            display: flex;
            align-items: center;
            position: relative;
            background: var(--osm-background-header-color) !important;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
    
            #inbox-msg-date {
                font-weight: 400;
                font-size: 16px;
                color: #FFF;
                margin-left: 10px;
                padding: 5px 0 0 0;
            }
        
            img {
                height: 34px;
                max-width: 115px;
            }
        }

        #inbox-wrapper {
            overflow-y: auto;
            flex-grow: 1;
            margin: 32px 29px 0px 29px;
            max-height: 480px;
            
            #inbox-msg-title {
                .gibson-semi-bold;
                font-size: 24px;
                line-height: 30px;
                color: var(--primary-color);
                margin-bottom: 16px;
                font-family: "Figtree", sans-serif;
            }

            #inbox-image {
              max-width: 100%;
              margin-bottom: 32px;
              border-radius: 5px;
            }

            .inbox-msg-text {
                font-size: 16px;
                line-height: 21px;
                
                a {
                    color: #FFF;
                    text-decoration: underline;
                    font-weight: 600;
                }


                p {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }

                ul {
                    .osm-ul-list();
                }
            }
    
            &::-webkit-scrollbar-thumb {
                background: #E2E4E6;
                border-radius: 20px;
            }
            
            &::-webkit-scrollbar-track {
                background: #263042;
                border-radius: 20px;
            }
            
            &::-webkit-scrollbar {
                display: block;
                width: 8px;
            }
        }

        #inbox-dismiss {
            align-items: center;
            justify-content: center;

            button {
                color: @brand-white;
                background: var(--brand-green);
                padding: 10px 20px;
                width: 100%;
                text-transform: uppercase;
                line-height: 21px;
                font-size: 14px;
                border-radius: var(--button-border-radius);
                margin: 0 !important;
                .gibson-bold;
                padding: 13px 5px 10px 5px;
            }
        }

        #inbox-bonus-container {
            justify-content: center;
            flex-direction: column;
            align-items: center;

            #inbox-bonus-description {
                max-width: 369px;
                min-height: 147px;
                margin-top: 19px;
                margin-bottom: 45px;
                box-sizing: border-box;
                border: none;
                border-radius: 5px;
                background: var(--osm-bonus-background);
                padding: 0;
                align-items: center;

                &::before, &::after {
                    content: url(/assets/img/ticket-background-side-rizk.png);
                    width: 17px;
                    height: 124px;
                }
                &.inkabet-ticket::before, &.inkabet-ticket::after {
                    content: url(/assets/img/ticket-background-side-inkabet.png);
                }
                &.betsson-ticket::before, &.betsson-ticket::after {
                    content: url(/assets/img/ticket-background-side-betsson.png);
                }

                &::after {
                    transform:scaleX(-1);
                }

                .bonus-description-container {
                    display: flex;
                    align-content: center;
                    justify-content: left;
                    flex-direction: column;
                    padding: 30px 35px;
    
                    .p, .title, .description {
                        font-size: 16px;
                        line-height: 21px;
                        color: var(--osm-bonus-color);
                        text-align: center;
                        width: 100%;
                    }

                    .description {
                        a {
                            color: #000;
                            text-decoration: underline;
                            font-weight: 600;
                        }
                    }

                    p {
                        margin-bottom: 5px;
                        
                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    .title {
                        // color: #008b2e;
                        text-transform: uppercase;
                        margin-left: 0;
                        font-weight: bold;
                    }
            
                    .description {
                        margin-left: 0;
                    }
    
                    &:hover {
                        cursor: pointer;
                    }

                    ul {
                        .osm-ul-list();
                    }
                }
            }
          .bonus-description-container-button {
            display: flex;
            align-content: center;
            justify-content: left;
            flex-direction: column;
            padding: 30px 35px;

            #collect-bonus-button {
              margin: 0;
              font-size: 14px;
              line-height: 14px;
              min-height: 36px;
            }
          }
        }
    }

    button[name="close"]::before, button[name="close"]::after {
        width: 3px;
        height: 15px;
        background-color: #FFF;
        border-radius: 2px;
        content:"";
    }

    button[name="close"]::before {
        transform: rotate(45deg);
    }

    button[name="close"]::after {
        transform: rotate(135deg);
        margin-left: -3px;
    }

    button[name="close"] {
        top: 12px;
        right: 25px;
        width: 34px;
        height: 34px;
        background: none;
        background-color: rgba(39, 39, 39, 0.25);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.inbox-inner {
    padding: 24px 29px 0 29px;
}

.inbox-inner-button {
    padding: 24px 29px 27px 29px;
}

#mudal[scroll="true"]{
    #inbox-wrapper{
        padding-right: 16px;
    }
}

.account-content .inbox-archive {
    thead td {
        color: @brand-white;
    }
    td:last-child {
        text-align: left;
        width: 75%;
    }
}

@media screen and (max-width: 750px) {
    .mudal-inbox {
        width: 100%;

        #inbox-container {
            position: relative;

            header { 
                padding: 0 18px;
                min-height: 63px;
            }

            #inbox-wrapper {
                margin: 30px 18px 0px 18px;
                flex-grow: unset;
                  max-height: 452px;

                #inbox-msg-title {
                    margin-bottom: 25px;
                    line-height: 28px;
                }
            }

            #inbox-bonus-container {
                padding: 14px 34px 0 34px;

                #inbox-bonus-description {
                    margin-bottom: 17px;
                }
            }
        }

        button[name="close"] {
            height: 40px;
            width: 40px;
            right: 18px;
            top:13px;
        }

        button[name="close"]::before,
        button[name="close"]::after {
            height: 17px;
        }

        #inbox-bonus-description {
            margin-bottom: 17px;
        }
    }

    #mudal[inbox-message="true"] {
        top: 0px;

        .mudal-container {
            display: flex;
            
            .mudal-content {
                width: calc(100% - 48px);
                height: auto;
                margin: auto;
            }
        }
    }
}

@media screen and (min-width: 751px) {
    #inbox-msg-title {
        font-size: 28px;
    }

    #inbox-wrapper{
        margin-top: 24px;
    }
    
    .inbox-msg-text {
        font-size: 18px;
        line-height: 28px;
    }
   
    #inbox-container {
        position: relative !important;
    }
} 
