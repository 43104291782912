@import 'category-filter-icons.less';
@import 'breakpoints';

@ticker-line-height: 40px;
.insider-ticker {
  height: 40px;
  background: @insider-bg-color;
  position: relative;
  margin: 24px @spacing;
  .rizk-border-radius;
  .insider-logo-container {
    float: left;
    padding: 6px 21px;
    font-size: 0px;
    margin-right: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    .mobile({
      margin-right: 0;
    });
    &.bg-brand-red {
      background-color: @brand-red;
    }
    &.bg-brand-gray {
      background-color: @insider-background-single-article;
    }
    .insider-logo {
      width: 42px;
      height: 28px;
    }
    @media screen and (max-width: 464px) {
      padding: 6px 8px;
    }
  }
  .article-thumbnail {
    position: absolute;
    height: 60px;
    width: 60px;
    top: 0;
    left: 74px;
    img {
      position: absolute;
      top: 33%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
  }
  .ticker-title {
    float: left;
    @media screen and (max-width: 1200) {
      max-width: 550px;
    }
    @media screen and (max-width: 992px) {
      max-width: 450px;
    }
    @media screen and (max-width: 700px) {
      max-width: 350px;
    }
    @media screen and (max-width: 564px) {
      max-width: 230px;
    }
    .title {
      margin: 0 0 0 15px;
      padding: 0;
      font-size: 14px;
      line-height: @ticker-line-height;
      text-transform: none;
      letter-spacing: 0;
      a {
        color: @insider-title-color;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          color: var(--tertiary-color);
          text-decoration: none;
        }
        .handle {
          color: var(--tertiary-color);
          margin-right: 2px;
        }
      }
    }
  }
  .date {
    line-height: @ticker-line-height;
    color: @warm-grey;
    font-size: 14px;
    margin-left: 15px;
    float: left;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  .categories {
    float: left;
    margin-left: 10px;
    @media screen and (max-width: 768px) {
      display: none;
    }
    .cat-list {
      position: relative;
      top: 9px;
      .small-cat {
        font-size: 13px;
        padding: 1px 8px;
      }
      .cat a {
        color: inherit;
        font-size: 13px;
      }
    }
  }
  .read-more {
    float: right;
    margin-right: 20px;
    a {
      color: @brand-red;
      line-height: @ticker-line-height;
      font-size: 16px;
      .gibson-semi-bold;
    }
  }
  .hidden-xs {
    @media screen and (max-width: 1200) {
      display: none;
    }
  }
}

/* List of categories */

.cat-list {
  display: inline-block;
  list-style: none;
  position: absolute;
  top: 0;
  right: 0;
  margin-left: 8px;
  li {
    float: left;
    text-transform: uppercase;
    &.cat {
      padding: 5px 18px;
      color: @brand-black;
      font-size: 13px;
      letter-spacing: 0.9px;
      border-radius: 0 0 0 10px;
      .gibson-semi-bold;
      background: var(--insider-label-bg);
      a {
        color: var(--secondary-color);
      }
      .mobile({
        font-size: 14px;
      });
    }
    &.cat-video,
    &.cat-blogg,
    &.cat-blog,
    &.cat-blogi {
      background-color: @cat-blogi;
      color: @brand-white;
      a {
        color: @brand-white;
      }
    }
    &.cat-raffle,
    &.cat-arvonta,
    &.cat-uncategorized {
      background-color: @cat-green;
      color: @brand-white;
      a {
        color: @brand-white;
      }
    }
    &.cat-exclusive,
    &.cat-promotion,
    &.cat-jackpot {
      background-color: var(--tertiary-color);
      color: @brand-black;
      a {
        color: @brand-black;
      }
    }
    &.cat-promotion {
      background-color: @brand-white;
      color: @brand-black;
      a {
        color: @brand-black !important;
      }
    }
    &.cat-kilpailu {
      background-color: @cat-light-blue;
    }
    &.cat-insider {
      background: @cat-violet;
      color: @brand-white;
      a {
        color: @brand-white;
      }
    }
  }
}

/* Subposts */

.sub-posts {
  li {
    position: relative;
    align-items: flex-start;

    .cat-list li.cat {
      font-size: 13px;
    }
    .insider .articles & .title {
      .header-font(29px);

      margin: 15px;
      text-transform: uppercase;
      
      a & {
        color: #f5f5f5;
      }
    }
    .excerpt {
      font-size: 17px;
      margin: 0 15px;
      color: var(--insider-article-copy-color);

      p {
        margin-top: 0px;
      }
    }
    .author {
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    .author,
    .date-time {
      color: @warm-grey;
      font-size: 14px;
      margin: 0 0 12px 12px;
    }
    .date-time {
      margin-bottom: 10px;
    }
    &.show-more {
      display: block;
      clear: both;
    }
  }
}

.sub-title {
  .header-font(28px);

  color: var(--tertiary-color);
  margin: 0 10px 0 0;
  text-transform: uppercase;
}

.category-description {
  color: @insider-description-color;
  font-size: 16px;
  margin-right: 10px;
}

.articles {
  margin: 0 auto;
  max-width: 1080px;
  .cat-list .cat-promotion a {
    color: @brand-black;
  }
  a {
    color: @brand-white;
    transition: all 0.2s ease-in;
    &:hover {
      text-decoration: none;
      color: var(--tertiary-color);
      .title {
        transition: all 0.2s ease-in;
        color: var(--tertiary-color);
      }
    }
  }
}

/* Insider styles */

.insider {
  background: var(--body-background-color);
  color: var(--primary-color);
  &.home {
    // Media query for showing modified things 1150px max
    .article-first .article {
      width: 100%;
      overflow: hidden;
      float: left;
      .featured-image {
        height: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .article-sticky .article,
    .article-sub .article {
      width: 100%;
    }
    @media screen and (max-width: 1150px) {
      .article-sticky {
        display: none;
      }
    }
    .cat-list {
      .cat-promotion {
        display: block;
      }
      .date-time {
        color: @brand-white;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.85);
        position: relative;
        top: 1px;
      }
    }
    .article {
      position: relative;
      img {
        width: 100%;
      }
    }
    .article-container {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100% !important;
      color: @brand-white;
      padding: 20px 30px;
      display: grid;
      grid-template-rows: 90% 1fr;
      background: rgba(0, 0, 0, 0)
        linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 10%,
          rgba(0, 0, 0, 0.1) 60%,
          rgba(0, 0, 0, 0.5) 75%,
          rgba(0, 0, 0, 1) 100%
        )
        repeat scroll 0 0;
      .mobile({
          position: relative;
          padding: 0;
          .title {
            margin-top: 20px;
          }
          .cat-list {
            margin-left: 0;
          }
        });
      .title {
        .header-font(35px);

        color: var(--primary-color);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.85);
        word-wrap: break-word;
        text-transform: uppercase;
        align-self: end;
        padding-top: 15px;
      }
      .author-image {
        width: 50px;
        border-radius: 25px;
        margin-right: 10px;
        .mobile({
          display: none;
        });
      }
      .author-name {
        .header-font(18px);

        text-transform: uppercase;
        letter-spacing: @letr-spacing-medium;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.85);
        position: relative;
        top: 3px;
      }
      .excerpt {
        font-size: 17px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.85);
      }
    }
  }
  /* Single post view */
  &.single {
    .cta-container {
      color: @brand-black;
      background-color: var(--tertiary-color);
      a.tc-link {
        margin: 0 6px;
        color: @brand-black !important;
        text-decoration: underline;
      }
      .rizk-btn {
        margin: 20px;
        min-width: 20%;
      }
      p {
        color: @brand-black;
      }
    }
    b,
    strong {
      .gibson-semi-bold;
      color: var(--primary-color);
    }
    .meta {
      margin-bottom: 10px;
    }
    .video-player {
      height: 580px;
      width: 100%;
      .mobile({
        height: auto;
      });
    }
    .notice-bar {
      display: block;
      margin-top: 13px;
      background: var(--secondary-bg-button);
      width: 100%;
      padding: 5px 0;
      color: @brand-white;
    }
    .featured-image {
      height: auto;
      max-width: 1080px;
      width: 100%;
    }
    .content {
      font-size: 17px;
      margin: 0 auto 20px auto;
      max-width: 1080px;
      .gibson-light;
      .mobile({
        margin-right: 20px;
        margin-left: 20px;
      });
      a span {
        color: @brand-white;
      }
      span a {
        color: @brand-white;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      table {
        width: 100%;
        margin-bottom: 40px;
      }
      .the-content {
        .cat-list {
          padding: 0;
          list-style: none;
          .date-time {
            color: @brand-white;
          }
        }
      }
    }
    .author-info {
      display: grid;
      grid-column-gap: 30px;
      grid-template-columns: auto 1fr;
      background: var(--secondary-body-background-color);
      border: 1px solid var(--border-color);
      padding: 20px 10px 20px 20px;
      border-bottom: 2px solid var(--tertiary-color);
      @media screen and (max-width: @menu-switch-height-breakpoint) {
        display: block;
        .image {
          margin-bottom: 20px;
        }
      }
      .name {
        color: var(--primary-color);
        margin-left: 0px;
        justify-self: center;
      }
      .author-image {
        width: 96px;
        height: 96px;
      }
      @media screen and (min-width: 100px) and (max-width: 1200px) {
        &.left-col {
          display: none;
        }
        &.bottom {
          text-align: left;
          display: block;
          float: none;
          border-top: 1px solid #353535;
          border-bottom: 1px solid #353535;
          padding-top: 20px;
          width: 100%;
          height: auto;
          margin: 40px 0;
          .some-accounts li {
            display: inline-block;
            margin-right: 10px;
            a {
              padding-left: 0;
            }
          }
          .image {
            float: left;
            margin-right: 20px;
          }
          p {
            margin-bottom: 10px;
          }
        }
      }
      @media screen and (min-width: 1200px) {
        &.bottom {
          display: none;
        }
      }
      .author-image {
        border-radius: 48px;
      }
      .name {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 200;
      }
      .description {
        color: var(--secondary-color-button);
        font-size: 16px;
        margin-bottom: 10px;
      }
      .some-accounts a {
        color: @insider-author-some-color;
        display: block;
        margin-right: 20px;
        font-size: 14px;
        .some-icons {
          float: left;
          margin-right: 5px;
          position: relative;
          top: 2px;
        }
      }
    }
    .the-content {
      h1,
      h2,
      h3,
      h4,
      h5 {
        text-transform: uppercase;
        margin: 20px;
        font-family: var(--header-font), sans-serif;
      }
      .title {
        .header-font(42px);

        word-wrap: break-word;
        color: var(--primary-color);
        margin: 50px 20px 20px 20px;
      }
      .secondary-content {
        margin-top: 30px;
        margin-bottom: 20px;
        &.ml-20 {
          margin-left: 20px;
        }
        ul {
          list-style: none;
        }
        li {
          padding-left: 15px;
          position: relative;
          margin-bottom: 5px;
        }
        li:before {
          content: '';
          border-color: transparent var(--tertiary-color);
          border-style: solid;
          border-width: 5px 0 5px 7px;
          display: block;
          height: 0;
          width: 0;
          left: -5px;
          top: 7px;
          position: absolute;
        }
        p {
          margin: 0 20px;
        }
        a {
          color: @brand-white;
        }
      }
      .bullet-points > ul {
        list-style: none;
        li {
          padding-left: 15px;
          position: relative;
        }
        li:before {
          content: '';
          border-color: transparent var(--tertiary-color);
          border-style: solid;
          border-width: 5px 0 5px 7px;
          display: block;
          height: 0;
          width: 0;
          left: -5px;
          top: 7px;
          position: absolute;
        }
        p {
          margin: 0 20px;
        }
      }
      .border-top-red {
        border-top: 2px solid #ff5149;
        padding-top: 15px;
      }
    }
    .secondary-content {
      table {
        margin: 20px 0;
        > tbody > tr:nth-of-type(odd) {
          background-color: #f9f9f9;
        }
        td {
          padding: 5px;
        }
        td:nth-child(1) {
          width: 20%;
        }
      }
    }
    &.seo {
      .the-content {
        margin-left: 0;
        @media screen and (max-width: 1340px) {
          padding-left: 60px;
        }
      }
    }
    .cta-container-sports {
      color: @brand-white;
      background-color: @brand-red;
      display: grid;
      grid-template-columns: auto 200px;
      @media (max-width: @menu-switch-breakpoint) {
        grid-template-columns: none;
      }
      p {
        color: @brand-white;
      }
      .cta-sports-red {
        height: 40px;
        align-self: center;
        margin: 20px;
      }
    }
  }
  .alignleft {
    margin-right: 30px;
    margin-bottom: 30px;
  }
  .alignright {
    margin-bottom: 30px;
    margin-left: 30px;
  }
  .date-time {
    font-size: 14px;
  }
  .meta {
    line-height: 20px;
  }
}

.optin-form-reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;

  button {
    padding: 10px 45px;
  }

  .optin-message {
    font-size: 18px;
    margin-top: 10px;
    display: none;
  }
}

.optin-form {
  text-align: center;
  padding: 30px 0;
  label {
    display: block;
    color: @brand-white;
  }
  input {
    display: inline-block;
    color: @brand-black;
    font-size: 16px;
    width: 100px;
    margin-right: 10px;
    .mobile({
      margin-bottom: 10px;
    });
  }
  button {
    width: auto;
    display: inline-block;
    padding: 10px 30px;
    .btn-green;
  }
  textarea {
    min-height: 140px;
    margin-bottom: 16px;
    color: @brand-black;
    outline: none;
    box-shadow: none;
    &:focus {
      border: 2px solid var(--brand-green);
    }
  }
}

.page-navigation {
  ul {
    text-align: center;
    li {
      display: inline-block;
      margin: 0 10px;
    }
  }
}

@media (max-width: @menu-switch-breakpoint) {
  .insider-ticker {
    margin: 24px 16px 0 16px;
  }
}

// New insider based on a grid
.sub-posts {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-items: center;
  grid-gap: 25px 20px;
  margin: 0 10px 10px 10px;
  li {
    background-color: var(--secondary-body-background-color);
    a {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }
  .show-more {
    grid-column-start: 1;
    grid-column-end: -1;
    justify-self: center;
    width: 250px;
    background-color: transparent;
  }
}

.grid-subtitle {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  @media (max-width: 639px) {
    grid-template-columns: auto;
    .sub-title,
    .category-description {
      margin: 0 20px;
    }
  }
  @media (max-width: 1260px) {
    .sub-title {
      margin-left: 20px;
    }
  }
}

.gray-separator-insider {
  border-top: 2px solid @insider-background-single-article;
  margin: 7px 0;
}

.main-content {
  background-color: var(--secondary-body-background-color) !important;
  border: 1px solid var(--border-color);
  box-shadow: 0 -5px 70px -10px var(--overlay-background);

  &.betsson {
    box-shadow: none;
  }

  line-height: 1.4;
  .cta-desc {
    color: @brand-black;
  }

  h1 {
    text-transform: uppercase;
  }

  img.aligncenter {
    display: block;
    margin: auto;
  }

  h4 a,
  h3 a,
  li a,
  td a,
  ol a {
    color: var(--tertiary-color);
    text-decoration: underline;
  }

  p a,
  p a strong {
    color: var(--tertiary-color) !important;
    text-decoration: underline;
  }

  h3,
  h4,
  h5,
  ul {
    margin-left: 20px;
  }

  p {
    color: var(--insider-article-copy-color);
    margin: 20px !important;
  }

  .post-data {
    font-size: 14px;
  }

  .meta .date-time {
    color: @warm-grey !important;
  }

  .meta {
    justify-self: end;
  }

  ol {
    margin-left: 40px;
  }

  @media (max-width: @menu-switch-breakpoint) {
    p > iframe {
      width: 100%;
    }
  }
}

.grid-articles {
  display: grid;
  grid-template-columns: 35px auto 35px;
  grid-template-rows: 1fr;
}

.some-accounts {
  li {
    margin-bottom: 10px;
    margin-top: 5px;
  }
}

.content-top {
  position: relative;
  margin-top: -3%;
}

.grid-cta {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  img {
    align-self: stretch;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.55);
    &.no-box-s {
      box-shadow: none;
    }
  }
  h3 {
    margin: 20px;
    font-size: 30px;
  }
  .rizk-btn {
    align-self: start;
    width: 90%;
    margin: 20px auto;
  }
  @media (max-width: 1240px) {
    grid-template-columns: 1fr;
  }
}

.grid-cta-desc {
  display: grid;
  grid-template-rows: repeat(3, auto);
  h3 {
    align-self: end;
  }
  p {
    margin: 0;
    margin-left: 20px;
    color: @brand-black;
  }
}

.insider-top-toolbar {
  .space-sticky-bar,
  .space-sticky-bar-loggedIn {
    padding-top: 50px;
  }
  .filter-btns {
    margin-top: 0px;
    margin-bottom: 10px;
    padding-left: 12px;
    .filter-btn {
      padding: 9px;
      width: 40px;
      height: 40px;
      margin: 5px 10px 5px 0;
      i {
        font-size: 20px;
      }
      &:active {
        color: @brand-black;
        background-color: var(--tertiary-color);
      }
    }
  }
  .position-vertical-choice {
    position: fixed;
    z-index: 9;
    top: 0px;
  }
  .panic-bar-margin-insider {
    top: 30px;
  }
  .vertical-choice {
    .header-font(23px);

    text-transform: uppercase;
    max-width: none;
    display: grid;
    grid-template-columns: 70px auto auto 1fr;
    letter-spacing: 0.4px;
    width: 100%;
    background-color: @brand-black;
    img {
      justify-self: end;
      align-self: center;
      cursor: pointer;
      width: 45px;
      margin: 10px 10px 10px 0px;
    }
    .insider-promotions {
      grid-column-start: 2;
      align-self: center;
    }
    .insider-sports-lobby {
      grid-column-start: 3;
      align-self: center;
    }
    .active-item {
      color: var(--tertiary-color);
    }
    .gray-separator-insider {
      width: 100%;
      grid-column-start: 1;
      grid-column-end: -1;
    }
    a {
      font-size: 24px;
      margin: 8px;
      color: @warm-grey;
      transition: all 0.1s ease-in;
      &:hover {
        color: var(--tertiary-color);
      }
    }
  }
}

.wp-video {
  margin-left: 20px;
}

// blur filter
.faded {
  position: relative;
  display: inline-block;
  color: @brand-black;
  width: 100%;
  text-align: center;
}

.faded:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-collapse: separate;
}

// .faded.faded-left.faded-right.faded-bottom:after {
//   box-shadow: inset 0px -30px 90px 30px;
// }

@media (max-width: @menu-switch-breakpoint) {
  .insider-top-toolbar {
    .position-vertical-choice-loggedIn {
      position: static;
    }
    .sticky-top-bar {
      top: 0;
      position: fixed;
    }
    .panic-bar-margin-insider {
      top: 45px;
    }
    .space-sticky-bar {
      padding-top: 50px;
    }
    .space-sticky-bar-loggedIn {
      padding-top: 0;
    }
  }
  .sticky-articles-content {
    padding-top: 50px;
  }
}

.rizk-btn.insider-deposit-cta {
  font-size: 20px;
  padding: 8px 22px;
}

// sportsbook ticker
.sb-insider-ticket {
  background: @brand-black;
  border: 1px solid @side-menu-bottom-bar;
  margin: 25px @spacing;
  margin-bottom: 3px;
  .hidden-desktop {
    font-size: 18px;
    @media screen and (min-width: 1200) {
      display: none;
    }
  }
  @media (max-width: @sb-tablet) {
    margin: 12px 8px;
  }
  .insider-logo-container {
    margin-right: 5px;
  }
  .small-cat {
    margin: 0 2px;
  }
}

.twitter-tweet {
  margin: 0 auto;
}

.post-filters {
  display: flex;
  padding-top: 25px;
  margin-left: 10px;
  li {
    padding: 8px 20px 8px 20px;
    margin-right: 10px;
    font-weight: 400;
    text-transform: capitalize;
    width: auto;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background: var(--filter-bg-btn);
    color: var(--secondary-color);
    &.not-active {
      background: var(--secondary-bg-button);
      color: var(--primary-color);
    }
  }
}

@media (min-width: @menu-switch-breakpoint) {
  .post-filters {
    li {
      &:hover {
        transform: scale(1.05);
        background: var(--filter-bg-btn);
      }
      &.not-active {
        &:hover {
          background: var(--secondary-bg-button);
        }
      }
    }
  }
}

.insider-articles {
  .grid-subtitle {
    margin-bottom: 0 !important;
    margin-left: 10px;
    .sub-title {
      color: var(--primary-color);
    }
  }
  @media (max-width: @menu-switch-breakpoint) {
    .sub-title {
      margin-left: 0 !important;
    }
    .post-filters {
      margin: 0 10px 10px 10px !important;
      padding-top: 20px !important;
      display: flex;
      white-space: nowrap;
      overflow-x: auto;
    }
    .sub-posts {
      padding-top: 20px !important;
    }
  }
  .sub-posts {
    padding-top: 50px;
    padding-bottom: 50px;
    .article-sub-post {
      padding-bottom: 15px;
      border-radius: 15px;
      img {
        border-radius: 10px 10px 0 0;
      }
    }
    .cat-list li.cat {
      border-radius: 0 10px 0 10px;
      background: var(--insider-label-bg);
      a {
        color: white !important;
        font-weight: 700;
      }
    }
  }
}

.no-access-content {
  height: 500px;
  background: #181818;
  margin-top: -24px;
  h3 {
    padding-top: 200px;
    text-align: center;
    color: white;
    font-size: 21px;
  }
}

summary {
  h2 {
    display: block;
    margin-top: -27px !important;
  }
}

.hide-dynamic-cta {
  display: none !important;
}
.show-dynamic-cta {
  display: inline-block !important;
}
.show-dynamic-cta-container {
  display: flex !important;
}
.dynamic-cta-container {
  padding: 0 !important;
}
.dynamic-cta {
  margin-bottom: 10px;
}
