// variables
@background-height: 70px;
@game-image-dimensions: 100px;
@page-padding: 30px;

#game-license {
  font-size: 10px;
  color: #444;
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  margin: 0 auto;
  width: 80%;
  transition: all 1s ease-in;
  display: none;
}

#game-session-check {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  // font-family: "League Gothic", sans-serif;

  #game-session-container {
    display: flex;
    min-height: 40%;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #292929;
    position: relative;
    border-radius: 8px;
    margin-top: 50px;

    #game-session-notification {
      width: 100%;

      header {
        font-size: 23px;
        color: @brand-yellow;
      }

      p {
        margin: 0;
        color: @brand-white;
        box-sizing: border-box;
        padding: 20px 10% 50px;
        font-size: 17px;

        a {
          color: @brand-yellow;
          text-decoration: underline;
        }
      }
    }

    #game-session-header {
      box-sizing: border-box;
      width: 100%;
      padding: 16px 68px;
      text-align: left;
      color: white;
      font-size: 40px;
      background-color: #292929;
      text-transform: uppercase;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    #game-session-form,
    #game-session-active {
      width: 100%;
      font-style: normal;
      font-weight: 400;
    }

    #game-session-form {
      padding: 40px 68px;

      button {
        width: 60%;
      }

      button:disabled {
        background-color: #444;
      }

      .fieldset {
        display: flex;
        padding-bottom: 40px;
        justify-content: space-between;

        .field-container {
          width: 45%;
          display: flex;
          flex-direction: column;
          position: relative;

          span {
            height: 8px;
            width: 12px;
            background: url('../img/icons/arrow-down.svg') no-repeat top center;
            display: block;
            pointer-events: none;
            position: absolute;
            top: 62%;
            right: 13px;
          }

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            border-color: #dbdbdb;
            height: 45px;
            width: 100%;
            padding: 0 20px;
            border-width: 1px;
            font-size: 13px;
            border-radius: 6px;
            outline: none;
            cursor: pointer;
            background-color: #464646;
            color: #dbdbdb;
          }
        }
      }
    }

    #game-session-active {
      padding: 40px;

      p {
        font-size: 17px;
        color: white;
        margin: 0;
      }

      #game-session-btn-container {
        display: flex;
        padding-top: 60px;
        justify-content: center;

        button:first-child {
          background: #009a39;
          color: #fff;
        }

        button {
          margin: 0 auto;
          font-size: 24px;
          font-weight: 400;
          border-radius: 24px;
          min-width: 130px;
          padding: 4px;
        }
      }
    }
  }
}

.live-stat-bar {
  width: 100%;

  .bar {
    box-sizing: border-box;
    display: flex;
    width: 60%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
    background: #1f1f1f;
    padding: 5px;
    border-radius: 20px 20px 0px 0px;

    .bar-item {
      font-size: 11px;
      color: white;

      span {
        display: block;
      }
    }
  }
}

.panic__button__parent {
  // border: 3px solid #ffffff82;
  height: auto;
  border-radius: 8px;
  position: relative;
  // height: 55px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .panic__button__txt__parent {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 55px;
    padding: 0px 15px;
  }

  .panic__button__txt {
    font-size: 20px;
    height: 55px;
    display: flex;
    align-items: center;
    color: black;
  }

  .slider {
    width: 661px;
    -webkit-appearance: none;
    height: 55px;
    outline: none;
    opacity: 1;
    padding: 0px;
    justify-content: center;
    align-items: center;
    color: white;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, #ef4136cc 50%, #ffe500 50%);
    transition: background-position 1s;
    transition-timing-function: linear;
    z-index: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    z-index: 10000;
    width: 55px;
    height: 55px;
    margin: 0px;
    background-image: url(../img/24hour-lock.svg);
    background-size: 35px 35px;
    background-color: #ef4136;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    pointer-events: all;
    border-radius: 5px;
  }

  .slider::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    z-index: 10000;
    width: 55px;
    height: 55px;
    margin: 0px;
    background-image: url(../img/24hour-lock.svg);
    background-size: 35px 35px;
    background-color: #ef4136;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    pointer-events: all;
    border-radius: 5px;
  }
}
.panic__button__parent:hover {
  .panic__button__txt__parent {
    z-index: -1;
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: -10px;
  left: 7px;
  height: 21px;
  width: 21px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid var(--brand-green, #009a39);
}

.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox input:checked ~ .checkmark {
  background-color: white;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--brand-green, #009a39);
}

.checkboxdiv {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 45px;
}

.footer-modal-padding {
  padding-right: 272px !important;
}

.mask-description {
  transition: all 0.3s ease-in-out;
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 20%);
  mask-image: linear-gradient(to top, transparent 0%, black 20%);
}

.gameview {
  height: 100%;
  background: @brand-black;
  right: 0;
  overflow-x: hidden;

  &.gameview-mobile {
    height: calc(100% - 30px);
    @media (max-width: @menu-switch-breakpoint) {
      height: calc(100% - 50px);
    }
  }

  &.panic-bar-margin #gameview-container[device='desktop'] .gameview-background,
  &.panic-bar-margin #gameview-container[device='desktop'] #game-view {
    height: calc(100vh - 30px);
  }

  #game-aside {
    z-index: 1;
    height: 100%;
    position: absolute;
    right: -355px;
    width: 0px;
    transition: all 0.5s ease-in;
    .race-opted-in-ml {
      margin-left: 15px;
    }
    .race-opted-in-pl {
      padding-left: 15px;
    }
  }
  .gameview-wrapper {
    display: flex;
  }

  #gameview-container {
    transition: all 0.4s ease-in;
    height: calc(100% - @mobile-drawer-menu-height);
    width: 100%;

    &[device='desktop'] {
      height: auto;
      background: @brand-black;
      margin-top: 0px !important;

      .gameview-background {
        .absolute-inset;
        height: 100vh;

        .horizontal-toggle & {
          height: calc(100vh - 120px);
        }

        &:after {
          box-shadow:
            inset 0px -11px 35px 6px #00000085,
            0 0 150px 60px #000 inset;
        }
      }

      .gameview-not-available {
        position: relative;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        -webkit-backdrop-filter: brightness(0.5) blur(5px);
        backdrop-filter: brightness(0.5) blur(5px);

        .title {
          .header-font(38px);

          text-transform: uppercase;
          margin-bottom: 16px;
          margin-top: 0px;
        }

        .text {
          width: 30rem;
          text-align: center;
        }

        button {
          margin-top: 24px;
          padding: 10px 20px;
        }
      }

      #game-view {
        display: flex;
        flex-direction: column;
        position: relative;
        transition: all 0.4s ease-in;
        padding: calc(@page-padding / 2) @page-padding;
        height: 100vh;

        .horizontal-toggle & {
          height: calc(100vh - 120px);
        }

        & > *:not(:last-child) {
          padding-bottom: calc(@page-padding / 2);
        }

        #game-iframe-container {
          position: relative;
          width: 100%;
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          #game-iframe {
            transition: all 0.3s ease-in-out;
          }
        }

        .gv-buttons {
          z-index: 1;
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          &.race-container-visible {
            right: 268px;
          }

          #panic-button-promt {
            color: white;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
            text-align: center;
            display: flex;
            flex-direction: column;
            opacity: 1;
            transition: opacity 0.8s;

            .panicbutton__infobox {
              background: white;
              color: black;
              width: fit-content;
              display: grid;
              z-index: 11000;
              padding: 1rem;
              position: absolute;
              margin-top: 75px;
              margin-left: auto;
              right: 0;
              margin-right: 1.5rem;

              .panicbutton__infobox__title {
                text-align: left;
                color: var(--brand-green, #009a39);
                font-weight: bold;
              }

              .panicbutton__infobox__text {
                text-align: left;
              }

              .panicbutton__infobox__btns {
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }

              &:before {
                content: '';
                height: 2rem;
                width: 2rem;
                transform: rotate(45deg);
                margin: 0 10px 0 0;
                background: white;
                position: relative;
                top: -25px;
                left: 55%;
              }
            }
          }
        }
      }

      .gameview-content {
        padding: @page-padding;

        :first-child {
          margin-top: 0px;
        }

        .gameview-paragraph {
          padding-right: 0px;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    &[device='mobile'][content='false'] {
      overflow-y: scroll;
      .gameview-background {
        height: 50%;
        position: absolute;

        &:after {
          box-shadow:
            inset 0px -11px 35px 6px #00000085,
            0 0 150px 60px #000 inset;
        }
      }

      .gameview-content {
        height: 100%;
        position: relative;
        align-items: center;
        padding: 60px @page-padding @page-padding @page-padding;

        @media screen and (min-height: 640px) {
          padding: 85px @page-padding @page-padding @page-padding;
        }

        @media screen and (min-height: 736px) {
          padding: 100px @page-padding @page-padding @page-padding;
        }

        h1 {
          flex-grow: 1;
        }

        .gameview-game-image {
          position: relative;
          top: 0;
          left: 0;

          @media screen and (min-height: 640px) {
            height: 125px;
            width: 125px;
          }
        }
      }
    }

    .gameview-background {
      position: relative;
      height: @background-height;
      width: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 150px 20px @brand-black inset;
      }

      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
        filter: brightness(0.5);
      }
    }

    .gameview-game-image {
      position: absolute;
      top: calc(@background-height - (@game-image-dimensions / 2));
      left: @page-padding;
      height: @game-image-dimensions;
      width: @game-image-dimensions;
      border-radius: 100%;
      margin-top: 0px !important;
    }

    .gameview-close-button {
      height: 21px;
      position: absolute;
      top: 0;
      right: 20px;
    }

    .gameview-button-group {
      display: flex;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 10px;
      }

      .button-group-right,
      .button-group-left {
        display: flex;

        button {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          font-size: 14px;
          border-radius: var(--button-border-radius);
          background: var(--tertiary-bg-color);
          color: var(--primary-color);
          transition: all 0.4s ease-in;

          &[active='true'] {
            background: var(--tertiary-color);
            color: var(--secondary-color);

            img {
              filter: invert(1);
            }
          }

          img {
            width: 12px;
            margin-right: 5px;
            margin-bottom: 3px;
            transition: all 0.4s ease-in;
          }
        }
      }

      .button-group-qd {
        flex-grow: 1;
      }
    }

    .gameview-content {
      display: flex;
      flex-direction: column;
      padding: calc(@game-image-dimensions / 2) @page-padding @page-padding @page-padding;
      color: @brand-white;
      height: calc(100% - @background-height);
      &.gameview-showmore {
        height: unset;
      }

      & > * {
        margin-top: 15px;
      }

      h1 {
        text-transform: uppercase;
        margin-bottom: 0px;
      }

      .gameview-paragraph {
        overflow-y: scroll;
        flex-grow: 1;
        padding-right: 20px;

        h1 {
          .header-font(2em);
        }

        h2 {
          .header-font(1.5em);
        }

        h3 {
          .header-font(1.17em);
        }

        h4 {
          .header-font(1em);
        }

        h5 {
          .header-font(0.83em);
        }

        h6 {
          .header-font(0.67em);
        }

        a {
          color: @brand-yellow;
        }

        :first-child {
          margin-top: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background: #8d8d8d;
          border-radius: 20px;
        }

        &::-webkit-scrollbar-track {
          background: #333333;
          border-radius: 20px;
        }

        &::-webkit-scrollbar {
          display: block;
          width: 4px;
        }
      }

      button {
        padding: 12px;
        width: 100%;
      }
    }
  }
}

@media (max-width: @menu-switch-breakpoint) {
  .gameview {
    .gameview-content {
      overflow-y: scroll;
      padding-bottom: 100px !important;
    }
    .gameview-paragraph {
      padding-bottom: 10px;
      max-height: 200px;
    }
  }
  .gv-buttons {
    top: 43px;
  }

  .live-stat-bar {
    width: 85%;
    margin: auto;

    .bar {
      width: 100%;

      .bar-item {
        font-size: 9px;
      }
    }
  }

  #game-session-check {
    #game-session-container {
      width: 95%;
      margin-top: 0;
      max-height: 97%;

      #game-session-notification {
        header {
          font-size: 18px;
        }

        p {
          font-size: 13px;
          padding: 20px 10% 20px;

          a {
            color: @brand-yellow;
            text-decoration: underline;
          }
        }
      }

      #game-session-header {
        padding: 16px 30px;
        font-size: 30px;
      }

      #game-session-form {
        padding: 40px 30px;
        height: 100%;
        overflow-y: scroll;

        button {
          width: 100%;
          margin-top: 10px;
        }

        .fieldset {
          flex-direction: column;
          padding-bottom: 0;

          .field-container {
            width: 100%;
            padding-bottom: 30px;

            span {
              top: 45%;
            }
          }
        }
      }

      #game-session-active {
        #game-session-btn-container {
          flex-direction: column;

          button {
            margin: 0;
            width: 100%;
            margin-bottom: 25px;
            padding: 9px 0;
          }
        }
      }
    }
  }
}

@media (max-height: 550px) {
  .game-license {
    display: none;
  }
}
