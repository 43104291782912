// colors
.color-w {
    color: var(--primary-color);
}

// padding by percentage
.pr-10 {
    padding-right: 10%;;
}

.p-1 {
    padding: 1%;
}

