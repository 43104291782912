// Using CSS Masking: https://medium.com/@no.steiner/change-svg-icons-color-with-css-only-ba6a4912e6ba

.glyphicon {
  display: inline-block;
  background-color: #dbdbdb;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  width: 20px;
  height: 20px;

  &.folder-icon {
    mask-image: url('/assets/img/glyphicons/folder.svg');
  }

  &.checkmark-icon {
    mask-image: url('/assets/img/glyphicons/checkmark.svg');
  }

  &.cancel-icon {
    mask-image: url('/assets/img/glyphicons/cancel.svg');
  }
}
