@account-margin: 22px;
@account-block-width: 512px;
@account-max-width: @account-block-width * 2 + @account-margin * 2;
@content-auto-margin-limit: (@side-menu-width + @account-max-width + @account-margin * 2);
@menu-collapse-screen-width: 1084px;
@menu-open-screen-width: (@menu-collapse-screen-width + 1);

.triangle-right {
  border-left: 7px solid #94948d;
  &:hover {
    border-left: 7px solid var(--account-primary-color);
  }
}

.triangle-left {
  border-right: 7px solid #94948d;
  &:hover {
    border-right: 7px solid var(--account-primary-color);
  }
}

.mudal-account {
  background: var(--account-modal-bg);
}

.darkness {
  //background-color: var(--body-background-color);
  color: var(--account-primary-color);

  .form-control-feedback {
    display: none;
  }

  label {
    color: var(--account-tertiary-color);
  }

  a {
    color: var(--tertiary-color);

    &:hover {
      color: var(--account-primary-color);
    }
  }

  p {
    color: var(--account-tertiary-color);

    &.notice {
      margin: 10px 0 40px;
    }
  }

  input {
    border: 1px solid @darkness-grey;
    color: var(--account-primary-color);
    background: var(--account-modal-bg);
  }

  select {
    border: 1px solid #dbdbdb;
    color: var(--account-primary-color);
    background: var(--account-modal-bg);
    display: block;
    padding: 8px;
    width: 100%;
    border-radius: 5px;
  }

  .rizk-btn {
    //max-width: @account-block-width;
    //margin-top: 20px;
    height: auto;
  }

  .btn-inactive {
    background: darken(@brand-white, 50%);
    &:hover {
      background: darken(@brand-white, 50%);
    }
  }
}
.account-inbox-message {
  b,
  strong {
    color: var(--account-primary-color) !important;
    font-weight: bold !important;
  }
}

.checkbox-wrap {
  margin: 10px 0;
  label {
    font-size: 14px;
    letter-spacing: 0;
    color: var(--account-primary-color);
    text-transform: none;
    margin-right: 16px;
    span {
      margin-right: 8px;
    }
  }
}

.sr {
  .kyc-form-container {
    .checkbox-wrap {
      display: none;
    }
  }
}

.deposit-limit-quiz-ans {
  label {
    color: black;
    span {
      margin-right: 8px;
    }
  }
}

.rg-quiz-error {
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 27px;
  background-color: rgba(251, 1, 1, 0.4392156862745098);
  color: rgb(33, 33, 33);
}

.account-content table {
  width: 100%;
  max-width: @account-max-width;
  margin-top: 16px;

  th {
    color: var(--account-primary-color);
    text-transform: uppercase;
    letter-spacing: @letr-spacing-large;
    font-weight: normal;
    font-size: 12px;
  }

  td,
  th {
    padding: 10px 10px 10px 0;
    text-align: left;
    vertical-align: top;
  }

  td {
    color: var(--account-tertiary-color);
    border-bottom: 1px solid #545454;
    font-size: 16px;
  }
  td:last-child,
  th:last-child {
    text-align: right;
    padding-right: 0;
  }

  a {
    color: var(--account-primary-color);
    &:hover {
      color: var(--tertiary-color);
    }
  }
}
.small-block {
  font-size: 10px;
  vertical-align: top;
  display: block;
}

.gaming-history {
  table-layout: fixed;
  th,
  td {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 15%;
    }
  }
}

.betting-history {
  table-layout: fixed;
  th,
  td {
    &:nth-child(1) {
      width: 16%;
    }
    &:nth-child(2) {
      width: 16%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 15%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 15%;
    }
    &:nth-child(7) {
      width: 15%;
    }
    &:nth-child(8) {
      width: 8%;
    }
    &:nth-child(9) {
      width: 20%;
    }
  }
}

.transaction-history {
  table-layout: fixed;
  th,
  td {
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 12%;
    }
    &:nth-child(4) {
      width: 15%;
    }
    &:nth-child(5) {
      width: 18%;
    }
    &:nth-child(6) {
      width: 12%;
    }
    &:nth-child(7) {
      width: 18%;
    }
    &:nth-child(8) {
      width: 18%;
    }
  }

  @media screen and (max-width: 616px) {
    tr th:nth-child(4),
    tr td:nth-child(4) {
      display: none;
    }
  }
  @media screen and (max-width: 919px) {
    tr th:nth-child(2),
    tr td:nth-child(2) {
      display: none;
    }
  }
}

.new-pass-wrap {
  position: relative;
  .help-block {
    display: none;
  }
  #toggle-password {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.level-progress,
.bonus-active {
  .rizk-account-label {
    color: var(--account-primary-color);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: normal;
    font-size: 12px;
  }
  .align-left {
    text-align: left !important;
  }
  .meter {
    .rizk-border-radius;
    height: 10px;
    width: 160px;
    position: relative;
    border: 1px solid @warm-grey;
    // padding: 1px;
    display: inline-block;
  }
  .meter > span {
    .rizk-border-radius;
    display: block;
    height: 100%;
    background-color: var(--tertiary-color);
    position: relative;
    overflow: hidden;
  }
  .meter + span {
    margin-left: 10px;
    .color-white;
  }
  tr th:nth-child(1),
  tr td:nth-child(1) {
    width: 25%;
  }
  @media screen and (max-width: 888px) {
    tr th:nth-child(4),
    tr td:nth-child(4) {
      display: none;
    }
    tr th:nth-child(3),
    tr td:nth-child(3) {
      text-align: right;
    }
  }
  @media screen and (max-width: 666px) {
    tr th:nth-child(3),
    tr td:nth-child(3) {
      display: none;
    }
    tr th:nth-child(2),
    tr td:nth-child(2) {
      text-align: right;
    }
    tr th:nth-child(1),
    tr td:nth-child(1) {
      width: 50%;
    }
  }
  @media screen and (max-width: 555px) {
    .meter {
      width: 95px;
    }
  }
}

.processing {
  color: var(--tertiary-color);
}

.rejected {
  color: @brand-red;
}

.approved {
  color: var(--brand-green);
}

.date-range {
  display: none;
}

.kyc-form-container {
  .rizk-border-radius;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  .kyc-input {
    .rizk-border-radius;
    border: 2px dashed rgba(151, 151, 151, 0.3);
    position: relative;
    padding: 10px 17px;
    min-height: 100px;
    margin-bottom: 10px;

    &:hover {
      border: 2px solid var(--tertiary-color);
    }
    &.hilite {
      border: 1px solid var(--tertiary-color);
      color: var(--tertiary-color);
    }
    &.hilite-ok {
      border: 1px solid var(--brand-green);
      color: var(--brand-green);
    }
    &.hilite-error {
      border: 1px dashed @brand-red;
      color: @brand-red;
    }

    h5 {
      padding: 0;
      margin: 12px 50px 16px 0;
    }
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
    .status {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    .filename {
      color: var(--account-primary-color);
      display: block;
      margin-right: 80px;
      word-wrap: break-word;
    }
  }
  button {
    max-width: none;
    margin-top: 10px;
  }
  label {
    margin-top: 15px;
  }
  label::last-child {
    margin-bottom: 0;
  }
}

.closure-account-form-container {
  button {
    max-width: none;
    margin-top: 10px;
  }
}

.verification-disclaimer {
  font-size: 16px;
}

.secondary-navigation {
  padding: 30px 0 10px 0;
  border-bottom: 2px solid #595959;
  position: relative;
}

@media screen and (max-width: 1084px) {
  #mudal-account-settings {
    width: 100%;
    position: absolute;
  }
  .secondary-navigation {
    width: 100% !important;
    position: relative !important;
    margin: 0 !important;
  }
  .settings-menu {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    padding: 20px;
    li  {
      margin-right: 20px !important;
      width: max-content !important;
      &.active {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 7px;
      }
      a {
        white-space: nowrap;
        text-transform: capitalize;
        font-size: 17px;
      }
    }
  }
}

@media screen and (min-width: @menu-open-screen-width) {
  .settings-menu {
    &.account-menu-disabled {
      & li,
      & li:hover {
        color: #545454;
      }
    }
  }
}

.account-content {
  opacity: 0;
}

.fade-in {
  animation: fadeIn 0.5s ease-in both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-80px);
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in both;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.settings-menu {
  margin: 30px 0 0 0;
  padding-bottom: 2px;

  li  {
    display: block;
    margin: 0;
    color: var(--account-primary-color);
    text-transform: uppercase;
    // letter-spacing: @letr-spacing-large;
    transition: all 0.1s ease-in;
    padding: 0;

    &:hover {
      color: var(--tertiary-color);
      a {
        color: inherit;
      }
    }
    &.active {
      display: block;
      color: var(--tertiary-color);
    }
    a {
      color: inherit;
      white-space: nowrap;
    }
  }

  @media screen and (min-width: @menu-collapse-screen-width) {
    display: flex;
    justify-content: space-between;
    li {
      text-align: center;
      padding: 0 4px;
      display: block;
      &:first-child {
        text-align: left;
        padding-left: 0;
      }
      &:last-child {
        text-align: right;
        padding-right: 0;
      }
    }
  }
}

#account-content,
.secondary-navigation {
  text-align: left;
  max-width: @account-max-width;

  margin: 0 auto;
  padding: 30px 0 10px 0;
  color: var(--account-secondary-color);

  @media screen and (max-width: @content-auto-margin-limit) {
    margin: 0 @account-margin;
  }

  @media screen and (max-width: (@account-margin / 0.08 )) {
    margin: 0 8%;
  }
}

#last-login {
  margin-bottom: 20px;
}

.account-content {
  .age-check-arrow {
    position: absolute;
    width: 13px;
    height: 13px;
    transform: rotate(45deg);
    top: 26px;
    margin-left: 5px;
    z-index: 999999;

    span {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: none;
      border-bottom: 3px solid #ffd64a;
      border-right: 3px solid #ffd64a;
    }

    &--fixed {
      position: fixed;
    }

    &--rotate {
      transform: rotate(-135deg);
      top: 35px;
    }

    .tablet ({
      display: none;
    });;
  }
}
@keyframes animate {
  0% {
    border-color: #fff;
    transform: translate(0, 0);
  }
  20% {
    border-color: #fff;
    transform: translate(5px, 5px);
  }
  20.1%,
  100% {
    border-color: #ffd64a;
  }
}

@keyframes arrowMove {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 3px;
  }
  100% {
    margin-top: 0;
  }
}

.id-check-forms {
  width: 90%;
  margin: auto;
}

.account-wrap {
  position: relative;
  padding-bottom: 40px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    text-transform: uppercase;
  }
  .verification-disclaimer-heading{
    .font-size(24px);
    text-transform: none;
    letter-spacing: inherit;
  }

  &.age-check-kyc {
    text-align: center;
    border-radius: 10px;
    overflow: hidden;

    &--pop-up {
      background: rgba(0, 0, 0, 0.8);
      z-index: 9999;
      position: fixed;
      width: 100%;
      left: 0;
      animation: slideUp 1s ease-in-out forwards;
      top: 0;
      padding-top: 10rem;
      height: 150vh;

      .tablet({
        transform: translate(0);
        animation: none;
        position: inherit;
        padding-top: 0;
      });

      iframe .age-check-kyc__wrapper {
        width: 90%;
      }
    }

    .age-check-kyc__wrapper {
      max-width: 960px;
      width: 100%;
      max-height: 710px;
      margin: auto;
      border-radius: 10px;
      height: 600px;
      .tablet({
        height: 710px;
      })
    }
  }
}

.age-check-kyc-container {
  margin-bottom: 4rem;
}

.sow-account-block {
  border: 1px solid @darkness-grey;
  border-radius: 5px;
  padding: 20px;
}

.sow-account-block {
  .hrBorder {
    border-top: 2px solid @darkness-grey;
  }
  label {
    font-weight: normal;
    width: 76%;
  }

  .sow_item {
    margin-bottom: 12px;
  }

  label.required span,
  label:not([for]) span {
    margin: 0;
    top: inherit;
  }

  label[for='formSourceOfWealth_SowDeclaration'] {
    width: 90%;

    span {
      margin-right: 10px;
      margin-bottom: 2px;
    }
  }
}

.account-block {
  max-width: 420px;
  margin-bottom: 40px;

  .verification-disclaimer-heading {
    .font-size(28px);
  }

  .clearfix;
  & p:last-child {
    margin-bottom: 0;
  }
  & h5 {
    margin-top: 0;
  }
  &.wide {
    max-width: 640px;
  }
  &.max-wide {
    width: 100%;
    max-width: none;
  }
  @media screen and (min-width: ( @side-menu-width + @account-max-width + @account-margin)) {
    width: @account-block-width;

    &.wide {
      width: 100%;
    }

    &.right-col {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .consents-form .validator-icon {
    display: none;
  }

  #active-limits {
    margin-bottom: 50px;
  }
}
#js__upload-history {
  min-height: 364px;
  border: 1px solid #1b1b1b;
  border-radius: 5px;
  padding: 15px;
}
.float-wrap {
  .account-block {
    width: 100%;
    float: left;
    &:first-child {
      margin-right: @account-margin + @account-margin;
    }
    &:last-child {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
}

#dateInfo {
  min-width: 110px;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  &.month-active {
    color: var(--tertiary-color);
  }
}

.thb-controls {
  text-align: center;
  div {
    display: inline-block;
  }
  button {
    margin-top: 0;
    background: none;
    color: var(--account-tertiary-color);
    padding: 4px;
    font-size: 14px;
    span {
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      position: relative;
      top: 2px;
      display: inline-block;
      width: 0;
      height: 0;
    }
    &:hover {
      background: none;
      color: var(--account-primary-color);
    }
    &.active {
      color: var(--account-primary-color);
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 500px) {
    .thb-month {
      float: left;
    }
    .thb-pagelength {
      float: right;
    }
    .thb-print-icon {
      float: right;
      visibility: hidden;
      margin-left: 10px;
    }
    .thb-page {
    }
  }
}

#active-limits {
  div {
    color: var(--account-tertiary-color);
    border-bottom: 1px solid #545454;
    margin-bottom: 16px;
    clear: both;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p,
  h5 {
    display: inline-block;
    margin: 0 0 4px 0;
  }
  > div > p {
    text-align: right;
    float: right;
  }
  h5 {
    text-align: left;
  }
  .active-limits-remove {
    width: 100%;
    text-align: end;
    margin-bottom: 25px;
  }
}

#confirm-limits {
  .main-limit-div {
    padding-bottom: 50px;
    border-bottom: 1px solid #545454;
    margin-bottom: 16px;
  }
  p {
    margin: 0px;
  }

  button {
    margin: 5px;
  }
  .confirm-limits-button {
    display: block;
    position: absolute;
    left: 0;
    text-transform: uppercase;
    margin-left: 0px;
  }
  .cancel-limits-button {
    display: block;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    margin-left: 0px;
  }
  .confirm-limits-error-message {
    color: red;
    display: none;
  }
}

#accountBalance {
  h4 {
    font-size: 16px;
    text-transform: none;
    margin-bottom: 10px;
  }
  p {
    color: var(--account-primary-color);
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #545454;
  }
}

.checkbox-group {
  label {
    display: flex;
    align-items: center;
  }

  input[type='checkbox'] + label span,
  input[type='radio'] + label span {
    position: inherit;
  }
}

.kyc-input .form-group {
  position: initial;
}

.bank-id-input {
  padding-bottom: 20px !important;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  #account-content table {
    thead {
      display: none;
    }
    tr {
      border-bottom: 1px solid @darkness-grey;
    }
  }

  #account-content table.gaming-history {
    &.one-per-row {
      td {
        display: block !important;
        width: 100% !important;
      }
      td:last-child,
      th:last-child {
        text-align: left;
        padding-right: 0;
      }
    }
  }

  #account-content table.betting-history {
    &.one-per-row {
      td {
        display: block !important;
        width: 100% !important;
      }
      td:last-child,
      th:last-child {
        text-align: left;
        padding-right: 0;
      }
    }
  }

  #account-content table.transaction-history {
    tr th:nth-child(2),
    tr td:nth-child(2) {
      display: none;
    }
    &.one-per-row {
      td {
        display: block !important;
        width: 100% !important;
      }
      td:last-child,
      th:last-child {
        text-align: left;
        padding-right: 0;
      }
    }
  }
  .thb-controls {
    div {
      // display:block;
      margin: 15px 0;

      display: flex;
      width: 100%;
      overflow: scroll;
    }
    button {
      padding: 4px 10px;
    }
    .month-container {
      width: 100%;
      display: flex;
      overflow: scroll;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .thb-pagelength,
    .thb-print-icon,
    .thb-page {
      justify-content: center;
      align-items: center;
    }
  }

  .date-range {
    display: table-row;
  }

  .account-content {
    iframe {
      width: 100%;
    }
  }
}

// Let's make the tertiary-menu do something
.tertiary-navigation {
  display: none;
}
@media screen and (max-width: 1125px) {
  .tertiary-navigation {
    &.active {
      display: block;
    }
  }
  .activate-submit {
    #js__upload-history {
      display: none;
    }
  }
  .activate-submitted {
    #js__upload-header,
    #js__upload-boxes {
      display: none;
    }
  }
}

// Verification footer blocks
.verification-footer {
  border-top: 2px solid rgba(151, 151, 151, 0.15);
  padding-top: 20px;
  position: relative;
}
.verification-footer-heading {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.02em;
  margin-top: -30px;
}
.verification-footer-block {
  width: 25%;
  float: left;
  padding-right: 5%;

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding-right: 0;
    float: none;
  }

  &:last-child {
    padding-right: 0;
  }

  .verification-footer-header {
    color: #979797;
    font-weight: 600;
    font-size: 13px;
  }
  .verification-footer-text {
    font-size: 12px;
  }
}
// Extra
.or-separator {
  border-top: 2px solid rgba(151, 151, 151, 0.3);
}
.or-separator p {
  margin-top: -11px;
  padding-bottom: 25px;
  text-align: center;
}
.kyc-option {
  position: absolute;
  top: -14px;
  left: 15px;
  padding: 5px 15px;
  background-color: #3a3a3a;
  border-radius: 4px;
  color: var(--tertiary-color);
  font-size: 12px;
}
@media screen and (min-width: 700px) {
  #modal-bankid {
    min-width: 666px;
  }
}

// Reality check interval popup form
.rcf-wrap {
  max-width: 480px;

  .checkbox-wrap label {
    color: inherit;
  }
}

// ugly hack to hide consents for Croatia
#gig-consents-container {
  display: none;
}

@media screen and (min-width: 1151px) {
  body:not(.horizontal-toggle) {
    .mudal-account {
      position: absolute;
      right: 0;
      left: 158px;
    }
  }
}
