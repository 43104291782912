@import "variables";
.cookie-reset {
    padding: 0px;
    max-width: none;
    .cookie-reset-bg {
        width: 100%;
        border-bottom: 1px solid @pages-heading-color;
    }
    .title {
        text-transform: uppercase;
        position: relative;
        text-align: left;
        >div {
            position: absolute;
            bottom: 0;
            left: 5%;
        }
        h2 {
            color: @brand-white;
            text-align: left;
            text-transform: uppercase;
            margin: 0;
            // font-family: League Gothic, sans-serif;
        }
        h1 {
            margin: 0;
            font-weight: 700;
        }
        .subtext {
            font-size: 10px;
            color: @insider-author-desc-color;
            text-transform: none;
            margin-top: 0;
            text-align: center;
        }
        .sign-up-part {
            width: 50%;
        }
        .rizk-btn {
            color: @brand-white;
            margin-top: 20px;
        }
    }
    .payment-methods {
        margin-left: 50px;
        margin-top: 50px;
        text-align: left;
        img {
            height: 25px;
            margin-right: 15px;
            margin-top: 10px;
        }
    }
    h3 {
        text-align: left;
        margin-left: 20px;
    }
    .reasons {
        background-color: @side-menu-bottom-bar;
        margin-top: 50px;
        padding: 50px 20px 0 50px;
        display: flex;
        >div {
            margin-bottom: 50px;
        }
        p {
            font-size: 14px;
            text-align: left;
            color: @insider-author-desc-color;
            margin: 0;
            span {
                color: @brand-white;
                font-weight: 600;
            }
        }
    }
    .cpt-quotation {
        max-width: 200px;
        margin-left: 150px;
        .author-cpt {
            display: flex;
            margin-top: 20px;
        }
        .yellow-circle {
            display: block;
            width: 30px;
            height: 30px;
            background: var(--tertiary-color);
            border-radius: 20px;
            margin-left: 10px;
        }
        img {
            height: 50px;
            margin-left: -6px;
            margin-top: -7px;
        }
    }
    .checkmark {
        display: inline-block;
    }
    .checkmark:after {
        content: "";
        display: block;
        width: 7px;
        height: 14px;
        border: solid @brand-yellow;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        margin-right: 10px;
    }
    @media screen and (max-width: 1096px) {
        .reasons {
            flex-direction: column;
            .cpt-quotation {
                margin-left: 0px;
            }
        }
        .cookie-reset-bg {
            border-bottom: none;
        }
        .title>div {
            position: static;
            margin-left: 50px;
        }
    }
}
