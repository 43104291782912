.deposit-conceal{
  height: ~"calc(100% - 72px)";

  #deposit-methods-container,
  #deposit-bonus-container,
  #bankLogoFooter,
  #rizk-payment-iframe-container {
    display: none !important;
    visibility: hidden;
  }

  #deposit-container {
    height: 100%;
    #deposit-error-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;

      .deposit-error {
        color: #ff0000;
        border-style: solid;
        border-color: #ff0000a1;
        border-width: 2px;
        background-color: #ffc0cbb5;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        width: 90%;
      }
    }
  }
}


.cardRemovalPopUp {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 1s ease-out;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .cardRemovalPopUp__wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 290px;
    max-width: 400px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 3%;
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 1rem;

    .cardRemovalPopUp__question.hide {
      display: none;
    }

    .cardRemovalPopUp__successContainer.show, .cardRemovalPopUp__failedContainer.show {
      display: contents !important;
    }

    .cardRemovalPopUp__question, .cardRemovalPopUp__successContainer, .cardRemovalPopUp__failedContainer {
      display: contents;
    }

    .cardRemovalPopUp__closeBtn {
      background-image: url(../img/icons/modal-close-button-filled-yellow.svg);
      cursor: pointer;
      height: 36px;
      width: 36px;
      background-color: transparent;
      align-self: flex-end;
      transition: transform 0.1s ease-in-out;

      &:active {
        transform: scale(0.8);
      }
    }

    .cardRemovalPopUp__accountID {
      font-size: 20px;
    }

    .cardRemovalPopUp__heading {
      font-size: 20px; 
      text-align: center;
    }

    .cardRemovalPopUp__continueBtn, .cardRemovalPopUp__exitBtn {
      margin: 10px;
    }

    .cardRemovalPopUp__success {
      -webkit-transform: translate3d(0px, 0px, 0px);
      background: url("../img/icons/success-check.png") no-repeat center;
      display: block;
      margin: auto;
      background-size: 62px;
      width: 35px;
      height: 33px;
    }
    
  }
}

.cta-map-icon {
  width:100%;
  color: var(--primary-color) !important;
  text-decoration: none !important; 
  padding: 15px 20px; 
  max-width: 400px;

  &::before {
    content: "";
    display: inline-block;
    width: 26px;
    height: 26px;
    background-size: contain;
    margin-right: 15px;
    vertical-align: middle;
    background-image: url(/assets/img/icons/map-marker-white-sm.png);
    background-repeat: no-repeat;
  }
}