@responsible-grey: #464646;

.darkness .static-page,
#about-wor
{
  text-align: center;

  h4,
  h2,
  h1{
    color: var(--tertiary-color);
  }

  h1{
    font-size: 38px;
    font-weight: 600;
    text-transform: uppercase;
  }

  #passwordrecoveryForm {
    .new-pass-wrap {
      max-width: 420px;
      margin: 0 auto;
      #toggle-password {
        position: relative;
        right: auto;
        top: auto;
        display: block;
        text-align: right;
      }
      .requirements {
        display: inline-block;
        margin: 0 auto;
        #requirements-list {
          list-style-type: none;
        }
      }
    }
  }

  .responsible-gaming-gb-version{
    text-align: left;
    h1{
      color: var(--primary-color);
    }
    h2{
      margin-bottom: 30px;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 33px;
    }
  }
  h2{
    font-size: 42px;
    font-weight: 100;
    text-transform: none;
    margin: 70px 0 50px 0;
    letter-spacing: @letr-spacing-small;
  }
  h3{
    .header-font(32px);

    margin: 0 0 10px 0;
    text-transform: uppercase;
    color: var(--primary-color);
  }
  h4{
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 400;
    letter-spacing: @letr-spacing-large;
  }
  h5 {
    font-size: 32px;
    font-weight: 200;
    color: var(--primary-color);
  }

  ul{
    list-style-type: circle;
    color: var(--tertiary-color);
    text-align: left;
  }
  li,
  p{
    color: var(--primary-color);
  }
}

.narrower{
  padding: 0 30px; 
}

.borderline{
  margin: 20px 0;
  border: 1px solid @responsible-grey;
  padding: 15px;
}

#exclusion-cta{
  color:var(--secondary-color);
}

.trips{
  display: inline-block;
  vertical-align: top;
  margin: 0 20px;
  @media (max-width: 400px) {
    margin: 0;
  }
  min-width: 220px;
  h4,
  p{
    text-align: left;
    width: 254px;
  }
  a{
    min-height:120px;
    line-height:120px;
    display:block;
    margin: auto 0;
    img{
      margin: 0;
      vertical-align: middle;
    }
  }
}

