.raffle {
  color: var(--primary-color);

  &:not(.raffle--rewards) {
    background-color: var(--secondary-color);
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 90%;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 28px;
    z-index: 1;
    transform: translateX(-50%);
  }

  &__tab {
    background-color: transparent;
    padding: 10px;
    width: 50%;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin-bottom: 0;

    &--active {
      background-color: var(--races-bg-button);
      color: var(--races-color-button);
      font-weight: 600;
    }
  }
  .container {
    background-color: var(--secondary-color);
    margin-top: 0;
    position: relative;
    .raffleWinnersWithOptin {
      display: flex;
      justify-content: center;
      margin-top: 65px;
      align-items: center;
      label.raffleOptinWinnersLink {
        color: var(--primary-color);
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .raffleOptinButton {
      visibility: visible !important;
    }
  }

  .raffleBanner {
    background: var(--gradient-raffle-bg);
    background-repeat: no-repeat;
    background-position: 10% 110%;
    width: 100%;
    padding-bottom: 210px;
    padding-top: 20px;
    position: relative;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      background-image: var(--zig-zag-mobile-left);
      width: 10px;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    &::before {
      left: -6px;
    }

    &::after {
      left: 99%;
      background-image: var(--zig-zag-mobile-right);
    }

    &__captainRizk {
      position: absolute;
      bottom: 0px;
      left: 40px;
      background-image: var(--raffle-bg);
      width: 135px;
      height: 180px;
      background-repeat: no-repeat;

      &--valentines {
        background-image: url(/assets/img/raffles/captainRizkValentines.png);
        background-size: cover;
        width: 177px;
        left: 4%;
      }

      &--easter {
        background-image: url(/assets/img/raffles/captainRizkEaster.png);
        background-size: contain;
        width: 134px;
        left: 14%;
      }

      &--moreTickets {
        background-image: url(/assets/img/raffles/captainRizkMoreTickets.png);
        background-size: contain;
        left: 9px;
        width: 230px;
      }
    }

    &__endTime {
      text-align: center;
      color: #fff;
      font-size: 16px;
      margin-top: 5px;
    }

    &__timer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    &__timerbox {
      min-width: 80px;
      &--int {
        .header-font(60px);

        background-color: var(--raffle-timer-int-bg);
        padding: 0 10px;
        border-radius: 5px 5px 0 0;
        text-align: center;
        color: #fff;
      }

      &--text {
        background-color: var(--raffle-timer-text-bg);
        padding: 2px 5px;
        border-radius: 0 0 5px 5px;
        text-align: center;
        color: #fff;
      }

      &--spacer {
        font-size: 60px;
        color: #fff;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      position: absolute;
      bottom: 50px;
      right: 14%;
      width: 160px;

      &--moreTickets {
        right: 6%;
      }
    }

    &__info-wrapper {
      padding-top: 50px;
    }

    &__info--text {
      background: #fff1e3;
      color: black;
      padding: 0 10px;
      position: relative;
      text-align: center;
      min-height: 90px;
      display: flex;
      align-items: center;

      &::after,
      &::before {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        top: 0;
        background-image: url(/assets/img/raffles/zigzag.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      &::before {
        left: -3px;
        top: 0;
        transform: rotate(-180deg);
      }

      &::after {
        content: '';
        right: -3px;
        top: 0;
      }
    }

    &__info--tickets {
      background: #3b3b3b;
      color: #fff1e3;
      padding: 0px 10px;
      text-align: center;
      position: relative;
      text-transform: uppercase;
      min-height: 90px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100px;

      &::after,
      &::before {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100%;
      }

      &::before {
        left: -11px;
        top: 0;
        width: 15px;
        background-image: url(/assets/img/raffles/leftZigZag.png);
        background-size: 100% 100%;
      }

      &::after {
        content: '';
        background-image: url(/assets/img/raffles/zigZagRightBlack.png);
        right: -3px;
        top: 0;
        background-size: 100% 100%;
      }
    }

    &__container {
      position: relative;
    }

    &.raffleBanner--monthly {
      .raffleBanner__info--tickets {
        background: var(--raffle-monthly-tickets);

        &::before {
          background-image: var(--zig-zag-monthly-ticket-left);
        }

        &::after {
          background-image: var(--zig-zag-monthly-ticket-right);
          background-size: 100% 100%;
        }
      }
    }

    .output {
      display: none;
    }

    &__text {
      width: 90%;
      margin: auto;
      text-align: center;
      padding-top: 20px;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 37px;
      color: #fff;
    }

    &__btn {
      width: 70%;
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
      color: var(--secondary-color);
      z-index: 1;
      cursor: pointer;
      font-size: 18px;
      padding: 15px;
      background-color: var(--primary-button-bg);
    }

    &__rLogo {
      display: none;
    }

    &--monthly {
      display: none;
      background: #3b3b3b;

      &::after,
      &::before {
        background-image: url(/assets/img/raffles/zigzagBlack.png);
      }

      &::before {
        transform: rotate(180deg);
      }

      .raffleBanner__timerbox {
        &--int {
          background-color: #4f4f4f;
        }

        &--text {
          background-color: #737171;
        }
      }
    }

    #raffleBanner__text__no_raffle__daily,
    #raffleBanner__text__no_raffle__monthly {
      padding-top: 150px;
      width: 90%;
      margin: auto;
      text-align: center;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 37px;
      color: #fff;
    }
  }

  &__rules {
    padding: 20px 0;
  }

  #monthly_seconds_spacer,
  #monthly_seconds_div,
  #daily_seconds_div,
  #daily_seconds_spacer,
  #rewards--monthly_seconds_spacer,
  #rewards--monthly_seconds_div,
  #rewards--daily_seconds_spacer,
  #rewards--daily_seconds_div {
    display: none;
  }

  .dailyRaffle:checked {
    & ~ .raffleBanner--daily {
      display: block;
    }

    & ~ .raffleBanner--monthly {
      display: none;
    }

    & ~ .raffle__tabs {
      .raffle__tab--daily {
        background-color: var(--primary-button-bg);
        color: var(--secondary-color);
      }
    }
  }

  .monthlyRaffle:checked {
    & ~ .raffleBanner--daily {
      display: none;
    }

    & ~ .raffleBanner--monthly {
      display: block;
    }

    & ~ .dailyRaffle:checked {
      & ~ .raffleBanner--daily {
        display: block;
      }
    }

    & ~ .raffle__tabs {
      .raffle__tab--monthly {
        background-color: var(--races-bg-button);
        color: var(--races-color-button);
      }
    }
  }

  .raffleLeaderboard {
    margin-top: 60px;
    width: 100%;
    background-color: var(--tertiary-bg-color);
    padding: 20px;

    &__headers {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #787878;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &__rows {
      display: flex;
      flex-direction: column;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--primary-color);
      padding: 10px 15px;
      font-size: 14px;
      margin-bottom: 4px;
      background-color: var(--raffle-row-bg);
      border-radius: 5px;
    }

    &__title {
      .header-font(24px);

      margin: 0 0 10px;
      color: var(--primary-color);
    }
  }

  .winnerLeaderboardCheckbox:checked {
    & ~ .raffleWinnerModal {
      display: flex;
    }
  }

  .raffleWinnerModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(90vh - 70px);
    display: none;
    //padding: 20px;

    .overlay {
      background-color: var(--overlay-background);
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }

    &__container {
      //padding: 30px 20px;
      width: 90%;
      background-color: var(--landing-cards-bg);
      max-height: 60vh;
      min-height: 100%;
      overflow: scroll;
      margin-top: 114px;

      &-Info {
        padding: 0px 20px;
      }

      .raffle__tabs {
        position: inherit;
        width: 100%;
        margin: auto;
        padding: 0;
        font-weight: 900;
        border: 2px solid var(--raffle-top-border);
        transform: translateX(0);
      }

      .raffleLeaderboard {
        margin-top: 10px;
        height: 70%;
        padding: 14px 20px;
        border-radius: 5px;

        &.raffleLeaderboard--list {
          padding: 0;

          .raffleLeaderboard__label {
            padding: 14px 20px;
            background-color: var(--raffles-row-main-background);
          }
        }

        &:nth-child(even) {
          background-color: var(--raffles-row-second-background);
          color: var(--filter-bg-btn);

          h3,
          label {
            color: var(--filter-bg-btn);
          }

          .raffleLeaderboard__label {
            &::after {
              background-image: var(--raffle-expand-plus);
              width: 19px;
              cursor: pointer;
            }
          }

          .raffleLeaderboardCheckbox:checked {
            & ~ .raffleLeaderboard__label::after {
              background-image: var(--raffle-collapse-union);
              cursor: pointer;
            }
          }
        }

        &__headers {
          & > div {
            flex: 1;
            text-align: center; /* Adjust alignment as needed */
          }

          .raffleLeaderboard__prizes--no {
            max-width: 25px;
          }
        }

        &__title {
          font-size: 16px;
          margin: 0;
          font-family: 'Figtree', sans-serif;
        }

        &__showMore {
          width: 100%;
          text-align: center;
          padding: 10px 0 0px;
          cursor: pointer;

          &::after {
            content: attr(data-show);
          }
        }

        &__rows {
          max-height: 455px;
          display: none;
          overflow: scroll;
          margin-top: 20px;
        }

        &__row {
          display: none;

          &:nth-child(-n + 10) {
            display: flex; /* Show the first 10 elements */
          }

          & > div {
            flex: 1;
            text-align: center;
          }

          &--number {
            max-width: 25px;
            text-align: left;
            display: flex;
          }

          &--name {
            text-transform: uppercase;
          }

          &.userHighlighted {
            display: flex;
            position: sticky;
            bottom: -10px;
            background: var(--user-highlighted-bg);
          }
        }

        .raffleLeaderboardRowsCheckbox:checked {
          & ~ .raffleLeaderboard__row {
            display: flex;
          }

          & ~ .raffleLeaderboard__showMore {
            &::after {
              content: attr(data-less);
            }
          }
        }

        &__label {
          display: flex;
          justify-content: space-between;
          margin: 0;

          &::after {
            content: '';
            width: 14px;
            height: 15px;
            background-image: url(/assets/img/raffles/plus.svg);
            background-repeat: no-repeat;
            cursor: pointer;
          }
        }

        &.userInList {
          .raffleLeaderboard__label {
            .raffleLeaderboard__title {
              padding-right: 30px;
              background-image: url(/assets/img/raffles/medals.svg);
              background-repeat: no-repeat;
              background-position: 100% center;
              background-size: 20px;
            }
          }
        }

        .raffleLeaderboardCheckbox:checked {
          & ~ .raffleLeaderboard__rows {
            display: flex;
            padding: 0 20px 14px 20px;
          }

          & ~ .raffleLeaderboard__label::after {
            content: '';
            width: 14px;
            height: 3px;
            background-image: url('/assets/img/raffles/Union.svg');
            cursor: pointer;
          }
        }
      }
    }

    &__close {
      background: var(--gradient-raffle-bg);
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 20px;
      padding: 15px 30px;
      color: #fff;
      cursor: pointer;

      &--captainRizk {
        position: relative;
      }

      &--captainRizkImg {
        background-image: var(--raffle-bg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 30px;
        height: 41px;
        position: absolute;
        bottom: -23px;
        left: -16px;
      }
    }

    .raffleLeaderboardMonthly,
    .raffleLeaderboardDaily {
      display: none;
    }
  }

  #dailyWinners:checked {
    & ~ .raffleLeaderboardDaily {
      display: flex;
      flex-direction: column;
      overflow: scroll;
    }
  }

  #monthlyWinners:checked {
    & ~ .raffleLeaderboardMonthly {
      display: flex;
      flex-direction: column;
      overflow: scroll;
    }
  }

  #seo-content-box {
    margin: 0;
  }
}

.loggedIn {
  &.raffleBanner {
    padding-bottom: 180px;

    .mobile
      ({
            .raffleBanner{
                &__info {
                    left: 50%;
                    transform: translateX(-50%);
                    width: 80%;
                    bottom: 62px;
                }
                &__captainRizk {
                    display: none;
                }
            }
        });;
  }
}

.raffle--rewards {
  top: -10px;
  padding-top: 25px;
  .raffleBanner--reward {
    background: var(--gradient-raffle-bg-rewards);
    padding-bottom: 50px;
  }
  .raffleBanner__info-wrapper {
    margin: auto;

    .output {
      display: none;
    }

    .raffleBanner__info {
      width: 80%;
      margin: 30px auto 0;
      position: inherit;
    }
  }

  .raffleLeaderboard {
    background-color: var(--raffle-leaderboard-rewards);
    margin-top: 0;
    &__title {
      text-align: left;
    }
    &__headers {
      color: #ececec;
    }
  }
}

.tablet ({
    .raffle {   
        .container {
            padding: 20px 0;
        }

        &__tabs {
            width: 320px;
            z-index: 1;
            gap: 10px;
            left: 155px;
            top: 40px;
            transform: translateX(0);
        }

        &__tab {
            background-color: transparent;

            &--active {
                background-color: #FFE500;
            }
        }

        .raffleBanner {
            padding: 62px 0 0 115px;

            #raffleBanner__text__no_raffle__daily, #raffleBanner__text__no_raffle__monthly {
              padding-top: 150px;
              padding-bottom: 120px;
            }

            &::after, &::before{
                display: none;
            }

            &__captainRizk {
                position: absolute;
                bottom: -10px;
                width: 100%;
                height: 180px;
                background-repeat: no-repeat;
                background-position: bottom;
                background-image: var(--raffle-bg--desktop);

                &--valentines {
                    background-image: url(/assets/img/raffles/captainRizkValentines.png);
                    background-size: cover;
                    width: 438px;
                    left: 60%;
                    bottom: 0;
                }

                &--easter {
                    background-image: url(/assets/img/raffles/captainRizkEaster.png);
                    background-size: contain;
                    width: 438px;
                    left: 60%;
                    bottom: 0;
                }

                &--moreTickets {
                    background-image: url(/assets/img/raffles/captainRizkMoreTickets.png);
                    background-size: contain;
                    width: 134px;
                    width: 497px;
                    height: 475px;
                    left: inherit;
                    right: 0;
                }
            }

            &__info-wrapper {
                width: 400px;
                display: block;
                padding-top: 50px;
            }

            &__info {
                position: relative;
                left: 0;
                bottom: 0;
                width: 290px;
                margin: 30px auto 5px;
            }

            &__btn {
                position: relative;
                bottom: -20px;
                width: 80%;
                visibility: hidden;
            }

            &__captainRizk {
                width: 330px;
                left: 66%;
                position: absolute;
                height: 99%;

                &--flavia {
                    left: inherit;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    width: 540px;
                    background-size: cover;
                }

                &--valentines {
                    background-size: cover;
                    width: 438px;
                    left: 60%;
                    bottom: 0;
                }

                &--easter {
                    background-size: contain;
                    left: 62%;
                    bottom: 0;
                    width: 360px;
                }

                &--moreTickets {
                    background-size: contain;
                    width: 557px;
                    height: 475px;
                    left: 49%;
                    bottom: 0;
                }
            }

            &__rLogo {
                display: block;
                background-image: url('/assets/img/raffles/rLogo.png');
                width: 405px;
                position: absolute;
                bottom: 20px;
                left: 60%;
                height: 80%;
                background-repeat: no-repeat; 

                &--valentines {
                    left: 58%;
                }

                &--moreTickets {
                    left: 54%;
                }
            }

            &--monthly {
                .raffleBanner__captainRizk {
                    &--flavia {
                        background-image: var(--raffle-bg-monthly-desktop);
                    }
                }
            }
        }

        .raffleWinnerModal {
            height: 100%;

            &__container {
                width: 700px;
                //padding: 20px 80px;
                margin: auto;

                .raffleLeaderboard__rows {
                    max-height: 435px;
                }
            }

            .raffle__tabs {
                width: 60%;
                margin: 10px auto;
            }
        }
        &.raffle--rewards .raffleBanner__info {
            width: 73%;
        }
    }
});;

.no-scroll {
  overflow: hidden !important;
}
