@button-yellow-gradient-start: #fffea9;
@button-yellow-gradient-stop: #fff400;

#mudal-rewards {
  overflow: hidden;
  max-width: 700px;
  width: 90%;
  min-width: 300px;
  transition: all 0.3s ease-in-out;
  background: var(--myrewards-gradient);

  > div {
    padding: 0;
  }

  header {
    background: var(--rewards-header-bg-color);

    h1 {
      color: var(--secondary-color);
    }
  }

  .claim {
    //display:block;
    //margin-left:auto;
    //margin-right:auto;
    margin-right: 20px;
  }

  .back {
    .btn-white;
    color: var(--myrewards-color);
    //background:transparent;
    //border: 1px solid black;
    &.betsson{
      background: var(--secondary-bg-button);
    }
  }
  

  h5 {
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-align: left;
    text-transform: uppercase;
  }

  .wagerProgress {
    background-color: #f0f0f0;
    padding: 13px 20px;
    border-radius: 5px;
    font-family: 'Figtree', sans-serif;
    position: relative;
    margin: 30px 10px 18px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);

    .tablet ({
      margin: 30px 40px 18px;
    });;

    &__title {
      .header-font(20px);

      text-transform: uppercase;
      color: #209d44;
    }

    &__subTitle {
      font-weight: 800;
      color: #000;
      font-size: 18px;
    }

    &__text {
      font-size: 16px;
      margin: 0;

      &--wager {
        margin-bottom: 10px;
      }

      &--amountLeft {
        font-weight: bold;
      }
    }

    &__cta {
      display: block;
      padding: 10px 30px;
      border-radius: 40px;
      background: #209d44;
      margin: auto;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: none;
      width: max-content;
    }

    &__timeLeft {
      background-color: #209d44;
      position: absolute;
      right: 0;
      padding: 8px 5px 8px 26px;
      border-radius: 5px 0 0 5px;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      background-image: url('../img/icon-clock-white.svg');
      background-repeat: no-repeat;
      background-position: 10% center;
      background-size: 18px 18px;
      top: 5px;
    }

    &__amount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &__fullAmount {
      margin: 0;
      font-weight: 800;
    }

    .progress-bar {
      width: 100%;
      background-color: #d9d9d9;
      border-radius: 5px;
      height: 12px;
      margin-bottom: 10px;
    }

    .progress {
      height: 12px;
      background-color: #4caf50;
      border-radius: 5px;
      width: 0%;
      transition: width 0.5s ease-in-out;
    }
  }
}

#section-item-container {
  //transition: all 0.5s ease-in-out;
  display: none;
  position: absolute;
  left: 0;
  top: 90px;

  .inventory-grid-element {
    cursor: default;
    z-index: 50;
  }
}

#section-item-item {
  position: absolute;
  z-index: 2;

  .inventory-expire,
  .inventory-label {
    position: absolute;
    padding: 2px 2px 4px 12px;
  }
}

.ray-rotator {
  opacity: 0;
  transition: opacity 0.5s linear;
  position: absolute;
  top: 80px;
  left: 80px;
  animation: spinning 70s linear 0s infinite;
  transform: rotate(0);
}

.rays {
  background-image: url('../img/rays3.png');
  animation: scale 6s ease 0s infinite;
  transform: rotate(0);
  background-size: contain;
}

.hover-rays {
  // .rays;
  width: 272px;
  height: 272px;
  position: absolute;
  top: -136px;
  left: -136px;
  z-index: 1;
}

@keyframes hover-fx-frames {
  from {
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    transform: translate3d(100%, 100%, 0);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  }

  20% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }

  48% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }

  75% {
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
  }

  100% {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  }
}

.reward-overlay-container {
  display: none;
  transition: all 0.5s ease-in-out;
  text-align: center;
  position: absolute;
  top: 0;
  margin-top: 0;
  padding-top: 72px !important;
  color: white;

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h1 {
    .header-font(32px);
    
    color: @brand-yellow;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  h2 {
    .header-font(24px);

    color: white;
    text-transform: uppercase;
  }

  button {
    width: auto;
    margin-top: 20px;
    .rizk-btn;
    .btn-yellow;
    font-weight: 600;
    font-size: 18px;
    min-width: 120px;
  }

  .rewards-upper {
    min-height: 170px;
    padding-left: 200px;
    padding-right: 20px;
    padding-top: 20px;
    text-align: left;

    a {
      color: var(--primary-color);
      text-decoration: underline;
    }

    h1 {
      color: var(--single-reward-view-heading-color);
    }

    p {
      color: var(--primary-color);
    }
  }
}

.progress-multiplier-time {
  height: 64px;
  border-radius: 10px;
  border: solid 1px var(--myrewards-border);
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#claim-bonuscode {
  border-radius: var(--button-border-radius);
}

#progress-time-text {
  .font-size(36px);
  color: var(--primary-color);
  padding: 5px 55px;
}

.reward-overlay-container ul {
  padding: 0;

  &.wagering-bonus-games {
    display: flex;
    overflow: scroll;
    width: calc(100% - 10px);
  }

  li {
    transition: all 0.5s ease-in-out;
    padding: 0;
    display: inline-block;
    padding: 12px;
    //width: 144px;
  }
}

.raffle-content {
  margin: 40px;
  overflow: hidden;
  .rizk-border-radius;
  .bggradient(#e2cb85, #c6b275);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);

  .raffle-left {
    width: 50%;
    text-align: left;
    padding: 15px 15px 13px;
  }

  h2 {
    font-size: 18px;
    color: #85774d;
    margin-top: 0;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
    color: #000;
  }

  p {
    color: #85774d;
  }

  .raffle-img-cont {
    float: right;
    width: 50%;
  }

  .raffle-img {
    //float:right;
    width: 100%;
  }
}

.raffle-clock {
  display: inline-block;
  background: url('../img/icon-clock-brown.svg') no-repeat;
  width: 19px;
  height: 18px;
  position: relative;
  top: 4px;
  margin-right: 5px;
}

.no-raffles {
  display: none;
}

.deposit-bonus-content {
  margin: 30px 40px 18px;
  overflow: hidden;
  min-height: 89px;
  background-color: var(--myrewards-dep-bg);
  //height: 89px;
  .rizk-border-radius;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);

  &.betsson{
    background-color: var(--secondary-body-background-color);
  }


  .deposit-bonus-title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-top: 14px;
    margin-left: 14px;
    text-align: left;
    color: var(--brand-green);

    &.betsson{
      color: var(--secondary-bg-color);

    }
  }

  .deposit-bonus-message {
    float: left;
    width: 71%;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: 1px 14px;
    color: @brand-black;
    padding-bottom: 5px;

    p {
      margin: 0;

      &.small {
        font-weight: normal;
        font-size: 12px;
        margin-top: 10px;

        a {
          display: block;
        }
      }
    }
  }

  .deposit-cta {
    margin-right: 14px;
    float: right;

    .depo-cta-btn {
      color: white;
      text-decoration: none;
    }
  }

  @media (max-width: 950px) {
    height: auto;

    .deposit-bonus-message {
      width: auto;
      float: none;
    }

    .deposit-cta {
      float: none;
      clear: both;
      position: inherit;
      margin: 14px 0 14px 0;
    }
  }
}

@media (max-width: 950px) {
  .betsson-deposit-bonus-content{
    margin: 30px 20px 18px;
  }
  
}

#first-deposit-collection-container #first-deposit-collection-nobonus {
  background: none;
  text-decoration: underline;
  font-size: 14px;
  padding: 0px;
  margin: 10px 0px 0px 0px;

  &:hover {
    background: none;
    cursor: pointer;
  }
}

#claim-money-scroller {
  color: var(--primary-color);
  font-size: 36px;
  border: 1px solid var(--myrewards-border);
  padding: 15px;
  display: inline-block;
  min-width: 250px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #9b9b9b;
}

#inventory-grid {
  text-align: center;
  margin-bottom: 40px;

  .inventory-expire {
    float: left;
    background: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 2px 2px 4px 12px;
  }
}

.inventory-element-container {
  display: inline-block;
  //-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

#rewards-deposit-bonus-container {
  .green-expire {
    background: var(--myrewards-dep-bg) !important;
    color: #9b9b9b;

    .white-icon {
      display: none;
    }

    .gray-icon {
      display: inline;
    }
  }
}

.inventory-expire {
  background: white;
  width: 100%;
  text-align: left;
  color: #9b9b9b;
  z-index: 1;
  bottom: 0;

  p {
    font-size: 10px;
    margin: 0;
    padding-left: 5px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.085em;
  }
}

.green-expire {
  background: var(--brand-green) !important;
  color: white;

  .gray-icon {
    display: none;
  }
}

.inventory-label {
  background: white;
  width: 100%;
  text-align: center;
  color: #9b9b9b;
  z-index: 1;
  float: left;
  position: absolute;
  padding: 2px 5px 4px;
  bottom: 0;

  p {
    font-size: 10px;
    margin: 0;
    padding-left: 5px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.085em;
  }
}

.inventory-grid-element {
  vertical-align: top;
  position: relative;
  overflow: hidden;
  .rizk-border-radius;
  display: inline-block;
  width: 136px;
  height: 136px;
  margin: 12px;
  cursor: pointer;
  transform: translate3d(0, 0, 0) scale(1, 1);
  backface-visibility: hidden;
  transition: opacity 0.5s ease-in-out;
  transition: transform 0.25s ease;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  background: var(--myrewards-tile);
  background-size: contain;

  &.empty {
    background: var(--myrewards-gradient);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: default;
  }

  &.type-999 {
    .background-gradient(#ffffff, #949494);
  }

  .hover-fx {
    position: absolute;
    z-index: 10;
    width: 200px;
    height: 200px;
    display: none;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&amp;0+0,0.5+40,1+50,0.5+60,0+100 */
    background: -moz-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 40%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 40%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 40%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    transform: translate3d(-100%, -100%, 0);
    animation: hover-fx-frames 2s linear 0.5s infinite;
  }

  > img {
    position: absolute;
    //z-index:1;
    left: 64px;
    top: 40px;
    width: 100px;
    user-select: none;
    user-drag: none;
    //transition:all 0.25s ease;
    //transform: translate3d(0,0,0);
  }

  &.raffleticket > img {
    transform: rotate(-15deg);
  }

  h5 {
    color: @brand-red;
    margin: 12px 12px 0px;
  }

  /*&:hover {
    &.raffleticket >img {
      transform: scale(1.1) rotate(-15deg); 
    }
  }

  &:hover >img {
    transform: scale(1.1); 
  }*/

  &.single-reward{
    h5{
      color: var(--single-reward-heading-color);
      }
  }
  &.type-999{
    >img{
      width: 100px;
    }
  }
}
.full-icon>img {
  left: 0px;
  top: 0px;
  width: 100%;
}

.claim-games img {
  transition: transform 0.25s ease;
  .rizk-border-radius;
  backface-visibility: hidden;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);

  cursor: pointer;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.wagering-bonus-games img {
  transition: transform 0.25s ease;
  .rizk-border-radius;
  backface-visibility: hidden;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.overlay-text {
  position: absolute;
  font-size: 18px;
  font-weight: 800;
  padding: 0px 12px;
  z-index: 2;
  color: black;

  .smallspan {
    font-size: 15px;
  }
}

#claim-fs-nodesktop,
#claim-fs-nomobile {
  display: none;
}

.rewards-mobilefs {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 70px;
  left: 12px;
  background: url('../img/icon-mobilefs.svg') no-repeat;
}

#leaderboard-table {
  width: 80%;
  margin: 0 auto;
}

#bonuscode-container {
  position: relative;
  margin: 40px;
  padding: 15px;
  .rizk-border-radius;
  border: 1px dashed;
  text-align: left;

  h3,
  h5,
  p {
    margin: 0;
    color: var(--primary-color);
  }

  .close-btn {
    .rizk-btn {
      border-radius: var(--button-border-radius);
    }
  }

  p {
    color: @red-brown;
    margin: 5px 0;
  }

  h5 {
    line-height: 20px;

    span {
      color: var(--tertiary-bg-button);
      cursor: pointer;
      user-select: none;
      background: var(--primary-color);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      font-size: 16px;
      letter-spacing: 0;
      margin-left: 10px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;

      &.betsson{
        background: var(--primary-bg-color);
        color: var(--secondary-color);
      }
    }
  }

  
  

  input {
    width: 60%;
    margin-right: 15px;
  }

  button {
    display: inline-block;
    width: auto;
    .yellow-btn;
  }

  #bc-desc-hidden {
    cursor: pointer;
    color: var(--myrewards-color);
    text-decoration: underline;
    &.betsson{
      color: var(--tertiary-color);
    }
  }

  


  #bonus-code-prompt {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    max-width: 100%;
    padding: 15px;
    background: @brand-white;
    border-radius: 10px;
    box-shadow: 2px 0px 16px 0px #00000030;
    transition: opacity 0.3s ease-in-out;

    &:before {
      content: '';
      height: 12px;
      width: 12px;
      transform: rotate(45deg);
      margin: 0 10px 0 0;
      background: white;
      position: absolute;
      bottom: -6px;
      left: 117px;
    }

    h5 {
      color: var(--brand-green);
    }

    p {
      color: @brand-black;
    }

    .close-btn {
      display: flex;
      justify-content: flex-end;

      button {
        padding: 8px 16px;
        margin: 10px 0px 0px 0px;
      }
    }
  }
}

#bc-inner {
  display: none;
}

.type-16 > img {
  left: 50px;
  top: 30px;
}

.type-27 > img {
  left: 50px;
  top: 30px;
}

.type-26 > img {
  left: 0;
  top: 0;
  width: 100%;
}

.type-17 > img {
  transform: translateX(-12px) rotate(5deg);
}

#welcome-wheel-container {
  img {
    margin: 0px 16px;
    cursor: pointer;
  }

  button {
    display: block;
    margin: 32px auto;
  }

  .wheel-sel-cont {
    display: inline-block;
    text-align: center;
    margin: 0 16px 16px 16px;

    p {
      font-size: 24px;
      margin: 0;
    }
  }
}

@media (max-width: 950px) {
  .raffle-content {
    .raffle-img-cont {
      float: none;
      width: 100%;
      //height:120px;
      overflow: hidden;
    }

    .raffle-left {
      width: 100%;
    }
  }
}

@media (max-width: 660px) {
  #section-item-container {
    opacity: 0.2;
  }

  .reward-overlay-container {
    .rewards-upper {
      min-height: 180px;
      padding-left: 20px;
      text-align: center;
    }
  }
}
