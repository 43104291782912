@game-width-desktop: 186px;
@game-height-desktop: 188px;
@game-width-mobile: 120px;
@game-height-mobile: 120px;
@row-height-mobile: 130px;
@race-width-desktop: 910px;
@race-height-desktop: 160px;

.newLobbyToggle {
  .show-all-category-games {
    font-size: 12px;
    text-transform: uppercase;
    border: none;
    padding: 0;
    background: none;
    color: var(--primary-color);
    letter-spacing: 1px;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }

    img {
      height: 12px;
    }
  }

  .game-url {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
  }

  .search-results {
    width: 100%;
    overflow: hidden;
  }

  .games-a-z,
  .games-providers,
  .game-jackpots,
  .game-rizkjackpot,
  .games-live-casino,
  .game-races,
  .games-favourite,
  .game-tablegames {
    display: none;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition:
      transform 0.5s ease-in-out,
      -webkit-transform 0.5s ease-in-out;
  }

  .game-categories {
    position: relative;
    padding-top: 5px;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition:
      transform 0.5s ease-in-out,
      -webkit-transform 0.5s ease-in-out;
  }

  #canvas {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
  }

  .category-container {
    position: relative;
    padding-bottom: 20px;
    div.game-name {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 1);
      padding: 2px;
      padding-top: 4px;
      font-size: 16px;
      border: none;
      text-align: center;
    }
    div.latest-stats {
      position: absolute;
      bottom: 60px;
      left: 20px;
      right: 20px;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 2px;
      font-size: 12px;
      border: none;
      text-align: center;
    }
    div.min-max-amount {
      position: absolute;
      bottom: 30px;
      left: 20px;
      right: 20px;
      color: #ffe500;
      background-color: #212121;
      padding: 2px;
      font-size: 14px;
      border: none;
      text-align: center;
      border-radius: var(--button-border-radius);
    }
    div.min-max-amount-mobile {
      position: absolute;
      bottom: 30px;
      left: 20px;
      right: 20px;
      color: #ffe500;
      background-color: #212121;
      padding: 2px;
      font-size: 11px;
      border: none;
      text-align: center;
      border-radius: 15px;
    }
  }

  .categoryTitle {
    .header-font(24px);

    text-transform: uppercase;
    color: var(--primary-color);
    padding-left: @spacing;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    &.title-selected {
      color: var(--tertiary-color);
    }
  }

  .category,
  .category-desktop {
    -webkit-overflow-scrolling: touch;
    position: relative;
  }

  .category-row-parent {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .race-opted-in-ml {
      margin-left: 15px;
    }
    .race-opted-in-pl {
      padding-left: 15px;
    }
    .race-widget {
      margin-right: 10px;
      .race-widget-container {
        min-width: 900px;
      }
      .rizk-btn {
        min-width: 100%;
      }
      .race-col-race-img {
        width: 61px;
        height: 61px;
      }
    }
    .race-individual {
      margin: 0 20px 20px 0;
      height: unset;
    }
    .race-col {
      text-align: start;
    }
    .race-collapse {
      display: none;
    }
  }

  .category-desktop {
    overflow-x: hidden;
  }

  .categoryRow {
    text-align: left;
    height: @row-height-mobile;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-padding-start: 0;
    white-space: nowrap;
    font-size: 0px;
    &.collapse {
      -webkit-transition: none;
      transition: none;
      li {
        margin: 0 5px 10px 5px;
        float: left;
      }
    }
  }

  .jackpot-banner {
    height: 100%;
    background: url(../img/jackpot/jackpot-banner-bg-desktop.jpg) no-repeat left center;
    border-radius: 3px;
    background-size: cover;

    .scrollable-row {
      position: relative;
      overflow: hidden;
    }
  }

  .category-row-races-template-desktop {
    .category-row-parent {
      margin-left: 27px;
      overflow-x: scroll;
    }
  }

  .category-desktop[data-tag='rizkjackpot'] {
    background: url(/assets/img/jackpot/jackpot-banner-bg-desktop.jpg) no-repeat left center;
    border-radius: 3px;

    .scrollable-row {
      position: relative;
      overflow: hidden;
    }
  }

  .category[data-tag='rizkjackpot'] {
    display: none;
  }

  .category-row-jackpot-template-desktop {
    &.mobile-jp {
      margin: 30px 0px 10px 0px;
      .categoryTitle {
        display: none;
      }
      .jackpot-banner {
        justify-content: center;
        background: url(/assets/img/jackpot/jackpot-banner-bg-mobile.jpg) no-repeat center center;
        background-size: cover;
        padding: 24px;
      }

      .jackpot-tile {
        width: 100% !important;
        height: 215px;
        margin-left: 0px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .jackpot-tile-image {
        max-width: 190px !important;
      }

      .scrollable-row {
        display: none;
      }
    }
    .jackpot-banner {
      display: flex;
      padding: 21px 0 17px 0;

      .jackpot-tile {
        flex-shrink: 0;
        margin-left: 27px;
        width: 280px;

        .jackpot-tile__content-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;

          .jackpot-tile__jackpot-amount {
            .header-font(38px);

            color: #ffffff;
            margin: auto;
            width: 100%;
            text-align: center;
          }
          .jackpot-tile__jackpot-btn {
            padding: 14px 0px;
            width: 80%;
            font-size: 15px;
            line-height: 15px;
            font-weight: 600;
            text-transform: initial;
          }

          .jackpot-tile-image {
            display: block;
            max-width: 170px;
          }
        }
      }
    }
  }

  .jackpot-tile-mobile {
    height: 250px;
    width: 100%;
    margin: 30px 0px;
    padding: 0 8px;
    .jackpot-tile__content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 24px;
      border-radius: 3px;
      background: url(../img/jackpot/jackpot-banner-bg-mobile.jpg) no-repeat center center;
      background-size: cover;

      .jackpot-tile__jackpot-amount {
        .header-font(38px);

        text-align: center;
        color: @brand-white;
        margin: auto;
        width: 164px;
      }

      .jackpot-tile__jackpot-btn {
        padding: 16px 0px;
        width: 80%;
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
        text-transform: initial;
      }

      .jackpot-tile-image {
        display: block;
        max-width: 185px;
      }
    }
  }

  .categoryRow li {
    width: @game-width-mobile;
    // height: @game-height-mobile;
    overflow: hidden;
  }

  .lobby-main-content-child {
    min-height: 1000px;
    padding-right: 32px;
  }

  .mobile-sort-filter {
    width: 100%;
    text-align: right;
  }

  .game-grid li img,
  .categoryRow li img {
    display: inline-block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    border: 1px solid transparent;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    opacity: 1;
  }

  .categoryRow li img.selected {
    border: 1px solid @bright-yellow;
  }

  /* @todo: use the gradient mixin for this */

  .hover {
    white-space: pre-wrap;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: @game-width-desktop + 2;
    height: @game-height-desktop + 2;
    text-align: center;
    opacity: 0;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7) 77.47%, #000 99.8%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateZ(0);
    /* no repaints */
    .game-buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 11px;
      max-width: 90%;
    }

    .url-fun {
      background: black;
      border: 1px solid #d8d8d87a;
      margin-top: 3px;
      color: #d8d8d8;
      font-weight: 600 !important;
      font-size: 10px !important;
      padding: 3px 11px !important;
      margin-bottom: -41px;
      width: fit-content;
      align-self: center;
    }
    .url-real {
      padding: 8px 16px !important;
      background: var(--primary-button-bg);
      text-shadow: none;
      color: var(--secondary-color);
      margin-bottom: 25px;
      font-size: 13px;
      line-height: 13px;
      transition: all 0.2s ease-in-out;
      align-self: center;
      font-weight: 900;

      &:hover {
        transform: scale(1.1);
        color: var(--secondary-color);
      }
    }
  }

  .hover:hover {
    opacity: 1;
  }

  .game-disabled {
    .is-offline {
      background: repeating-linear-gradient(45deg, #222, #222 10px, #444 10px, #444 20px);
      opacity: 0.9;
      z-index: 3;
      width: @game-width-desktop + 1;
      height: @game-height-desktop + 1;
      position: absolute;
    }
    .in-maintenance {
      z-index: 3;
      width: @game-width-desktop + 1;
      height: @game-height-desktop + 1;
      position: absolute;
      background: url(../img/rizk-maintenance.png) no-repeat top center;
      background-size: @game-width-desktop + 1;
    }
  }

  .hover a.btn-play {
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 64px;
    height: 64px;
    margin: 62px auto 0;
    padding: 19px 24px;
    color: @brand-white;
    background: @brand-yellow;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  .favourite-game {
    background: url(/assets/img/favourite-star.png) no-repeat;
    background-position: 0px 0px;
    background-size: cover;
    width: 24px;
    height: 21px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .desktop-fav {
    .favourite-game {
      &:hover {
        background-position: -28px 0px;
      }
    }
  }

  .favourite-game-mobile {
    background: url(/assets/img/favourite-star.png) no-repeat;
    background-position: 0px 0px;
    background-size: cover;
    width: 24px;
    height: 21px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .favourite-game-checked {
    background-position: -28px 0px;
  }

  .favourite-info {
    background-color: var(--tertiary-color);
    margin: 30px;
    padding: 15px;
    border-radius: 4px;
    display: none;
    @media only screen and (max-width: @menu-switch-breakpoint) {
      margin: 16px;
    }
  }

  .hover .tri {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 0 13px 22.5px;
    border-color: transparent transparent transparent #000000;
  }

  #search-content {
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition:
      transform 0.5s ease-in-out,
      -webkit-transform 0.5s ease-in-out;
    -webkit-transform: translateY(-110%);
    transform: translateY(-110%);
    // opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .search-grid {
    padding: 0;
    li {
      display: inline-block;
      position: relative;
    }
    .cat .categoryTitle {
      padding-left: 8px;
    }
  }

  .category-container .cat {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    padding-bottom: 20px;
  }

  .game-info-container {
    position: absolute;
    height: 330px;
    width: 100%;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    display: none;
  }

  .game-info {
    -webkit-transform: translateY(-330px);
    transform: translateY(-330px);
    background: rgba(40, 40, 40, 0.8);
    position: relative;
    color: @brand-white;
    height: 300px;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    margin: 16px 32px;
  }

  .game-info a {
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .game-info h1 {
    .header-font(48px);

    text-transform: uppercase;
    margin-top: 0;
  }

  .game-info h3 {
    margin: 20px 0;
    text-transform: none;
    line-height: 22px;
    font-size: 16px;
  }

  .game-info-left {
    margin: 16px;
    position: absolute;
    padding-right: 20px;
    width: 60%;
    min-width: 300px;
    max-width: 800px;
  }

  .game-info-right {
    width: 100%;
    height: 100%;
    // background: url("../img/games/banners/default.jpg") no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    -webkit-filter: blur(16px);
    filter: blur(16px);
    opacity: 0.5;
  }

  .search-input-container {
    display: inline-block;
    position: relative;
  }

  #casino-search {
    padding-top: 3px;
    position: relative;
    z-index: 1;
  }

  .game-search-form {
    width: 100%;
    // padding: 0 @spacing;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      border-radius: var(--button-border-radius);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      padding: 9px 28px 9px 18px;
      position: relative;
      background: var(--search-button-background);
      color: #616161;
      -moz-appearance: none;
    }
  }

  .search-input-container,
  & input {
    width: 100%;
  }

  .game-search-form label {
    width: 16px;
    height: 16px;
    background: url('../img/search_gray.svg') no-repeat;
    background-size: contain;
    border: none;
    position: absolute;
    right: 24px;
    top: 14px;
    margin: 0;
    z-index: 1;
  }

  .game-search-form .close-search {
    background: url('../img/close.svg') no-repeat;
    position: absolute;
    cursor: pointer;
    display: none;
  }

  .game-search-form input:focus {
    outline: none;
  }

  .jackpot-ticker {
    .header-font(20px);

    background: #212121;
    border-radius: var(--button-border-radius);
    color: var(--tertiary-color);
    position: absolute;
    top: 0;
    margin: 152px 16px 16px 16px;
    z-index: 1;
    width: 156px;
    text-align: center;
    pointer-events: none;

    &.jackpot-mobile {
      .font-size(18px);

      margin: 90px 3px 3px 3px;
      width: 114px;
    }
  }

  .child-game-value {
    background: url('../img/icons/child-value.svg') no-repeat center center;
    position: absolute;
    top: 8px;
    z-index: 1;
    height: 26px;
    width: 188px;
    opacity: 1;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    pointer-events: none;
  }

  .child-game-value p {
    color: @brand-black;
    margin-top: 2px;
  }

  .game-modal {
    min-width: 0;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    padding: 15px;
    .mobile-game-title {
      .header-font(26px);

      text-transform: uppercase;
      color: @brand-white;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      margin: 15px auto;
    }
    .modal-game-button {
      display: block;
      margin: 15px 30px;
    }
    @media only screen and (max-width: 750px) {
      .modal-game-button {
        width: 50%;
        margin: 15px auto;
      }
    }
  }

  // Filter buttons
  .filter-btn {
    border-radius: var(--button-border-radius);
    // border: 1px solid #333333;
    background: var(--secondary-bg-button);
    color: var(--secondary-color-button);
    padding: 8px 20px 8px 20px;
    margin: 5px 10px 5px 0px;
    box-sizing: border-box;
    font-weight: 500;

    &.submit-filters {
      color: white;
    }
  }

  .filter-btn:active {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
  }

  .active-filter-btns {
    color: var(--secondary-color);
    background: var(--filter-bg-btn);
  }

  .active-filter-btns:hover {
    background: var(--filter-bg-btn);
  }

  // buttons-carousel
  .buttons-carousel {
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .buttons-carousel__list {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
  }

  .smooth-scroll {
    scroll-behavior: smooth;
  }

  .buttons-carousel__list-item {
    display: flex;
    flex: 0 0 120px;
    flex-basis: auto;
  }

  @media only screen and (max-width: 1000px) {
    .game-info-left {
      width: 100%;
    }
  }

  @media only screen and (max-width: @menu-switch-breakpoint) {
    .game-search-form {
      padding: 8px;
    }

    #casino-search {
      background: var(--body-background-color);
    }

    .game-info {
      margin: 16px;
    }
    .categoryTitle {
      padding-left: 16px;
      text-align: left;
    }
    #lobby-all {
      margin-top: 0;
    }
    .buttons-carousel {
      margin-top: 0;
      padding: 0px !important;
    }
  }

  @media only screen and (max-width: 640px) {
    .game-info-left h3 {
      display: none;
    }
    .category-row-races-template-desktop {
      margin: 0 5px;
      .category-row-parent {
        margin-left: 0px;
      }
      .race-individual {
        min-width: 350px;
        .streak-info .tooltiptext {
          top: -25px;
        }
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .search-grid li {
      width: 44%;
    }
    .game-grid li img {
      width: 100%;
      height: auto;
    }
    .search-grid {
      text-align: center;
      margin: 0;
      .cat .categoryTitle {
        text-align: left;
        margin-left: 9px;
      }
    }
    .jackpot-ticker {
      &.jackpot-mobile {
        margin: 76% 3% 3% 3%;
        width: 94%;
      }
    }
  }

  @media all and (max-width: 320px) {
    .game-search-form {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      .search-input-container,
      & input {
        width: 100%;
      }
      .search-input-container {
        margin-bottom: 10px;
      }
    }
  }

  .fakeOrRealGamePopup {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 1s ease-out;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .fakeOrRealGamePopup__wrapper {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      max-height: 480px;
      max-width: 400px;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 3%;
      border: 1px solid rgba(255, 255, 255, 0.6);
      padding: 1rem;

      .fakeOrRealGamePopup__closeBtn {
        background-image: url(../img/icons/modal-close-button-filled-yellow.svg);
        cursor: pointer;
        height: 36px;
        width: 36px;
        background-color: transparent;
        align-self: flex-end;
        transition: transform 0.1s ease-in-out;

        &:active {
          transform: scale(0.8);
        }
      }

      .fakeOrRealGamePopup__gameImg {
        width: 188px;
      }
      .fakeOrRealGamePopup__gameName {
        font-family: var(--header-font), sans-serif;
        text-transform: uppercase;
      }
      .fakeOrRealGamePopup__btnGroup {
        display: flex;
        padding: 0rem 1rem 1rem 1rem;
        button {
          padding: 1rem;
        }
        .fakeOrRealGamePopup__realGameBtn {
          margin: 0rem 1rem 1rem 0.5rem;
        }
        .fakeOrRealGamePopup__fakeGameBtn {
          margin: 0rem 0.5rem 1rem 1rem;
        }
      }
    }
  }

  .fakeOrRealGamePopup__gameImg--animate {
    animation-name: fakeOrRealImageAnimation;
    animation-duration: 600ms;
    animation-delay: 10ms;
    animation-timing-function: ease-in-out;
  }

  @keyframes fakeOrRealImageAnimation {
    0% {
      transform: rotate3d(1, 1, 1, 0deg);
    }
    50% {
      transform: scale(1.05) rotate3d(-2, 1, 0, -20deg);
    }
    100% {
      transform: rotate3d(1, 1, 1, 0deg);
    }
  }

  .noscroll {
    overflow: hidden;
  }

  .hard-reality-check-btn-group {
    display: flex;
    padding: 0rem 1rem 1rem 1rem;

    button {
      padding: 1rem;

      &.hard-reality-check-btn-logout {
        margin: 0rem 1rem 1rem 0.5rem;
      }

      &.hard-reality-check-btn-continue , &.hard-reality-check-btn-confirm {
        margin: 0rem 0.5rem 1rem 1rem;

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }

  #seo-content-box {
    padding: 60px 32px;
  }

  .mob-filter-btn {
    &.active {
      background: var(--tertiary-color) !important;
      color: black !important;
      * {
        color: black !important;
      }
    }
  }

  .filter-btns-mob-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .games-sorting-filter-modal-mob {
    width: 95%;
    height: 100%;
  }

  .games-sorting-filter-modal-mob > div {
    padding: unset !important;
  }

  #page-title {
    align-self: center !important;
  }

  .games-filter-form {
    width: 100%;
    padding: 10px @spacing;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: var(--body-background-color);
    display: flex;
    font-size: 13px;
    text-transform: uppercase;

    label {
      color: var(--primary-color);
      font-weight: 500;
      align-self: center;
      margin: 0px;
      padding-right: 7%;
      white-space: nowrap;
    }
    p {
      color: var(--primary-color);
      margin-left: 7%;
    }
    .games-filter-item {
      display: flex;
      align-items: center;
    }
    .games-filter-item:nth-of-type(2) {
      margin-left: 5%;
    }
    .games-provider-filter-selected {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 60px;
      white-space: nowrap;
    }
  }

  .games-filter-select {
    appearance: none;
    outline: 0;
    border: 0.5px solid #bebebec7;
    box-shadow: none;
    flex: 1;
    padding: 0 1em;
    color: var(--primary-color);
    background-color: transparent;
    background: url('../img/icons/arrow-down.svg') no-repeat right center;
    background-origin: content-box;
    cursor: pointer;
    position: relative;
    display: flex;
    width: 10.5em;
    height: 2.8em;
    border-radius: 0.3em;
    align-items: center;
    option {
      background: black;
      color: white;
    }
  }

  .games-filter-select::-ms-expand {
    display: none;
  }

  .clear-game-providers {
    cursor: pointer;
    text-transform: none;
    white-space: nowrap;
    .close-label-prov {
      padding-right: 10%;
    }
  }

  .provider-count {
    border-radius: 0.8em !important;
    background-color: var(--tertiary-color);
    color: black;
    padding-top: 10%;
    padding-bottom: 10%;
    font-size: 11px;
    display: inline-block;
    line-height: 0.5em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em;
    font-weight: 600;
  }

  .filter-btn-home {
    padding: 0;
    width: auto;
    text-align: center;
    &.active-filter-btns {
      .home-btn {
        filter: unset !important;
      }
    }
    .home-btn {
      background-image: url(/assets/img/icons/home-icon.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 23px;
      width: 40px;
      display: inline-block;
      filter: invert(1);
      pointer-events: none;

      &.inkabet-home {
        background-image: url(/assets/img/icons/home-icon-light.svg);
      }
      &.betsson-home {
        background-image: url(/assets/img/icons/home-icon-light.svg);
      }
    }
  }

  .game-tile {
    margin: 0 10px 20px 5px;
    display: inline-block;
    border: 0.2px solid #ffffff45;
    border-radius: 10px;
    box-shadow: 0 0 12px #fdfcfc3f;
    overflow: hidden;
    height: 190px;
    width: 190px;
    position: relative;
    background: var(--lobby-thumbnail-bottom-color);
    .hover {
      border-radius: 10px;
      height: 190px;
      width: 186px;
      .play-button {
        width: 50px;
        height: 50px;
        background-image: url(/assets/img/icons/play-btn.svg);
        box-shadow: 1px 1px 12px 1px black;
        background-size: cover;
        background-position: center;
        display: inline-block;
        background-color: #ffe500;
        border-radius: var(--button-border-radius);
        align-self: center;
        margin-bottom: 5px;
        margin-top: 32px;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
      .game-title {
        color: #fffefe;
        font-size: 14px;
        top: 0;
        position: absolute;
        padding-top: 26px;
        font-weight: 500;
        margin: 0;
        width: 90%;
        text-shadow: 1px 1px 10px black;
        text-transform: capitalize;
      }
    }
    .game-img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 190px;
      width: 186px;
      object-fit: fill;
    }
    .jackpot-container {
      background: #1c1b0dc9;
      text-align: center;
      position: -webkit-sticky;
      position: sticky;
      bottom: -1px;
      box-shadow: 0px 2px 5px 2px #ffeaeaa3;
      width: 100%;
      padding-bottom: 1px;
      padding-top: 1px;
      .jackpot-amount {
        text-transform: uppercase;
        color: var(--tertiary-color) !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        margin: 2px;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    .categoryRow {
      .game-tile,
      .game-tile-show-more {
        height: 130px;
        width: 130px;
        .hover {
          height: 130px !important;
          width: 130px !important;
        }
        .game-img {
          height: 130px !important;
          width: 130px !important;
        }
      }
    }
    .game-tile,
    .game-tile-show-more {
      height: 155px;
      width: 155px;
      .hover {
        height: 155px !important;
        width: 155px !important;
      }
      .game-img {
        height: 155px !important;
        width: 155px !important;
      }
    }

    .jackpot-container {
      .jackpot-amount {
        font-size: 13px !important;
        margin: 0px !important;
      }
    }
  }

  .lobby-main-content-child {
    padding-bottom: 20px;
    padding-top: 20px;
    &.full-list {
      padding: 0 32px;
      margin-top: 20px;
    }
  }

  .fade-in-down-full {
    animation: fadeInDownFull 0.4s ease-in-out both;
  }

  .fade-out-up-full {
    animation: fadeOutUpFull 0.4s ease-in-out both;
  }

  .fade-in-full {
    animation: fadeIn 0.4s ease-in-out both;
  }

  .fade-out-full {
    animation-delay: 0.2s !important;
    animation: fadeOut 0.4s ease-in-out both;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fadeInDownFull {
    from {
      position: absolute;
      transform: translate3d(0, -120%, 0);
      display: none;
    }
    to {
      position: relative;
      transform: translate3d(0, 0, 0);
      display: block;
    }
  }

  @keyframes fadeOutUpFull {
    from {
      position: relative;
      transform: translate3d(0, 0, 0);
      display: block;
    }
    to {
      position: absolute;
      transform: translate3d(0, -120%, 0);
      display: none;
    }
  }

  @media only screen and (min-width: @sb-tablet) {
    .lobby-top-section {
      display: inline-flex;
      position: relative;
      .navi-arrow {
        height: 100%;
        z-index: 0;
        opacity: 1;
        width: fit-content;
        position: relative;
        img {
          margin-top: 16px;
          width: 64px;
        }
      }
      .expand-search {
        position: relative;
        display: flex;
        width: 100%;
        cursor: pointer;
        background: var(--search-button-background);
        justify-content: center;
        padding: 9px 18px 9px 18px !important;
        border-radius: var(--button-border-radius);
        transition: 0.1s all ease-in-out;
      }
      .buttons-carousel {
        width: 94%;
        padding-right: 0;
        display: inline-block;
        transition: 0.1s all ease-in-out;
      }
      #casino-search {
        width: 100%;
      }
      .game-search-input {
        overflow: hidden;
        width: 100%;
        padding: 0;
        padding-left: 5px;
        transition: 0.1s all ease-in-out;
        &::placeholder {
          color: var(--search-button-placeholder);
        }
      }
      .search-magnifier {
        width: 16px;
        height: 16px;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+c2VhcmNoLWljb248L3RpdGxlPjxwYXRoIGQ9Ik0xMC4wMyAxMS41MjNhNi4zNSA2LjM1IDAgMSAxIDEuNDk0LTEuNDk0bDQuMTcgNC4xNzJhMS4wNCAxLjA0IDAgMCAxIDAgMS40NzdsLS4wMTcuMDE4YTEuMDQ1IDEuMDQ1IDAgMCAxLTEuNDc2IDBsLTQuMTctNC4xNzJ6bS0zLjY4LS4zMmE0Ljg1NSA0Ljg1NSAwIDEgMCAwLTkuNzEgNC44NTUgNC44NTUgMCAwIDAgMCA5LjcxeiIgZmlsbD0iI0FDQUNBQyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+)
          no-repeat;
        background-size: contain;
        border: none;
        margin: 0;
        z-index: 1;
        top: 0;
        right: 0;
        height: 100%;
        background-position-y: center;
        margin-right: 0px;
        position: relative;
        align-self: center;
      }
      .casino-search-container {
        width: 25%;
        z-index: 1;
        display: inline-block;
        text-align: right;
        transition: 0.1s all ease-in-out;
      }
      &.open-search {
        .game-search-input {
          padding-right: 30px;
          padding-left: 5px;
          width: 100%;
        }
        .casino-search-container {
          width: 20%;
        }
        .search-magnifier {
          margin-right: 10px;
        }
      }
    }
  }

  .search-content-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--overlay-background);
    z-index: -1;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
  }

  .search-content-cover.show-cover {
    z-index: 1;
    opacity: 1;
  }

  .search-modal-content {
    position: absolute;
    width: 545px;
    background: var(--primary-bg-color);
    border: 1px solid #ffffff4a;
    right: 3%;
    margin-top: 5px;
    border-radius: 15px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    padding: 0px;
    height: 0;
    .search-modal-top {
      justify-content: space-between;
    }
    p {
      text-align: left;
      text-transform: uppercase;
      font-size: 18px;
      color: white;
    }
    .no-search-games {
      font-size: 14px;
      padding: 15px 0;
      color: var(--primary-color);
    }
    #search-result-list {
      grid-template-columns: repeat(auto-fill, 150px);

      .favourite-game {
        display: none !important;
      }

      li {
        width: 150px !important;
        height: 150px !important;
        box-shadow: none !important;
        margin: 0 !important;

        .hover {
          width: 150px !important;
          height: 150px !important;

          .game-title {
            text-align: center;
            font-size: 12px;
            padding-top: 0px;
            position: relative;
          }

          .game-buttons-container {
            margin-top: 0px;

            a {
              margin: 0;
              padding: 5px 10px !important;
            }

            .url-real {
              font-size: 11px !important;
            }
          }
        }
        .game-img {
          width: 150px !important;
          height: 150px !important;
        }
      }
    }
  }

  @media only screen and (max-width: @menu-switch-breakpoint) {
    .search-modal-content {
      right: 5%;
      width: 90%;
      #search-result-list {
        grid-template-columns: repeat(auto-fill, 125px);
        li {
          width: 125px !important;
          height: 125px !important;
          box-shadow: none !important;
          margin: 0 !important;
          .hover {
            width: 125px !important;
            height: 125px !important;
            .game-title {
              text-align: center;
              font-size: 12px;
              padding-top: 20px;
            }
            .url-real {
              font-size: 11px !important;
            }
          }
          .game-img {
            width: 100% !important;
            height: 125px !important;
          }
        }
      }
    }
  }

  .top-carousel-container {
    display: flex;
    gap: 10px;
  }

  .top-carousel-home {
    align-self: center;
    .filter-btn-home {
      margin: 0;
      padding: 4px;
      border-radius: var(--button-border-radius);
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      .home-btn {
        width: 20px;
      }
    }
  }

  .category-row-mobile-template {
    padding-right: 6px;
  }
  .category-row-header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .rizk-btn {
      height: 30px;
      width: 106px;
      align-self: center;
      font-weight: 600;
      font-size: 11px;
      border: 1px solid white;
      background: black;
      color: white !important;
      transition: all 0.2s ease-in-out;
      text-align: center;
      line-height: 13px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .game-tile-show-more {
    margin: 0 10px 20px 5px;
    display: inline-block;
    border: 0.2px solid #ffffff45;
    border-radius: 10px;
    box-shadow: 0 0 12px #fdfcfc3f;
    overflow: hidden;
    height: 190px;
    width: 190px;
    background: #302f2f;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #262626;
      p {
        font-size: 17px;
        transform: scale(1.1);
      }
    }
    .show-more-tile-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-self: center;
      pointer-events: none;
      p {
        color: white;
        display: block;
        text-align: center;
        font-size: 15px;
        position: relative;
        margin: auto;
        font-weight: 600;
        transition: all 0.25s ease-in-out;
      }
    }
  }

  .search-modal-quicklinks {
    display: none;
    .search-modal-quicklinks-title {
      display: flex;
      align-items: center;
      height: 25px;
    }
    .search-modal-quicklinks-container {
      width: 100%;
      border-left: 1px solid #ffffff38;
      display: inline-block;
      .search-modal-quicklinks-item {
        float: left;
        height: 30px;
        display: flex;
        width: 100%;
        cursor: pointer;
        align-items: center;
        &:hover {
          background: #68676742;
        }
      }
      .quicklink {
        margin-left: 10px;
        padding: 0 1em;
        color: #fff;
        background-color: transparent;
        background: url('../img/icons/link-icon.jpeg') no-repeat right center;
        background-origin: content-box;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        display: inline-block;
        filter: brightness(0) invert(1);
      }
      .quicklink-url {
        color: white;
        padding-left: 10px;
      }
    }
  }
}

.min-max-limit {
  display: block;
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.79);
  justify-content: center;
  align-items: center;

  &__text {
    font-size: 12px;
    color: var(--tertiary-color);
    font-weight: 600;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1149px) {
  .desktop-search {
    display: none !important;
  }
  .lobby-main-content-child.desktop-game-tiles {
    display: none !important;
  }
  .navi-arrow {
    display: none !important;
  }
  #page-title {
    margin: 0 !important;
  }
}

@media only screen and (min-width: 1150px) {
  .mobile-game-search {
    display: none !important;
  }
  .top-carousel-container {
    display: contents !important;
  }
}

.fade-out-anim {
  animation: fade-out 0.2s ease;
}

@keyframes zoom-out {
  0% {
    transform: scale(0.1, 0.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// game search

.search-modal-content {
  &.search-results-clear {
    opacity: 0;
    height: 0;
    padding: 0;
    .results-title,
    .close-search,
    .search-modal-quicklinks {
      display: none;
    }
  }
  &.search-results-open {
    opacity: 1;
    height: auto;
    padding: 30px;
    overflow-y: scroll;
    max-height: 750px;
    .results-title,
    .close-search,
    .search-modal-quicklinks {
      display: block;
    }
    .search-show-more {
      background: var(--primary-bg-color);
    }
  }

  .search-modal-top {
    position: sticky;
    right: 10px;
    top: 0px;

    .close-search {
      right: -5px;
      top: -10px;

      @media only screen and (min-width: 1150px) {
        right: -15px;
      }
    }
  }
}

@media only screen and (max-width: 1149px) {
  .search-modal-content {
    &.search-results-open {
      opacity: 1;
      height: auto;
      padding: 20px !important;
      max-height: 550px !important;
    }
  }

  .lobby-top-section {
    width: inherit;
    padding: 5px 14px 5px 14px !important;
    position: sticky !important;
    top: 50px;
    margin-top:15px;
  }

  .new-mobile-nav {
    .top-bar-fixed {
      width: 100% !important;
    }
    .link-wrap {
      a {
        &.menu-item-active {
          color: var(--tertiary-color);
        }
      }
    }
  }
}

// Top bar sticky

.desktop-search-right {
  right: 0;
  position: sticky;
}

.lobby-top-section {
  background: var(--body-background-color);
  backdrop-filter: blur(5px);
  z-index: 7;
  width: 100%;
  padding: 7px 32px;
  gap: 10px;
}

.top-bar-fixed {
  position: sticky !important;
  width: 100%;
  top: 0;
}

@media only screen and (min-width: 1150px) {
  .new-mobile-nav {
    .top-bar-fixed {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1150px) {
  #filter-btns-container {
    margin-top: 0px !important;
    display: inline-flex !important;
    justify-content: space-between;
    width: 100%;
  }
}

.mob-game-providers-sort-item {
  label {
    pointer-events: none;
  }
}

#filter-btns-container {
  display: block;
}

.new-mobile-nav {
  #page-title {
    margin-top: 15px;
  }
}

#page-title {
  margin-top: 20px;
}

.page-title-se {
  margin-top: 30px !important;
}

.lobby-main-content-child:not(.full-list) {
  margin-top: -20px;
}

// search show more styling

.search-show-more {
  text-align: center;
  display: none;
  background: var(--secondary-bg-button);
  color: var(--tertiary-color-button);
}

.search-show-more .open-content {
  display: block;
}

.category-row-parent li {
  display: inline-block;
  position: relative !important;
}

.categoryRowList {
  li {
    margin: 0 5px !important;
  }
}

.categoryRowList.collapse {
  li {
    margin: 0 5px 10px 5px !important;
  }
}

.category-row-parent li span:active:after {
  background: @brand-black;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .lobby-main-content-child {
    padding: 0px 0px 20px 0px !important;
    text-align: center;
  }
  .game-tile {
    .hover {
      display: none !important;
    }
    .mob-fav {
      display: block !important;
      z-index: 0;
      position: relative;
    }
    #fakeOrRealGamePopup {
      display: block !important;
    }
  }
  .categoryRowTemplate {
    .category-row-parent {
      .navi-arrow {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .game-tile {
    .hover {
      display: flex !important;
    }
    .mob-fav {
      display: none !important;
    }
    #fakeOrRealGamePopup {
      display: none !important;
    }
  }
  .category-row-parent {
    position: relative;
    .navi-arrow {
      display: block !important;
    }
    .categoryRow {
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
      transition: -webkit-transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
      transition:
        transform 0.5s ease-in-out,
        -webkit-transform 0.5s ease-in-out;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); // perform an "invisible" translation
      height: @game-height-desktop + 2;
      margin-left: 27px;
      li {
        width: @game-width-desktop !important;
        height: @game-height-desktop + 2 !important;
        overflow: hidden;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
      }
    }
    .categoryRowRaces {
      margin-left: 0px;
      height: fit-content !important;
      li {
        width: @race-width-desktop !important;
        height: @race-height-desktop !important;
      }
    }
  }
}

.category-row-template {
  position: relative;
}

#search-result-list {
  text-align: justify;
  margin-bottom: 10px;
  display: grid;
  justify-content: center;
  column-gap: 10px;
  row-gap: 10px;
}

@media screen and (max-width: @sb-tablet) {
  .sports-all-content {
    .wrap {
      display: none !important;
    }
  }
}

.game-tile,
.race-col-race-img-container {
  .exclusive-tag {
    top: 0;
    text-align: center;
    display: none;
    position: absolute;
    width: 100%;
    &.exclusive {
      display: block;
      z-index: 0;
    }
    .exclusive-tag-container {
      width: fit-content;
      padding: 2px;
      padding-left: 7px;
      padding-right: 7px;
      background: var(--tertiary-color);
      height: 23px;
      display: inline-flex;
      justify-content: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
      word-wrap: break-word;
      p {
        font-weight: 600;
        font-size: 14px;
        color: black;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
    }
  }
}

.race-col-race-img-container {
  .exclusive-tag {
    &.exclusive {
      position: relative;
      top: 23px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .game-tile,
  .race-col-race-img-container {
    .exclusive-tag {
      .exclusive-tag-container {
        max-width: 63%;
        height: 20px;
        p {
          font-size: 10px;
        }
      }
    }
  }
  .race-col-race-img-container {
    .exclusive-tag {
      &.exclusive {
        position: relative;
        top: 20px !important;
        width: 100%;
        .exclusive-tag-container {
          max-width: 70%;
        }
        p {
          font-size: 9px !important;
        }
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .game-search-form input:focus ~ .search-modal-content {
    height: 280px;
    max-height: 280px;
  }
}