// iPhone 6plus max-width
@payment-ui-breakpoint: 414px;
@payment-darker-gray: #ebeff4;
@payment-modal-default-width: 550px;

.qd-cont {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  z-index: 10;
  margin-left: 0;
  width: 100%;
  padding: 0 16px;

  //transition: all .3s ease-in-out;

  &.race-container-visible {
    width: ~'calc(100% - 256px)';
  }
}

.quick-deposit {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  color: #9c9c9c;
  background-color: #1e1e1e;

  .message {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 53px;
    background: #282828;
    color: #9c9c9c;
    line-height: 22px;

    .qd-title {
      margin-top: 3px;
      color: @brand-yellow;
    }
  }

  img {
    max-height: 45px;
    margin: 0 6px;
    max-width: 70px;
    position: relative;
    top: 0;

    &.safari  {
      top: 10px;
    }
  }

  .qd-nav {
    padding: 0px 20px;
    background-color: transparent;

    &.prev span {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 8px 0;
      border-color: transparent #9c9c9c transparent transparent;
    }

    &.next span {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent #9c9c9c;
    }
  }

  .quick-deposit-form {
    flex: 40 0 0;
    display: flex;
    align-items: center;
    padding: 3px;

    button {
      color: @brand-white;
      background: var(--brand-green);
      border-radius: var(--button-border-radius);
      text-transform: uppercase;
      margin-right: 2px;
      margin-left: 2px;
      padding: 0px 20px;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    input {
      width: 80px;
      line-height: 12px;
      border: 1px solid #404040;
      margin-right: 5px;
      background-color: #282828;
      color: #acacac;
    }

    .account-masked {
      display: inline-block;
      width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
    }

    .fields {
      display: flex;
      position: relative;
      align-content: center;
      height: 100%;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 600px) {
  .quick-deposit .quick-deposit-form .account-masked {
    width: 20%;
  }
  .fields {
    width: 55%;
  }
  .quick-deposit .quick-deposit-form button,
  .quick-deposit .quick-deposit-form input {
    width: 30%;
  }
  .quick-deposit img {
    width: 20%;
  }
}

@media screen and (max-width: @menu-switch-breakpoint) {
  .qd-cont {
    margin-left: 0;
  }
}

.payments-block {
  margin: 5%;
}

.mudal-payment {
  overflow: hidden;
  min-height: 400px;
  min-width: 100%;
  transition: all 0.5s ease-in-out;

  @media screen and (min-width: 550px) {
    min-width: @payment-modal-default-width;
  }

  .epro-msg {
    position: absolute;
    padding-left: 39px;
    font-size: 12px;
    color: #aeaeae;
  }

  &.wider,
  &.wide,
  &.full-wide {
    width: 100%;
  }
}

.withdraw-container,
.bonus-container,
.user-container,
.deposit-container {
  width: 100%;
  transition: all 0.5s ease-in-out;
  text-align: center;

  &.user-hidden {
    opacity: 0;
  }
  &.user-visible {
    opacity: 1;
  }
}

.user-container {
  margin-top: -72px;
  padding: 72px 0 40px 0 !important;
}

.deposit-container {
  .bank-logo-footer {
    display: none;
    margin: 0 20px;
    padding: 0 0 40px;

    img {
      width: 100%;
      max-width: 450px;
      height: auto;
    }
  }
}

.pending-withdrawal {
  display: none;
  padding: 10px 15px;
  border: 1px solid #a8a8a8;
  color: @brand-red;
  font-size: 16px;
  text-decoration: none;
  margin: 15px 32px 0 32px;

  .rizk-border-radius;

  .bggradient(#fcfcfc, #f1f1f0);
}

.deposit-bonus-selection {
  justify-content: center;

  .btn-green {
    margin: 20px;
    width: 80%;
  }
  .alignToLeft {
    text-align: left;
  }
  .selection-block {
    width: 95%;
    height: auto;
    display: block;
    border-radius: 8px;
    background: white;
    font-size: 25px;
    box-shadow: 1px 1px 5px rgba(0.4, 0.4, 0.4, 0.4);
    margin: 10px;
    padding: 10px;

    .bonusArea {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .bonusText {
        //max-width:270px;
        .tcSection {
          display: block;
          font-size: 10px;
          margin-top: 10px;
          font-weight: normal;
        }
      }

      .paymentsArea {
        .paymentImages {
          display: flex;
          margin-left: 27px;

          .paymentLogo {
            width: 35px;
            height: 30px;
            background-color: lightgray;
            margin: 5px 2px 0px 2px;
            border-radius: 8px;
          }
        }

        .seeMorePayments {
          display: block;
          font-size: 13px;
        }
      }
    }
  }

  div .first-deposit-payment-label {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    text-transform: none;
    margin-left: 30px;
    margin-top: -14px;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none !important;

    & + label span {
      background: url('../img/form-icons.png') top center;
      background-size: 24px;
      background-position: 0 -210px;
      display: inline-block;
      width: 24px;
      height: 21px;
      position: relative;
      top: 7px;
      margin-right: 5px;
    }

    &:checked + label span {
      background-position: 0 -190px;
    }

    &.paas-bonus-select:checked + label span {
      background-position: 0 -190px;
    }

    // Red check for deposit modal
    .deposit-bonus-radio &:checked + label span {
      background-position: 0 -96px;
    }
  }
}

.paas-container {
  max-width: 1140px !important;
  padding: 2% !important;
  margin: 0;
  justify-content: center;
  display: flex;
  background: @payment-darker-gray;
  #paas-deposit-iframe,
  #paas-withdraw-iframe {
    width: 100% !important;
  }
}

.deposit-bonus {
  display: none;
  width: 100%;
  max-width: @payment-modal-default-width;
  background: @payment-darker-gray;
  margin: 0;
  padding: 0 32px 15px;
  text-align: left;
  position: relative;

  .title {
    font-size: 13px;
    color: var(--brand-green);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 10px 0;
  }

  > label,
  .bonus-tc {
    color: #a8a8a8;
    font-size: 12px;
    display: block;
    float: left;
  }

  .bonus-tc {
    text-transform: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .deposit-bonus-inner {
    position: relative;
    width: 100%;
    display: block;
    color: #acacac;
    font-size: 12px;

    @media screen and (max-width: 768px) {
      .de & {
        padding-bottom: 25px;
      }
    }

    .disabled-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      display: none;
    }
  }

  .gbp-bonus-disclaimer {
    font-size: 14px;
    color: #595959;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 5px;
    width: 100%;
  }

  img {
    float: left;
    width: 75px;
  }

  p {
    font-size: 15px;
    line-height: 17px;
    margin: 0 0 7px;
  }

  .radio-div {
    user-select: none;
  }

  input[type='radio'] {
    & + label span {
      cursor: pointer;
      background-color: @brand-white;
    }
  }

  .deposit-bonus-radio {
    margin-top: 10px;

    label {
      font-weight: 300;
      text-transform: none;
      font-size: 14px;
      letter-spacing: @letr-spacing-small;
    }

    label[for='deposit-bonus-on'] {
      margin: 0 10px 0 0;
    }

    input[type='radio']:first-child:checked {
      & + label span {
        background-position: 0 -120px;
      }
    }
  }
}

.gbp-disclaimer-container {
  width: @payment-modal-default-width;
  padding: 0 32px 6px 32px;
  p {
    margin: 0;
  }

  label label {
    margin-top: -20px;
    margin-left: 30px;
    text-transform: none;
    font-weight: normal;
  }
}

.deposit-deal-text {
  font-size: 16px;
  color: black;
  font-weight: 600;
}

.rizk-payment-iframe {
  transform: translate3d(0, 0, 0);
  transition: opacity 0.35s linear;
  width: 100%;
}

.rizk-bankid-iframe {
  transform: translate3d(0, 0, 0);
  transition: opacity 0.35s linear;
  width: 100%;
  height: 512px;
}

.rizk-payment-iframe-loading,
.rizk-withdraw-iframe-loading {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  display: none;
}

.netbank-image {
  margin: 0 auto;
}

.netbank-text {
  color: #cecece;
}

.withdraw-container {
  text-align: left;
  height: auto;

  label {
    color: #8e8e8e;
  }

  .rizk-withdraw-iframe-content {
    width: 100%;
  }

  p {
    text-align: center;
  }
}

.withdraw-methods {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.saved-account-dropdown,
.deposit-dropdown,
.withdraw-dropdown {
  width: 100%;
  border: 1px solid #cfcfcf;
  outline: 0;
  height: 40px;
  padding-left: 15px;
  color: rgba(0, 0, 0, 0);
  background: #ffffff;
  margin-bottom: 16px;
  -moz-appearance: none;

  .rizk-border-radius;

  option {
    color: black;
  }

  &.bank-wire {
    color: #8e8e8e;
    padding-left: 65px;
  }

  &.trustly {
    color: #8e8e8e;
    padding-left: 100px;
  }
}

.form-group .saved-account-dropdown {
  background: #ffffff;
  color: #000000;
  border: 1px solid #cfcfcf;
  height: 40px;
}

.deposit-image,
.withdraw-image {
  position: absolute;
  width: auto;
  height: 24px;
  margin-left: 45px;
  z-index: 10;
  left: 0;
  top: 8px;
  pointer-events: none;
}

.zero-balance,
#no-withdraw-methods {
  display: none;
  padding: 14px 64px 14px 42px;
}

.redirect-notification {
  padding: 25px 0px 4px 42px;
}

.mudal-cancel-withdraw {
  div {
    border-bottom: 1px solid @grey;
    padding-bottom: 15px;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    p {
      margin: 0;

      &.withdraw-date {
        font-size: 12px;
        color: @warm-grey;
        font-weight: 200;
      }
    }

    button {
      .red-btn;
    }
  }

  .transactiondata {
    margin-top: 20px;
  }
}

/* New Devcode implementation */
/* todo: clean up unused old styles */
.cashier-information {
  padding: 0 32px;
  max-width: 500px;
  font-size: 12px;
  text-align: left !important;
  margin: 10px 0px 0px 0px !important;
}

.payment-row {
  padding: 0 32px;
  margin: auto auto 20px;
  font-size: 16px;
  max-width: @payment-modal-default-width;

  > select {
    display: block;
    margin-bottom: 16px;
  }

  input {
    border: 1px solid #cfcfcf;
    background: #ffffff;
    font-weight: 600;
  }

  input.error {
    border: 1px solid #ec1818;
  }

  select.error {
    border: 1px solid #ec1818;
  }

  label {
    display: block;
    text-transform: none;
    color: #3a3939;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: inherit;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .amount-currency {
    position: absolute;
    top: 36px;
    right: 40px;
    background: #f1f4f6;
    color: #8f98a2;
    border-radius: 2px;
    padding: 2px 8px;
    font-weight: 600;
    font-size: 14px;
  }

  &__bottom-logos {
    height: 40px;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    background-color: #ebeff3;
    gap: 15px;
    align-items: center;

    a {
      width: inherit;
      height: 100%;

      img {
        max-width: 100%;
        height: 100%;
      }
    }
  }

  .expires-on {
    display: flex;
    justify-content: space-between;

    label {
      flex-grow: 2;
      width: 100%;
    }

    .cell {
      flex: 0 0 60%;
      flex-wrap: wrap;
      flex-direction: column;

      .select-wrap,
      input {
        width: 48%;
      }

      .select-wrap {
        select {
          padding: 7px;
          background: #ffffff;
          color: #3a3939;
          font-weight: 600;
        }
      }

      &.cell-cvv {
        flex: 0 0 25%;

        input {
          width: 100%;
          padding-right: 15px;
        }
      }
    }
  }

  .bin {
    background: url('../img/icons/bin.png') no-repeat center;
    position: absolute;
    top: 10px;
    width: 30px;
    height: 19px;
    right: 100px;
    display: block;
    margin: 0;
    background-size: contain;
    filter: grayscale(100%) opacity(0.35);
    cursor: pointer;
  }

  .arrow-down {
    position: absolute;
    top: 16px;
    right: 45px;
  }

  .deposit-warning {
    color: red;
    border: 2px solid red;
    background: #ffe6e6;
    text-align: center;
    border-radius: 4px;
  }

  .withdraw-message {
    color: #7d7d7d;
    border: 1px solid #7d7d7d;
    text-align: center;
    border-radius: 4px;
    word-wrap: break-word;
    padding: 3px;

    &--age-verification {
      color: #7d7d7d;
      border: 1px solid #7d7d7d;
      text-align: center;
      border-radius: 4px;
      word-wrap: break-word;
      padding: 0px;
      position: fixed;
      z-index: 9999;
      width: 100%;
      height: 100%;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.9);

      .age-verification-close {
        padding: 10px;
        color: white;
        border: 2px solid;
        width: 200px;
        margin: 10px auto;
        cursor: pointer;

        &--count-exceeded {
          border-radius: 50%;
          padding: 5px;
          background-image: url('/assets/img/close.png');
          width: 40px;
          height: 40px;
          border: none;
          margin: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 17px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      .age-verification-content {
        height: 100%;
        max-width: 593px;
        width: 90%;
        margin: auto;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        iframe {
          width: 100%;
          height: 600px;
        }

        .age-verification-count-exceeded {
          &__title {
            background-color: #019a39;
            width: 100%;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
              font-size: 22px;
              color: #fff;
              margin: 0;
            }
          }

          &__message {
            background-color: #fff;
            width: 100%;
            padding: 30px;

            p {
              margin: 0;
            }
          }
        }
      }

      &.age-verification-count-exceeded {
        .age-verification-content {
          height: max-content;
          border-radius: 5px;
          overflow: hidden;
        }
      }
    }
  }
  .error {
    border: 1px solid #ec1818;
  }

  .changePhoneNumberLabel {
    width: max-content;
  }
}

#changePhoneNumberErrorContainer {
  display: none;
}

#changePhoneNumberError {
  color: #ec1818;
}

.change-phone-number-parent {
  display: none;
  .change-phone-number-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    .change-phone-number-prefix {
      flex-shrink: 2;
    }
  }
}

#changePhoneNumberButton {
  display: none;
}

@media screen and (max-width: 400px) {
  header {
    button {
      padding-right: 10px !important;
    }
  }

  .mudal-payment {
    .close-button {
      right: 27px;
    }
  }
}

.mudal-payment {
  background-color: #fafbfc;

  header {
    display: flex;

    button, .modal-title {
      .header-font(32px);

      flex-wrap: nowrap;
      background: none;
      width: auto;
      color: rgba(255,255,255,.5);
      text-transform: uppercase;
      line-height: 16px;
      margin: 20px 0 16px;
      padding: 0 16px 0 0;
      border-radius: 0;

      &.active {
        color: rgba(255, 255, 255, 1);
      }
    }

    .modal-title {
      color: #ffffff;
      line-height: 32px;
    }
  }

  button[type='submit'] {
    color: @brand-white;
    background: var(--brand-green);
    text-transform: uppercase;
    border-radius: var(--button-border-radius);
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0.06em;

    &[disabled] {
      background: #8e98a2;
      color: @brand-white;
    }
  }

  button[type='button'] {
    color: @brand-white;
    background: var(--brand-green);
    text-transform: uppercase;
    border-radius: var(--button-border-radius);
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.06em;

    &[disabled] {
      background: #8e98a2;
      color: @brand-white;
    }
  }

  .payment-response {
    padding: 10px;
    border-radius: 4px;
    margin-top: 25px;
  }

  .payment-response.success {
    color: #009a39;
    border: 1px solid #009a39;
    background-color: #eefff5;
  }

  .payment-response.failed,
  .form-error-header {
    background-color: #ffe6e6;
    color: #ec1818;
    border: 1px solid #ec1818;
  }
}

.withdraw-limit-notice,
.deposit-limit-notice {
  color: @brand-red;
  text-align: left;
  margin-bottom: 20px;
}

.cost-breakdown {
  text-align: left;

  .bd-row {
    display: flex;
    justify-content: space-between;
    color: rgba(41, 41, 41, 0.7);
    margin-bottom: 10px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(41, 41, 41, 0.4);

    &.total {
      border-bottom: 1px solid rgb(41, 41, 41);
      color: rgb(41, 41, 41);

      .gibson-semi-bold;
    }

    span {
      flex-wrap: nowrap;
    }
  }
}

.breakdown-container {
  background: @payment-darker-gray;
  padding: 20px 32px 40px;
  max-width: @payment-modal-default-width;
  margin-bottom: 0px;

  .fee-notify {
    font-size: 12px;
    margin: 0;
    text-align: left;
    color: rgba(0, 0, 0, 0.3);
    line-height: 14px;
  }
}

.limit-or-self-exclude {
  background: @payment-darker-gray;
  padding: 5px 30px 5px;
  max-width: @payment-modal-default-width;
  margin-bottom: 0px;
  .basedin {
    margin-bottom: 15px;
    display: block;
    text-align: center;
  }
  .set-limit-text {
    padding-bottom: 10px;
  }
  &.full-width {
    margin: 0;
    max-width: 100%;
  }
}

.location-payment {
  background: @payment-darker-gray;
  max-width: @payment-modal-default-width;
  padding: 5px 30px 5px;
  text-align: center;
}

.half-and-half {
  display: flex;
  justify-content: space-between;

  .cell {
    flex: 0 0 47%;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.amount-with-buttons {
  > input[type='number'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #cfcfcf;
    border-radius: 0 0 4px 4px;
    background: @payment-darker-gray;
    border-top: none;
    flex-wrap: nowrap;
    overflow-x: scroll;

    .amount-button {
      flex: 0 0 30%;
      margin-right: 10px;
      label {
        border-radius: var(--button-border-radius);
        border: 1px solid #009a39;
        color: #009a39;
        background: #eefff5;
        text-align: center;
        padding: 5px 10px;
        margin: 0;
        transition: all 0.1s ease-in;
        font-weight: 800;
      }

      input:checked + label,
      input:focus + label,
      label:active {
        background: #009a39;
        color: @brand-white;
      }
    }

    .amount-button-sm {
      flex: 0 0 24%;
      margin-bottom: 3px;
      margin-right: 4px;

      label {
        border-radius: 100px;
        border: 1px solid #009a39;
        color: #009a39;
        background: #eefff5;
        text-align: center;
        padding: 5px 10px;
        margin: 0;
        transition: all 0.1s ease-in;
        font-weight: 600;
      }

      input:checked + label,
      input:focus + label,
      label:active {
        background: #009a39;
        color: @brand-white;
      }
    }

    &.count-1 {
      .amount-button {
        flex: 0 0 100%;
      }
    }

    &.count-2 {
      .amount-button {
        flex: 0 0 49%;
      }
    }

    &.count-4 {
      .amount-button {
        flex: 0 0 24%;
      }
    }

    &.count-8 {
      .amount-button {
        flex: 0 0 24%;
      }
    }
  }
}

/* Tweaking */
.mudal-payment {
  @media all and (min-width: @payment-ui-breakpoint) {
    header button {
      .header-font(36px);
    }

    &.wide {
      max-width: 660px;
      width: 100%;
    }

    &.wider {
      min-width: 860px;
    }
  }
}

.serbiaTandC {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  padding: 30px;
  z-index: 15;
  color: black;
  .content {
    margin-top: 40px;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 200px;
  }
}

.clip-overflow {
  overflow-y: clip;
}

.serbia-tc.error + label {
  color: red;
  span {
    border: 1px solid red;
  }
}

@media screen and (max-width: 495px) {
  #deposit-image.deposit-image-mercatavoucher_kiosk {
    content: url('/assets/img/devcode/mercatavoucher_kiosk_small.png');
  }
}
@media screen and (max-width: 420px) {
  #deposit-image.deposit-image-mercatavoucher_kiosk {
    content: url('/assets/img/devcode/mercatavoucher_kiosk_xsmall.png');
  }
}
