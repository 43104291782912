// Sports placeholders
.igc-spfe .betslip{
    max-height: ~"calc(100% - 120px)" !important;
}

.sportsbookpage {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.sportsBookIframe {
    &-wrapper {
        flex: auto;
        overflow-x: hidden;
        overflow-y: scroll;
        will-change: scroll-position;
        background-color: var(--sportsbook-wrapper-bg);
        position: relative;
    }
}

#sportsBookIframe-wrapper {
    overflow-y: hidden; /* Hide vertical scrollbar */
}

@media screen and (min-width: 1150px) {
    .sportsBookIframe, #sportsBookIframe-wrapper {
        height:95vh;
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 

    @media screen and (max-width: 380px){
        .sportsBookIframe {
            height: 78vh;
            padding-top: 6vh;
        }
    }

    @media screen and (orientation:portrait) and (max-width: 1150px) and (min-width: 381px) {
        .sportsBookIframe {
            height: 87vh;
            margin-top: 0vh;
            padding-top: 6vh;
            
            &.sportsbook-logged {
                height: 80vh;
                margin-top: 0vh;
                padding-top: 0;
            }
        }
    }


    @media screen and (orientation:portrait) and (max-width: 1150px) and (min-width: 420px) {
        .sportsBookIframe {
            height: 88vh;
            margin-top: 0vh;
            padding-top: 6vh;
            
            &.sportsbook-logged {
                height: 81vh;
                margin-top: 0vh;
                padding-top: 0;
            }
        }
    }
    @media screen and (orientation:landscape) and (max-width: 1150px) {
        .sportsBookIframe {
            height: 78vh;
            margin-top: 7vh;
            
            &.sportsbook-logged {
                height: 75vh;
                margin-top: 0vh;
                padding-top: 0;
            }
        }
    }
}

@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */ 
    @media screen and (orientation:portrait) and (max-width: 1150px) {
        .sportsBookIframe {
            height: 85vh;
            margin-top: 50px;
            
            &.sportsbook-logged {
                height:85vh;
                margin-top: 0vh;
            }
        }
    }
    @media screen and (orientation:landscape) and (max-width: 1150px) {
        .sportsBookIframe {
            height: 87vh;
            margin-top: 12vh;
            &.sportsbook-logged {
                margin-top: 0vh;
            }
        }
    }
}

#sportsBookMsg{
    color: var(--primary-color);
    width:100%;
    padding:80px 30px;
    text-align: center;
}

.results-container {
    height: 100vh;
    position: relative;

    & ~ footer {
    height: auto;
    position: relative;
    } 
}

.sports-all-content {
   .wrap {
        display: none;
    }
}

#loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 18;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .loader {
    border: 8px solid rgb(162,162,162, 0.5);
    border-top: 8px solid var(--sports-loader);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  