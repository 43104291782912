// Core variables and mixins
//@import "../../../node_modules/bootstrap/less/mixins.less";

// Reset and dependencies
//@import "../../../node_modules/bootstrap/less/normalize.less";

// Core CSS
//@import "../../../bower_components/bootstrap/less/scaffolding.less";
//@import "../../../bower_components/bootstrap/less/grid.less";
//@import "forms-override.less";


// Utility classes
//@import "../../../bower_components/bootstrap/less/utilities.less";

@import "glyphicons.less";
@import 'breakpoints';
@import "variables";

.mobile({
  .hidden-xs {
      display: none;
  }
});

::-ms-clear { display: none; }

body {
  font-size: 14px;
  line-height: 1.2;
  margin-left: @body-menu-offset;
  background:white;

  &.menu-stashed {
    margin-left: @body-menu-offset / 4;

    .side-menu {
      left: -@side-menu-width * 0.75;
    }
  }
  background: var(--body-background-color);
}

@import "global";
@import "colors";
@import "bootstrap-override";
@import "mixins";
@import "typography";
@import "loader";
@import "header";
@import "new-mobile-nav";
@import "footer";
@import "footer-old";
@import 'footer-old-v2';
@import 'footer-betsson';
@import "insider";
@import "blog";
//@import "carousel";
@import "casino-games";
@import "general";
@import "gameview";
@import "casino-navigation-desktop";
@import "progressbar";
@import "modal";
@import "forms";
@import "register";
@import "payments";
@import "rewards";
@import "account";
@import "responsible-gaming";
@import "buttons";
@import "static-pages";
@import "about-wor";
@import "notification";
@import "cave";
@import "livechat";
@import "icons";
@import "highlight-box";
@import "race";
@import "add-to-homescreen";
@import "inbox";
@import "cta";
@import "junegame";
@import "sport-coming-soon";
@import "sports"; 
@import "cookie-reset";
@import "bankid-auth";
@import "panic-bar";
@import "cashier";
@import "bonus-content";
@import "site-index";
@import "rafflepage";
@import "vip-cashback";

* {
    box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

img {
  vertical-align: middle;
}

.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.show {
  display: block !important;
}
.hidden {
  display: none !important;
}

html {
  font-size: 14px;
  height:100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.fullwidth {
  width: 100%;
}

#rizk-all:not(.rizk-all-no-style) {
  visibility:hidden;
  opacity:0;
  transition: opacity 0.3s linear;
}

// Unordered and Ordered lists
ul,
ol {
  margin: 0;
  list-style: none;
  padding: 0;
}

ol {
  list-style: decimal;
  margin-left: 20px;
}

hr {
  border: none;
  height:1px;
}

iframe {
  border:0;
}

.invisible{
  visibility: hidden;  
}

// Links
a {
  color:black;
  cursor: pointer;
  text-decoration: none;
}

a:focus {
  outline:0;
  //.tab-focus();
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.absolute-inset {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loader-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader-app {
  border: 8px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  border-top: 8px solid #00c147;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
