@lb-entry-h: 32px;

.gameview-race-container {
  > p {
    margin: 8px 0 0 0;
  }

  .race-individual {
    height: fit-content;
    display: none;
    max-height: 100vh;
    align-self: flex-end;

    .race-collapse {
      display: none;
      cursor: pointer;
    }
    .race-col {
      height: 100vh;
    }
  }

  flex-direction: column;
  display: flex;
}

.gv-race-upper {
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(69, 69, 69, 0), rgba(69, 69, 69, 0.64));
  padding-bottom: 16px;

  img {
    //margin-top:16px;
    padding: 16px;
    max-width: 80%;
    max-height: 128px;
  }

  p {
    margin: 0;
  }
}

.gv-race-yellow {
  padding: 16px;
  margin-bottom: 16px;
  background: var(--tertiary-color);
  color: black;
  font-size: 16px;

  .race-gv-big {
    color: black;
  }

  b {
    text-transform: uppercase;
    display: block;
    margin: 16px 0 8px;
  }
}

.gv-race-inner > h3 {
  padding-left: 16px;
}

.gv-race-inner > p {
  text-align: center;
}

.btn-container {
  padding: 0 16px;
}

.race-gv-big {
  color: var(--tertiary-color);
  font-size: 42px;
  font-weight: 700;
  margin-top: -8px;
}

.race-show-more {
  height: 48px !important;
  padding: 0 32px;
  margin: 32px auto;
  display: block;
  background: transparent;
  border: 2px solid #a3a3a3;
  color: #a3a3a3;
}

.race-inner {
  max-width: 1088px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  .padded-race-img {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    width: unset;
    top: 8px;
  }

  &__top-image {
    margin-bottom: 30px;
    height: inherit;
  }

  #history-page > h2,
  > div > div > h2 {
    padding-left: 16px;
  }

  #info-page > h2,
  > div > div > h2 {
    padding-left: 16px;
  }

  #race-page > p {
    padding-left: 16px;
  }
}

.rp-pos {
  font-weight: 600;
  border-radius: 50%;
  background: @brand-white;
  color: @brand-black;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
}

.pos-1 {
  background: #ffc845;
}

.pos-2 {
  background: #dfe1e3;
}

.pos-3 {
  background: #dfa47f;
}

.rp-prize {
  font-weight: 600;
  line-height: 24px;
  margin-left: 8px;
  text-align: right;
}

.race {
  //border:1px solid white;
  background-color: var(--secondary-body-background-color);
  margin: 16px;
  padding: 0 0 16px 0;
  text-align: left;
  vertical-align: top;
  border-radius: 4px;
  color: var(--primary-color);

  > header {
    font-size: 16px;
    padding: 4px 0;
    color: var(--primary-color);
    background-size: cover;
    background-position: center center;
    //text-align: center;
  }

  > img {
    max-width: 175px;
  }

  &.yellow {
    background: var(--tertiary-color);
    color: @brand-black;

    .race-info-tbl {
      color: @brand-black;

      ul li {
        background: rgba(255, 255, 255, 0.36);

        a {
          color: @brand-black;
        }
      }
    }
  }
}

.race.yellow .race-footer .details > div {
  background: rgba(255, 255, 255, 0.32);
}

.race-footer {
  //font-size: 16px;
  font-weight: 600;

  .race-small & {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .opt-in-button {
      flex-grow: 3;
      background: none;
    }

    .details {
      display: flex;
      flex-wrap: wrap;

      > div {
        padding: 6px;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.16);
        text-align: center;
        flex: 1 27%;
        margin: 2px;
      }

      .main-prize {
        flex: 1 100%;
        display: flex;
        justify-content: space-between;
        padding: 6px;
        margin-bottom: 14px;
      }

      .show-all {
        cursor: pointer;
        margin: 14px 2px;

        &:hover {
          color: var(--tertiary-color);
        }
      }
    }
  }

  h3 {
    margin: 0;
    font-size: 12px;
    font-weight: 300;
  }

  .rizk-btn {
    margin: 0;
    width: 100%;
  }

  .race-small.yellow & {
    .show-all:hover {
      color: var(--brand-green);
    }
  }
}

.race-small {
  width: 240px;
  padding-bottom: 0;

  .history-date {
    font-size: 12px;
    background: @brand-white;
    color: @brand-black;
  }

  header {
    .header-font(20px);

    height: 93px;
    color: var(--tertiary-color);
    letter-spacing: 0.8px;

    background-position: center center;
    background-size: cover;

    h3 {
      margin: 10px 0 0 0;
    }
  }

  p {
    font-size: 32px;
    font-weight: bold;
    margin: 0;
    color: var(--primary-color);
  }

  p,
  header {
    text-align: center;
  }

  .trophy-img {
    display: block;
    margin: auto;
  }

  button {
    display: block;
    width: 100%;
    margin: 8px 0;
    padding: 4px;
  }

  .show-all {
    cursor: pointer;
  }

  .race-footer {
    padding: 14px;
  }
}

.top-race-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.history-race-container,
.future-race-container {
  display: flex;
  flex-flow: row wrap;

  .title {
    flex: 1 0 100%;
  }
}

.race-big header .starts-today-tomorrow {
  display: none;
}

.big-daily-race-container,
.big-race-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  > .title {
    flex: 0 0 100%;
    padding-left: 16px;
  }

  @media all and (min-width: 1140px) {
    width: 50%;
  }
}

/* Two column current/next and two priority races */
@media all and (min-width: 1140px) {
  .big-race-container {
    .race-big > header {
      height: 178px;
    }
  }

  .big-daily-race-container {
    .race-big header .starts-today-tomorrow {
      display: block;
    }

    .race {
      display: flex;
      flex-flow: inherit;
      justify-content: space-between;
      position: relative;
      padding: 0;

      > header {
        height: 224px;
        flex: 0 0 50%;
        //flex: 0 0 220px;
        padding: 0;
        margin: 0;
      }

      .starts-today-tomorrow {
        .header-font(18px);

        text-align: center;
        margin: auto;
        width: 60%;
        background: @brand-white;
        color: @brand-black;
        text-transform: uppercase;
        line-height: 26px;
        letter-spacing: 1px;
      }

      .info-tbl {
        flex: 0 0 50%;
        margin-top: 12px;

        .race-info-tbl {
          max-width: 100%;

          .show-all .show-all-prizes {
            margin-right: 0;
          }
        }
      }

      .race-footer {
        position: absolute;
        bottom: 4px;
        right: 0;
        width: 50%;

        .opt-in-button button {
          height: auto;
          padding: 6px;
        }
      }

      .race-prizes > header,
      .race-info {
        display: none;
      }
    }
  }
}

.race-countdown {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
}

.race-big {
  flex: 1;
  max-width: 512px;
  min-width: 320px;

  .race-lb {
    margin: 24px 16px 0;
  }

  .race-dot {
    margin: 2px 42px;
  }

  header h2 {
    .header-font(32px);

    line-height: 32px;
    text-align: center;
    color: var(--tertiary-color);
    letter-spacing: 0.8px;
    margin: 32px 0 0 0;
  }

  h2,
  header > div {
    display: block;
  }

  > header {
    height: 160px;
    width: 100%;
    margin: 0 0 16px 0;
  }

  .info-tbl {
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;

    .race-info-tbl {
      ul li {
        line-height: 24px;

        > :first-child {
          margin-right: 15px;
        }

        :last-child {
          text-align: right;
          //white-space: nowrap;
          //overflow: hidden;
          //text-overflow: ellipsis;
          font-weight: 600;
        }

        &.show-all {
          cursor: pointer;
        }
      }

      & a {
        color: @brand-white;
      }
    }
  }

  .trophy-img {
    display: block;
    margin: 42px auto 34px auto;
  }

  .race-footer {
    .opt-in-button {
      display: block;
      margin: 16px 16px 8px 16px;

      button {
        height: 48px;
        display: block;
        margin: auto;
      }
    }
  }

  @media all and (min-width: 460px) {
    .info-tbl {
      flex-wrap: nowrap;
      justify-content: space-between;

      .race-info-tbl {
        max-width: 49%;
      }
    }
  }

  @media all and (min-width: 679px) {
    width: 48%;
    min-width: 460px;
  }
}

.race-games {
  margin: 14px 0;
  display: flex;
  justify-content: space-around;

  li {
    display: inline-block;
    cursor: pointer;

    img {
      border-radius: 50%;
    }
  }
}

.lb-poscont {
  position: absolute !important;
  //width:100%;
  text-align: center;
  z-index: 1;
  //padding-left:16px;

  li {
    font-weight: 600;
    position: relative;
    height: 24px !important;
    margin: 4px 0 16px 16px;
    color: black;
    line-height: 24px;
    border-radius: 50%;
    background: white;
    width: 24px !important;
  }
}

.race-meters {
  text-align: left;
  color: white;
  font-size: 14px;
  font-weight: 600;

  .right {
    float: right;
  }
}

.race-user-entry-stats {
  padding: 0 16px;

  .indicator-container {
    position: relative;
    height: 4px;
    border: 0;
    border-radius: 0;
    margin: 4px 0 20px;
  }

  .rizk-progress-black {
    background: grey;
  }

  .item-list {
    display: inline-block;
    position: absolute;

    li {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .streak-label {
    margin-bottom: 2px;
  }
}

.race-user-entry-stats p {
  display: block;
  margin: 4px 0;
}

.lb-list {
  //margin-left: 32px;
}

.lb-entry {
  transition: all 0.25s ease;
  //padding-left: 48px;
  width: 208px;
  position: absolute;
  background: rgba(255, 255, 255, 0.08);
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;

  p {
    display: inline-block;
    padding-left: 48px;
    flex-grow: 2;
    line-height: @lb-entry-h;
    height: @lb-entry-h;
    margin: 0;
  }

  .pos {
    padding-right: 4px;
  }
}

.my-pos {
  width: 100%;
  margin: 0px 16px 8px 32px;
  padding-top: 4px;
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);

  span {
    text-align: right;
    float: right;
  }

  div {
    display: inline;
  }
}

.race-lb {
  color: #e6e6e6;
  font-size: 14px;
  margin-top: 16px;

  .user-entry p {
    color: var(--tertiary-color);
  }

  header {
    display: block;
    //background-color: @brand-red;
  }

  ul {
    display: block;
    //height: 250px;
    overflow: hidden;
    position: relative;
  }

  li {
    width: 100%;
    justify-content: space-between;
    height: @lb-entry-h;
    user-select: none;
    cursor: default;
  }

  .points {
    font-weight: 600;
    text-align: right;
    padding-right: 16px;
  }

  .name {
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.race-user-entry {
  margin-top: 12px !important;
  margin-bottom: 16px;

  .lb-entry {
    position: relative;
    //color: @brand-yellow;
    width: 100%;
    padding-left: 16px;
    margin: 0;

    .name {
      padding: 0 0 0 32px;
    }
  }

  background: rgba(255, 255, 255, 0.08);

  p {
    display: inline-block;
    margin: 0;
    //padding-left: 16px;
  }
}

.user-entry-pos {
  border-radius: var(--button-border-radius);
  background: var(--tertiary-color);
  width: 24px;
  position: absolute;
  height: 24px;
  margin: 4px 0 4px 0px;
}

.race-dot {
  width: 4px;
  height: 4px;
  opacity: 0.16;
  background-color: #ffffff;
  margin: 2px 26px;
  border-radius: 50%;
}

.user-entry-dot {
  background: black;
  width: 6px;
  left: 25px;
  top: 13px;
  border-radius: 50%;
  z-index: 1;
  height: 6px;
  position: absolute;
}

.lb-meters {
  height: 32px;
  margin: 0 0 12px 0;
  //padding-left: 16px;
}

.winmeter-rizk {
  background-image: url('/assets/img/meter-star-rizk.svg');
  opacity: 0.3;
}

.winmeter-inkabet {
  background-image: url('/assets/img/meter-star-inkabet.svg');
  opacity: 0.3;
}

.winmeter-rizk.active,
.lossmeter-rizk.active,
.winmeter-inkabet.active,
.lossmeter-inkabet.active {
  opacity: 1;
}

.lossmeter-rizk {
  background-image: url('/assets/img/meter-skull.svg');
  opacity: 0.3;
}

.lossmeter-inkabet {
  background-image: url('/assets/img/meter-skull-inkabet.svg');
  opacity: 0.3;
}

.race-italic {
  .league-italic;
  letter-spacing: 1px;
  margin-bottom: 8px;
}

.race-info-tbl {
  display: inline-block;
  list-style: none;
  text-align: left;
  width: 100%;
  color: white;

  .yellow & {
    color: @brand-black;
  }

  header {
    margin: 8px 0;
    font-size: 16px;

    img {
      width: 50%;
    }
  }

  ul li {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.16);
    margin-bottom: 8px;
    padding: 4px 16px;

    &.show-all {
      cursor: pointer;
    }
  }
}

.single-race-modal {
  display: inline-block;
  position: relative;
}

.race-nav {
  margin-bottom: 20px;
  border-bottom: 1px solid #393939;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;

  ul {
    display: flex;
    margin-bottom: 20px;

    li {
      margin-right: 20px;

      a {
        color: #797979;

        letter-spacing: 0.4px;
        user-select: none;

        .header-font(24px);

        &:hover,
        &.active {
          color: var(--tertiary-color);
        }

        h1 {
          .font-size(24px);
        }
      }
    }
  }
  img {
    width: 22px;
    height: 22px;
  }
}

.race-page {
  padding-bottom: 20px;
}

.info-page-content {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.6;
  p {
    color: var(--secondary-color-button);
  }
}

.race-rounded {
  border-radius: 15px;
}

.race-info-block {
  flex: 1 366px;
  max-width: 100%;
  padding: 16px;

  img {
    width: 100%;
  }

  p {
    font-size: 18px;
  }

  h1 {
    .header-font(40px);

    color: var(--tertiary-color);
    word-wrap: break-word;
  }

  .race-h2 {
    .header-font(40px);

    text-transform: uppercase;
    color: var(--tertiary-color);
    word-wrap: break-word;
    margin-top: 20px;
  }

  h3 {
    color: var(--secondary-color-button);
  }

  ul {
    list-style: disc outside;
    margin-left: 16px;
    color: var(--secondary-color-button);
    font-size: 18px;

    li {
      padding: 0 0 8px 0;
    }
  }

  .info-page-back-button {
    display: flex;
    justify-content: center;
    a.rizk-btn {
      color: inherit;
      padding: 10px 60px;
      border: 2px solid transparent;
      color: var(--races-color-button);
    }
  }
}

.info-points {
  p {
    color: var(--secondary-color-button);
    margin: 0 0 24px 0;
  }

  .info-points__points {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: var(--tertiary-color);
    margin: 8px 0;

    img {
      width: 16px;
      height: 16px;
      display: inline;
      vertical-align: text-top;
    }
  }
}

.race-help {
  position: absolute;
  pointer-events: none;
  width: 100%;
  right: 264px;
  border-radius: 4px;
  max-width: 256px;
  z-index: 1;
  background-color: white;
  text-align: left;
  padding: 8px;
  display: none;

  h2 {
    font-size: 14px;
    font-weight: 600;
  }
}

#race-points-help {
  top: 64px;
}

#race-spins-help {
  top: 128px;
}

#race-streak-help {
  top: 216px;
}

#racer-tooltip {
  top: 333px;
}

.race-optin {
  border: 2px solid transparent;

  &.disabled {
    background: transparent;
    opacity: 1;
    border: 2px solid #a3a3a3;
    color: #a3a3a3;
  }
}

.race-auto-optin {
  min-width: 300px;
}

.pb-green {
  background: #009a39;
}

#gameview-races {
  margin-top: 30px;

  header {
    margin: 8px 16px;
  }

  .race-lb h3 {
    margin-left: 16px;
  }
}

#race-name {
  color: #ffe500;
  font-size: 27px;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}

.race-game-hl {
  background: rgba(255, 255, 255, 0.32) !important;
  cursor: pointer;
}

@media all and (max-width: 679px) {
  .race-info-block img {
    display: none;
  }
}

.race-top-banner {
  display: flex;
  .base-img {
    width: 100%;
    height: 153px;
    background-color: var(--primary-bg-color);
    margin-bottom: 21px;
    object-fit: cover;
    margin: auto;
  }
}

@media all and (max-width: 1150px) {
  .race-top-banner {
    .base-img {
      object-fit: cover;
      background-color: var(--primary-bg-color);
      &:not(.betsson) {
        max-width: 435px;
      }
    }
  }
}

.race-col-container {
  width: 100%;
  flex-direction: row;
  background-color: var(--races-col-container-bg);
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .race-collapse {
    display: none;
    cursor: pointer;
  }
}

.race-col-timer {
  height: 51px;
}

.race-history-container {
  width: 100%;
  flex-direction: row;
  background-color: var(--primary-bg-color);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  .race-optin,
  .race-collapse {
    display: none;
    cursor: pointer;
  }
  .race-col-race-img {
    cursor: unset;
  }
  .start-time {
    color: var(--tertiary-color);
  }
  .current-user {
    background: lightcoral;

    .current-points {
      display: none !important;
    }
  }
}

.gameview-wrapper,
.race-history-container,
.lobby-main-content-child,
.race-upcoming {
  .race-individual__overlay {
    display: none;
  }
}

.race-col {
  background-color: var(--races-col-bg);
  height: inherit;
}

.race-individual {
  width: calc(100% - 750px);
  min-width: 340px;
  display: none;
  position: relative;

  &.raceClone {
    display: block;
  }

  .streak-info {
    align-self: flex-end;
    justify-self: flex-end;
  }

  .streak-info .tooltiptext {
    visibility: hidden;
    width: 80%;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    text-align: center;
    border-radius: 6px;
    padding: 5px 15px;
    top: 90px;
    right: 25%;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    @media screen and (max-width: 1000px) {
      width: 65vw;
    }
  }

  .streak-info:hover .tooltiptext {
    visibility: visible;
  }

  .streak-info .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -1vh;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent var(--secondary-color);
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--overlay-background);
    z-index: 1;

    svg {
      width: 100px;
      overflow: visible;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      circle {
        stroke: var(--tertiary-color);
        transform-origin: 50px 50px;
        animation: spin 1.2s linear infinite;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.race-col-container {
  .race-individual {
    display: block;
  }
}

.race-col-img,
.race-widget-img {
  width: 100%;
  height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  .start-time {
    font-weight: 700;
    font-size: 20px;
    color: white;
  }

  .race-collapse {
    position: absolute;
    top: 10px;
    right: 15px;
    justify-content: center;
    color: var(--primary-color);
    cursor: pointer;
  }
}

.race-col-name {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--primary-color);
  margin: 10px 20px 10px;
  padding-bottom: 4px;
  display: block;
  border-bottom: 1px solid #ffffff42;
}

.race-col-details {
  width: 100%;
  height: 180px;
  border-bottom: 1px solid var(--secondary-border-color);
  padding: 5px;
  display: flex;
  color: var(--primary-color);
}

.race-col-race-img-container {
  flex-wrap: wrap;
  display: flex;
  width: 50%;
  min-width: 50%;
  height: 100%;
  padding: 15px;
  text-align: center;
  justify-content: center;
  color: white;
}

.race-col-race-img-container.modal {
  width: unset;
  max-height: 450px;

  .race-game {
    margin: 5px;
    max-width: min-content;
    position: relative;

    .race-game-name {
      font-size: small;
      text-align: center;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--primary-color);
    }
  }
}

.race-col-race-img {
  .modal {
    max-height: 450px;
  }
  width: 45%;
  height: 45%;
  max-width: 61px;
  margin-right: 5px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.race-modal-race-img {
  width: 150px;
  height: 150px;
  margin-right: 5px;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.race-col-race-details {
  display: flex;
  flex-direction: column;
  margin: 5px;
  .item-list {
    display: inline-block;

    li {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  #race-prize,
  #race-minbet,
  #race-spins,
  #race-duration {
    font-size: 16px;
  }
  #race-prize {
    font-weight: 700;
    color: var(--quaternary-color-button);
  }
}

.race-col-race-optin-container {
  width: 100%;
  display: flex;
  color: var(--primary-color);
  flex-direction: column;
  padding: 10px;
  .race-start-time {
    color: var(--primary-color);
  }
}

.race-start-time {
  display: flex;
  flex-direction: column;
  margin: 5px;
  color: white;
  text-align: center;
  .start-time {
    font-size: 16px;
  }
}

.race-col-race-optin-details {
  display: flex;
  justify-content: space-around;

  .race-col-race-optin-details-optedin {
    margin: 5px;
    align-self: center;
    display: flex;
    flex-direction: row;

    @keyframes glow {
      from {
        box-shadow: 0 0 1px -1px green;
      }

      to {
        box-shadow: 0 0 1px 1px green;
      }
    }
  }
}

.race-col-race-leaderboard-container {
  width: 100%;
  display: none;
  color: var(--primary-color);
  flex-direction: column;
  background-color: var(--secondary-body-background-color);
  padding-bottom: 9px;

  #race-leaderboard,
  #race-prizes {
    display: none;
  }
  .current-user {
    max-height: 50px;
    background: lightcoral;
    .current-points {
      display: flex;
    }

    &__active {
      max-height: 266px;
      min-height: 266px;
    }
  }

  .race-widget-race-leaderboard {
    display: flex;
    justify-content: space-around;
    background: #3b3b3b;
    border-radius: 5px;
    padding: 5px;
    height: 35px;
    margin: 5px 10px;
    align-items: center;
  }
}

.race-col-race-leaderboard-nav {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid var(--secondary-border-color);
  border-top: 1px solid var(--secondary-border-color);
  height: 41px;
  font-size: 14.2px;
  text-transform: uppercase;
  position: relative;

  .tablink {
    text-align: center;
    margin: 10px 5px 0 5px;
    width: 125px;
    cursor: pointer;
    color: var(--quaternary-color-button);
    display: flex;
    justify-content: center;
  }

  hr {
    width: 125px;
    border-bottom: 2px solid var(--tertiary-color);
    height: 0px;
    position: absolute;
    bottom: -8px;
  }
}

.race-col-race-leaderboard-item {
  display: none;
  justify-content: space-around;
  background: var(--races-col-bg);
  border-radius: 5px;
  padding: 5px;
  max-height: 35px;
  margin: 5px 10px;
  align-items: center;
  flex: 1;
  justify-content: center;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  &flex1 {
    flex: 1;
    margin-right: 5px;
  }

  &flex2 {
    flex: 2;
    min-width: 0px;
    margin-right: 5px;
    .max-points,
    .current-points {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }
    }
    .current-points {
      border-top: 1px solid white;
      display: none;
    }
  }

  &flex3 {
    flex: 3;
  }

  .img {
    object-fit: scale-down;
    height: 20px;
  }
}

.race-col-race-upcoming {
  width: 100%;
  color: var(--tertiary-color);
  margin-top: 50px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--secondary-border-color);
  padding: 10px 0;
  font-size: 20px;
}

.race-col-race-upcoming-item {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px;
  min-height: 43px;
  width: 100%;
  align-items: center;
  color: var(--primary-color);
  font-size: 15px;
  gap: 5px;

  .prize {
    text-align: center;
  }

  &__name {
    display: flex;
    justify-self: flex-start;
    text-align: left;
    min-width: 145px;
  }

  &__expand {
    display: flex;
    justify-content: center;
    cursor: pointer;
    max-width: 15px;
  }

  & > div {
    flex: 1 1 0px;
  }
}

.race-col-race-upcoming-container {
  max-height: fit-content;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  .race-col-race-img {
    width: 60px;
    height: 60px;
    max-width: 60px;
    margin-right: 5px;
  }

  .race-col-race-upcoming-item-container {
    display: flex;

    &--hide {
      display: none;
    }

    &:nth-child(-n + 12) {
      display: flex;

      .race-col-race-upcoming-item {
        display: flex;
      }
    }
    &:first-child {
      display: none;
    }
  }

  &--board {
    max-height: inherit !important;
    height: inherit;
  }

  .board {
    min-height: inherit;
  }

  .race-col {
    overflow-y: hidden;
  }

  .show-more {
    display: none;
    cursor: pointer;
    background: var(--secondary-body-background-color);
  }
}

.race-col-race-upcoming-item-container:nth-child(odd) {
  background: var(--secondary-body-background-color);
}

.race-widget {
  width: 100%;
  background: var(--secondary-body-background-color);
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  .rizk-btn {
    cursor: pointer;
    min-width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .race-col-container,
  .race-history-container,
  .race-col-race-upcoming-item-container {
    flex-direction: column;
    align-items: center;

    .race-individual {
      margin-bottom: 25px;
      height: unset;
    }

    .race-col {
      overflow-y: hidden;
    }

    .board {
      max-height: 180px;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }
    .user_in_limit {
      min-height: 196px;
    }
  }

  .race-col-race-upcoming-item-container {
    .race-individual {
      margin-bottom: 0px;
    }
  }

  .race-individual {
    width: 100%;
    overflow-y: hidden;
  }

  .race-inner {
    padding: 0 20px;
  }
  .race-modal-race-img {
    width: 100px;
    height: 100px;
  }
  .race-col-race-upcoming-item {
    font-size: 14px;
    .prize-placeholder {
      text-align: center;
    }
  }
  .race-history-container {
    .user_in_limit {
      min-height: unset;
    }
  }
}

@media screen and (min-width: 1000px) {
  .race-top-image-sr {
    height: inherit;
    width: 63%;
    margin: 3% auto 30px;
  }

  .board {
    max-height: 246px;
    min-height: 246px;
    overflow-y: scroll;
  }

  .gameview-race-container {
    .board {
      max-height: calc(100vh - 500px);
      height: calc(100vh - 500px);
      position: relative;
    }

    .current-user {
      position: sticky;
      bottom: 5px;
    }
  }

  .race-col-race-upcoming-item {
    padding: 5px 20px;

    &__image {
      width: 15px;
      height: 15px;
    }

    &__day,
    &__time {
      max-width: 180px;
    }
  }

  .race-history-container {
    .race-individual {
      margin-bottom: 25px;
    }
  }
}

.show-more,
.upcoming-show-more,
.upcoming-show-less {
  height: 50px;
  width: 100%;
  background: var(--secondary-body-background-color);
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upcoming-show-less,
.upcoming-show-more {
  display: none;
}

.arrow {
  border: solid var(--primary-color);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin: 28px 10px 20px;

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: 18px 10px 20px;
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

.race-widget {
  .race-widget-container {
    display: flex;
    height: auto;
    background: var(--secondary-body-background-color);
    border-radius: 5px 5px 0 0;
  }

  .race-widget-optedin {
    width: 100%;
    height: 100%;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .race-widget-data-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 14px;
    color: var(--primary-color);
  }

  .race-widget-data {
    display: flex;
    color: var(--primary-color);
    justify-content: space-between;
  }

  .race-widget-data-entry {
    display: flex;
    margin: 15px 25px 15px 0;
    flex-direction: column;
    #race-prize,
    #race-minbet,
    #race-spins,
    #race-duration,
    .start-time {
      font-size: 16px;
    }
    #race-prize {
      font-weight: 700;
      color: var(--quaternary-color-button);
    }
  }

  .race-widget-game-thumbnail {
    background-color: #aaa;
    width: 50px;
    height: 50px;
    margin-right: 5px;
    border-radius: 5px;
  }

  .race-widget-game-thumbnail-container {
    display: flex;
    flex: 2;
    text-align: center;
    color: white;
  }

  .race-widget-img-optin-container {
    display: flex;
    flex-direction: column;
  }

  .race-widget-img {
    width: 308.95px;
    height: 100%;
    flex: 2;
    border-radius: 5px 0 0 0;
  }

  .race-widget-race-leaderboard-item {
    justify-content: center;
    display: flex;

    &flex1 {
      flex: 1;
    }

    &flex2 {
      flex: 2;
    }

    &flex3 {
      flex: 3;
    }
  }

  .race-widget-race-name-container {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 18px;
    }
    .race-collapse {
      cursor: pointer;
    }
  }

  hr {
    display: none;
    width: 350px;
    border-bottom: 2px solid var(--tertiary-color);
  }

  .tablink {
    flex: 1;
  }

  .race-widget-race-optin-details-optedin {
    display: flex;
    flex-direction: row;
    color: var(--primary-color);
  }
}

.circle {
  margin: 5px 10px 5px 0;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}

#race-games-modal {
  position: relative;
  height: unset;
  opacity: unset;
  right: unset;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  .search-modal-top {
    background: inherit;
    padding-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .close-search {
    align-self: end;
    margin: 10px 20px;
    cursor: pointer;
    color: var(--primary-color);
  }
  .results-title {
    color: var(--primary-color);
  }

  .race-optin {
    width: 70%;
    display: none;

    &.show {
      display: block;
    }
  }

  .race-col-race-img-container {
    overflow: scroll;
    @media screen and (min-width: 1000px) {
      overflow: inherit;
    }
  }
}
.race-collapse {
  justify-content: center;
  margin-right: 4px;
}
.rizk-btn[disabled] {
  cursor: unset;
  pointer-events: none;
}
.race-info-explain {
  .header-font(24px);

  margin: 32px 0 0 0;
  text-transform: uppercase;
  color: var(--secondary-color-button);
}

.rizk-btn {
  cursor: pointer;
}

.race-opted-in,
.race-opted-in-mob {
  width: auto;
  padding: 0 5px;
  color: var(--secondary-color);
  text-align: right;
  background: var(--tertiary-color);
  position: absolute;
  z-index: 0;
  font-weight: 600;
  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    background: inherit;
    border-radius: inherit;
    border-bottom-left-radius: 0;
    transform-origin: top left;
  }
  &::before {
    left: 0;
    right: 20%;
    transform: skewX(-15deg);
  }
  &::after {
    z-index: -2;
    left: 0px;
    height: 105%;
    width: 10px;
    -webkit-filter: brightness(0.7);
    filter: brightness(0.7);
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(10deg);
  }
}

.race-opted-in {
  font-size: small;
  left: -2%;
  top: 13%;
}
.race-opted-in-mob {
  z-index: 2;
  font-size: smaller;
}

.race-individual {
  .race-opted-in-mob {
    left: 7px;
    top: 23px;
  }
}
.prize_for_user {
  background-color: lightcoral;
}
