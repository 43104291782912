.navi-arrow {
  // top: 0px;
  transition: all .3s ease-in-out;
  opacity:0;
  position:absolute;
  width:80px;
  height:190px;
  z-index:6;
  text-align:center;
  cursor:pointer;
  transform:translate3d(0,0,0);
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */    
}

.navi-arrow img {
  width:64px;
  height:48px;
  margin-top: 71px;
  pointer-events:none;
}



.navi-arrow-left img, .navi-arrow-races-left img {
  transform:rotate(180deg);
}

.navi-arrow-left, .navi-arrow-races-left {
left:0px;
background: linear-gradient(to right,  var(--primary-bg-color) 0%, rgba(0,0,0,0) 100%,);
}

.navi-arrow-right, .navi-arrow-races-right {
right:0px;
background: linear-gradient(to right,  rgba(0,0,0,0) 0%,var(--primary-bg-color) 100%);
}

.navi-arrow-races-left img, .navi-arrow-races-right img{
  margin-top: 66px;
}
.navi-arrow-races-right{
  right: 30px;
}
.navi-arrow-races-left{
  left: 20px;
}
#race-right-arrow, #race-left-arrow{
  height: 200px;
}

.readmore {
  position:absolute;
  width:188px;
  height:32px;
  left:0px;
  bottom: 16px;
  background: url("../img/show-more-button.svg") no-repeat center;
  background-size:contain;
  opacity:0;
  z-index:20;
  cursor:pointer;
  transition: all .3s ease-in-out;
  backface-visibility: hidden;
}

.readmore:hover {
  transform:scale(1.2);
}

