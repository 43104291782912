.coming-soon .all-content {
    background-image: url("/assets/img/coming-soon.jpg");
    background-repeat: no-repeat;
    background-size: contain;
}

.sport-coming-soon {
    text-align: center;
    padding: 40px;
    padding-bottom: 80px;
    max-width: 680px;
    margin: 0 auto;
    color: @brand-white;
    h1 {
        text-transform: uppercase;
        .gibson-bold;
        font-size: 100px;
        line-height: .9;
        margin-bottom: 40px;
        @media (max-width: 820px) {
            font-size:46px;
            max-width: 480px;
            margin: 0 auto;
        }
    }
    h3 {
        .gibson-semi-bold;
        margin-bottom: 0;
        line-height: 1.2;
    }
    .btn-wide {
        width: 40%;
        margin: 20px auto;
        margin-top: 50px;
    }
    .form-wrap {
        text-align: left;
    }
    label,
    input,
    ::placeholder {
        color: @brand-white;
    }
    input {
        margin-bottom: 15px;
    }
    .m-b-2 {
        margin-bottom: 20px;
    }
    .m-b-4 {
        margin-bottom: 40px;
    }
    .m-b-5 {
        margin-bottom: 50px;
    }
    iframe {
        text-align: left;
    }
}