/**
 * Currently there is only Football articles in Sports, so when others appear, need to check the corresponding icon
 * for the translated category name. Check out the .filter-football below...
 */

@font-face {
    font-family: "category-filter";
    src: url("fonts/category-filter-icons/category-filter.eot?9uazlg");
    src: url("fonts/category-filter-icons/category-filter.eot?9uazlg#iefix") format("embedded-opentype"), url("fonts/category-filter-icons/category-filter.ttf?9uazlg") format("truetype"), url("fonts/category-filter-icons/category-filter.woff?9uazlg") format("woff"), url("fonts/category-filter-icons/category-filter.svg?9uazlg#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="insider-filter-"],
[class*=" insider-filter-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "category-filter" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.insider-filter-fussball:before,
.insider-filter-fotball:before,
.insider-filter-football:before,
.insider-filter-fotboll:before,
.insider-filter-ball-soccer:before,
.insider-filter-jalkapallo:before {
    content: "\e905";
}

.insider-filter-tennis:before {
    content: "\e92a";
}

.insider-filter-alpine-skiing:before {
    content: "\e900";
}

.insider-filter-athletics:before {
    content: "\e901";
}

.insider-filter-aussie-rules:before {
    content: "\e902";
}

.insider-filter-badminton:before {
    content: "\e903";
}

.insider-filter-ball-basket:before {
    content: "\e904";
}

.insider-filter-bandy:before {
    content: "\e906";
}

.insider-filter-baseball-bat:before {
    content: "\e907";
}

.insider-filter-beach-soccer:before {
    content: "\e908";
}

.insider-filter-beach-volley:before {
    content: "\e909";
}

.insider-filter-biathlon:before {
    content: "\e90a";
}

.insider-filter-bowls:before {
    content: "\e90b";
}

.insider-filter-combat-sports:before,
.insider-filter-kamppailulajit:before,
.insider-filter-kampsport:before,
.insider-filter-kampfsport:before {
    content: "\e90c";
}

.insider-filter-call-of-duty:before {
    content: "\e90d";
}

.insider-filter-cod:before {
    content: "\e90e";
}

.insider-filter-counter-strike:before {
    content: "\e90f";
}

.insider-filter-cricket:before {
    content: "\e910";
}

.insider-filter-curling:before {
    content: "\e911";
}

.insider-filter-dart:before {
    content: "\e912";
}

.insider-filter-dota-2:before {
    content: "\e913";
}

.insider-filter-field-hockey:before {
    content: "\e914";
}

.insider-filter-figure-skating:before {
    content: "\e915";
}

.insider-filter-floorball:before {
    content: "\e916";
}

.insider-filter-football-headguard:before {
    content: "\e917";
}

.insider-filter-futsall:before {
    content: "\e918";
}

.insider-filter-golf:before {
    content: "\e919";
}

.insider-filter-handball:before {
    content: "\e91a";
}

.insider-filter-helmet:before,
.insider-filter-motorsport:before,
.insider-filter-moottoriurheilu:before {
    content: "\e91b";
}

.insider-filter-hockey:before,
.insider-filter-ishockey:before,
.insider-filter-jaakiekko:before {
    content: "\e91c";
}

.insider-filter-home:before {
    content: "\e91d";
}

.insider-filter-league-of-legend:before {
    content: "\e91e";
}

.insider-filter-nordic-combined:before {
    content: "\e91f";
}

.insider-filter-pesapallo:before {
    content: "\e920";
}

.insider-filter-ping-pong:before {
    content: "\e921";
}

.insider-filter-politics:before {
    content: "\e922";
}

.insider-filter-rugby:before {
    content: "\e923";
}

.insider-filter-short-track:before {
    content: "\e924";
}

.insider-filter-ski-jumping:before {
    content: "\e925";
}

.insider-filter-snooker:before {
    content: "\e926";
}

.insider-filter-soccer-myth:before {
    content: "\e927";
}

.insider-filter-specials:before {
    content: "\e928";
}

.insider-filter-squash:before {
    content: "\e929";
}

.insider-filter-volleyball:before {
    content: "\e92b";
}

.insider-filter-waterpolo:before {
    content: "\e92c";
}

.insider-filter-winter-sports:before {
    content: "\e92d";
}