#cave {
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  padding: 24px 34px;
  > img {
    width: 100%;
  }
  iframe {
    background: black;
  }
}

#cave-bg {
  position: relative;
  width: 100%;
}

.cave-layer {
  position: absolute;
  transform: translate3d(0, 0, 0);
}

#cave-layer-monitor {
  width: 100%;
  height: 100%;
  z-index: 2;
}
#cave-placeholder {
  height: 203px;
}

#cave-all {
  transition: transform 0.8s ease-in;
}

.rizk-cave-screen {
  width: 100%;
  height: 100%;
  transition: opacity 0.35s linear;
  transform: translate3d(0, 0, 0);
  position: relative;
  isolation: isolate;

  .rizk-cave-screen-img {
    top: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    object-position: center center;
    z-index: 1;
    border-radius: 3px;
  }
  .rizk-cave-screen-text-wrapper {
    position: relative;
    z-index: 2;
    color: @brand-white;
    max-width: 435px;
    padding: 0 70px;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rizk-cave-screen-heading {
    .header-font(38px);

    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }
  .rizk-cave-screen-subheading {
    color: #dcdcdc;
    margin: 0;
    padding: 0;
  }
  .rizk-cave-screen-footnote {
    font-size: 10px;
    margin: 5px;
  }
  .rizk-cave-screen-button {
    font-weight: 600;
    padding: 10px 25px;
    font-size: 12px;
    line-height: 1;
    width: max-content;
  }
}

@keyframes snowAnim {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-2430deg);
  }
}

.snowContainer {
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.tvsnow {
  transform: translate3d(0, 0, 0);
  width: 200%;
  position: absolute;
  height: 200%;
  top: -50%;
  left: -50%;

  background-image: url('../img/tvnoise.jpg');
  animation: snowAnim 6.4s steps(64) infinite;
}

.vimeo {
  width: 100%;
  height: 250%;
  margin: -42% 0 0 0%;
}

@keyframes jp-update {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes jp-glow {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.jp-anim {
  animation: jp-update 0.75s ease 0s;
}

.cave-jp {
  position: relative;
  z-index: 1;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 40px;
  font-size: 5vw;
  font-weight: bold;
  color: var(--brand-green);
  padding-top: 14%;
}

.cave-race-jp-p {
  position: absolute;
  left: 0;
  top: 71%;
  text-align: center;
  right: 0;
  color: white;
  p,
  h2 {
    margin: 2px;
  }
}

@media screen and (max-width: 1200px) {
  .cave-join {
    display: none;
  }
}

@media screen and (max-width: 915px) {
  #cave {
    padding: unset;
    border-radius: unset;
  }
  #mainslot {
    width: 100%;
    height: 100%;
  }
  .rizk-cave-screen {
    .rizk-cave-screen-text-wrapper {
      max-width: 65%;
      padding: 0 16px;
    }

    .rizk-cave-screen-heading {
      .font-size(33px);
      letter-spacing: 0!important;
    }

    .rizk-cave-screen-img {
      border-radius: revert;
    }
  }
}
