@import 'breakpoints';

// Animations Keyframes
.expand-animation(@animation-name, @height) {
    @keyframes @animation-name {
        from {
            height: 0;
        }

        to {
            height: @height;
        }
    }
}

.fold-animation(@animation-name, @height) {
    @keyframes @animation-name {
        from {
            height: @height;
        }

        to {
            height: 0;
        }
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// // Styles
.mudal-dialog {
    #VIP_Cashback_Widget_Title {
        text-transform: capitalize;
    }
    .vipCashbackToggle {
        width: 90%;
        background-color: var(--secondary-body-background-color);
        padding: 20px!important;
        margin: 20px auto;
        border-radius: 5px;
        font-family: Figtree;
        height: 82px;
        cursor: pointer;
        &__content {
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            &--badge {
                background-size: 50px 45px;
                background-repeat: no-repeat;
                background-position: 100% 3px;
                text-align: left;
            }

        }

        &__title{
            color: var(--tertiary-color);
            font-size: 12px;
            font-weight: 700;
            margin: 0 0 10px;
        }

        &__level {
            color: var(--primary-color);
            margin: 0!important;
            span {
                font-family: 900;
            }
        &--current-hidden{
            display: none;
        }

        }
    }
    .vipCashbackToggle.hidden {
        display: none;
    }
}

.vipContainer {
    background-color: var(--secondary-body-background-color);
    height: 100vh;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-bottom: 80px;
    &__banner {
        width: 100%;
        min-height: 152px;
        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        overflow: scroll;

        @media screen and (max-width: 500px) {
          padding-bottom: 100px;
        }
    }

    &__title {
        // text-transform: uppercase;
        // margin-bottom: 10px;
        font-size: 24px;
        font-weight: 900;
        line-height: 28px;
        text-align: center;
    }

    &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        }

    &__levels {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        flex-direction: column;
        gap: 30px;


        &__UpComing {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .vipContainer__level {
                filter: grayscale(100%);
                pointer-events: none;
                cursor: pointer;
                &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 100%;
                        height: 20px;
                        /* Adjust height as needed */
                        border-left: 2px dotted white;
                    }

                    &:last-child::after {
                        display: none;
                    }

                &.vipContainer__level--upcomingLevel{
                    filter: grayscale(0%);
                    pointer-events: all;
                }
            }
        }
    }

    &__level {
        display: flex;
        position: relative;
        width: 100%;
        border-radius: 5px;
        flex-direction: column;
        border: 2px solid;
        background:
                radial-gradient(50% 100% at 48.92% 0%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%),
                linear-gradient(0deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%),
                #282828;

        &--name {
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        &--items {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            row-gap: 25px;
            column-gap: 5px;
            width: 90%;

            .tablet({
                width: 45%;
            });
        }

        &--item {
            display: flex;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            &--img {
            height: 34px;
            width: 40px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 5px;
            }
            &--container {
                display: flex;
                flex-direction: column;
                &--amount {
                    text-align: left;
                }
            }
        }

        &--top {
            position: relative;
            display: flex;
            width: 100%;
            height: 65px;
        }

        &--arrow {
            position: absolute;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            bottom: 50%;
            margin-left: 5px;
            right: 20px;
            transform: rotateZ(45deg) translateY(50%);
            transform-origin: bottom;
            transition: all 0.3s ease;

            span {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                border: none;
                border-bottom: 3px solid #fff;
                border-right: 3px solid #fff;
            }

            &--rotate {
                transform: rotate(225deg) translateY(50%);
                bottom: 45%;
            }
        }

        &--lock {
            background-image: url(/assets/img/vip-cashback/lock.png);
            width: 15.58px;
            height: 22px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 50%;
            position: absolute;
            right: 20px;
            transform: translateY(50%);
        }

        &--badge {
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
            height: 104.43px;
            left: 50%;
            position: absolute;
            top: -23px;
            transform: translateX(-50%);
            width: 112.8px;
            z-index: 999999;
            background-size: cover;

            @media screen and (max-width: 480px) {
              height: 95px;
            }
        }

        &--info {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 10px 15px 10px;
            background-color: transparent;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
            height: 0;
            opacity: 0;
            overflow: hidden;
            justify-content: space-around;
            flex-direction: column;
            &--open {
                .expand-animation(level-expand, var(--target-height));
                animation: level-expand 0.5s forwards, fadeIn 1s forwards;
            }
            &--close {
                .fold-animation(level-fold, var(--target-height));
                animation: fadeOut 0.5s forwards, level-fold 0.5s forwards;
            }
        }

        &--tag {
            align-items: center;
            background-color: var(--primary-button-bg);
            -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
            clip-path: polygon(0 0, 100% 0, 92% 100%, 0% 100%);
            color: var(--secondary-color);
            display: flex;
            font-size: 8px;
            font-weight: 900;
            height: 20.31px;
            justify-content: center;
            left: -8px;
            line-height: 9.6px;
            padding: 5px 10px;
            position: absolute;
            text-transform: uppercase;
            top: 50%;
            transform: translateY(-50%);
            width: 87px;
            z-index: 4;
        }
        &--sub-tag {
            border-bottom: 10px solid transparent;
            border-right: 7px solid #615810;
            height: 0;
            left: -9px;
            position: absolute;
            width: 5px;
            bottom: 20%;
            z-index: 2;
        }
    }

    &__Secondary_Title {
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        }
        .vipContainer__level{
            cursor: pointer;
            &--bronze{
                .vipContainer__level--badge{
                    background-image: url('/assets/img/vip-cashback/badge_bronze.svg');
                }
            }
            &--silver {
                .vipContainer__level--badge {
                    background-image: url('/assets/img/vip-cashback/badge_silver.svg');
                }
            }
            &--gold {
                .vipContainer__level--badge {
                    background-image: url('/assets/img/vip-cashback/badge_gold.svg');
                }
            }
            &--platinum {
                .vipContainer__level--badge {
                    width: 126px;
                    height: 110px;
                    top: --24px;

                    @media screen and (max-width: 480px) {
                      height: 95px;
                    }
                }
            }
        }

    &__faqs {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &--faq {
            cursor: pointer;
            background: #181818;
            border-radius: 5px;
            &--header {
                background: #212121;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                text-align: left;
                display: flex;
                padding: 12px;
                border-radius: 5px;
                position: relative;
                &--question {
                    width: 70%;
                    border-radius: 5px;
                }
                &--arrow {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    bottom: 50%;
                    margin-left: 5px;
                    right: 20px;
                    transform: rotateZ(45deg) translateY(50%);
                    transform-origin: bottom;
                    transition: all 0.3s ease;

                    span {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        border: none;
                        border-bottom: 3px solid #fff;
                        border-right: 3px solid #fff;
                    }

                    &--rotate {
                        transform: rotate(225deg) translateY(50%);
                        bottom: 45%;
                    }
                }
            }
            &--info {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                height: 0;
                opacity: 0;
                overflow: hidden;
                &--open {
                        .expand-animation(faq-expand, var(--target-height));
                        animation: faq-expand 0.5s forwards, fadeIn 1s forwards;
                    }
                    &--close {
                        .fold-animation(faq-fold, var(--target-height));
                        animation: fadeOut 0.5s forwards, faq-fold 0.5s forwards;
                    }
            }
        }
        &--answer{
            padding: 15px;
        }
    }
    #VIP_Cashback_Back_Button {
        background-color: var(--tertiary-color-button);
        font-size: 14px;
        font-weight: 900;
        line-height: 21px;
        text-align: center;
        width: 136px;
        margin: auto;
    }
}

